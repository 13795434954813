import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { devicesActions } from './store';

export const eventName = Object.freeze({
  TrackDevice: 'TrackDevice',
  StopTrackingDevice: 'StopTrackingDevice',
  CloseDevice: 'CloseDevice',
  CloseAllDevices: 'CloseAllDevices',
});

const actions = new Map();

const initializeActions = (dispatch) => {
  actions.set(eventName.TrackDevice, (device) => {
    dispatch(devicesActions.select(device));
  });
  actions.set(eventName.StopTrackingDevice, () => {
    dispatch(devicesActions.deselect(null));
  });
  actions.set(eventName.CloseDevice, ({ flag, id }) => {
    if (flag) {
      dispatch(devicesActions.hide(id));
    } else {
      dispatch(devicesActions.open(id));
    }
  });
  actions.set(eventName.CloseAllDevices, (newClosedDevices) => {
    dispatch(devicesActions.turnAll(newClosedDevices));
  });
};

const events = new BroadcastChannel('events');

/**
 * Отправляет сообщение "слушателям", после получения eventName вызывается обработчик соответствующий названию.
 * @param {*} eventName Вызывает обработчик соответствующий имени.
 * @param {*} message Любой объект, строка.
 */
export const sendMessage = (eventName, message) => events.postMessage({ eventName, message });
/**
 * Возвращает обработчик соответствущий eventName.
 * @returns callback метод.
 */
export const getAction = (eventName) => actions.get(eventName);
/**
 * Вызывает обработчик соответствующий eventName и отправляет уведомление "слушателям".
 * @param {*} eventName Названием события для обработки сообщения.
 * @param {*} message Любой объект, строка.
 */
export const notify = (eventName, message) => {
  getAction(eventName)(message);
  sendMessage(eventName, message);
};

const NotificationSyncController = () => {
  const dispatch = useDispatch();

  events.onmessage = (event) => {
    const { eventName, message } = event?.data || null;
    if (!eventName || !actions.has(eventName)) return;
    actions.get(eventName)(message);
  };

  useEffect(() => {
    initializeActions(dispatch);
    return () => events.close();
  }, []);

  return null;
};

export default NotificationSyncController;
