import { makeStyles } from '@material-ui/core';
import scrollStyles from '../../../common/theme/scrollStyles';

// eslint-disable-next-line import/prefer-default-export
export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  sidebarToggle: {
    position: 'fixed',
    left: theme.spacing(1.5),
    top: theme.spacing(3),
    borderRadius: '0px',
    minWidth: 0,
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      left: 0,
    },
  },
  sidebarToggleText: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  sidebarToggleBg: {
    backgroundColor: 'white',
    color: '#777777',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  foundNothing: {
    maxWidth: '100%',
    zIndex: 1310,
  },
  timeExpiration: {
    bottom: 10,
    right: 10,
    position: 'absolute',
    pointerEvents: 'none',
    opacity: 0.5,
  },
}));

export const useStylesMainPanel = makeStyles((theme) => ({
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    maxHeight: '100%',
    left: 0,
    top: 0,
    margin: theme.spacing(1.5),
    width: theme.dimensions.drawerWidthDesktop,
    bottom: 56,
    zIndex: 1300,
    transition: 'transform .5s ease',
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  sidebarCollapsed: {
    transform: `translateX(-${theme.dimensions.drawerWidthDesktop})`,
    marginLeft: 0,
    [theme.breakpoints.down('md')]: {
      transform: 'translateX(-100vw)',
    },
  },
  paper: {
    zIndex: 1,
    width: '100%',
    position: 'sticky',
    top: theme.spacing(0),
    [theme.breakpoints.up('lg')]: {
      width: theme.dimensions.drawerWidthDesktop,
    },
  },
  toolbar: {
    display: 'flex',
    padding: theme.spacing(0, 1),
    '& > *': {
      margin: theme.spacing(0, 0),
    },
  },
  deviceList: {
    flex: 1,
  },
  buttonHide: {
    paddingRight: 0,
    paddingLeft: 25,
  },
}));

export const useStylesSortingMenu = makeStyles((theme) => ({
  container: {
    width: '100%',
    position: 'sticky',
    marginTop: 0,
  },
  bottoms: {
    height: theme.spacing(5),
  },
  buttonGroup: {
    width: '100%',
    height: theme.spacing(5),
  },
  button: {
    margin: 0,
    lineHeight: 1.2,
    width: '68%',
    fontSize: '0.75rem',
    textTransform: 'lowercase',
    transitionDelay: '0.1s',
    padding: theme.spacing(1, 0, 1),
    color: 'rgba(0, 0, 0, 0.54)',
  },
  buttonSmall: {
    width: '16%',
  },
  buttonSelected: {
    margin: 0,
    lineHeight: 1.2,
    width: '25%',
    fontSize: '0.87rem',
    textTransform: 'lowercase',
    transitionDelay: '0.1s',
    padding: theme.spacing(1, 0, 1),
    color: '#2b2b2bf9',
  },
  activeItem: {
    background: '#f2ae72',
    '&:hover': {
      background: '#d4ac6e',
    },
  },
  menuEditRemove: {
    zIndex: -1,
    position: 'relative',
    maxHeight: '70vh',
  },
}));

export const useStylesDeviceList = makeStyles((theme) => ({
  list: {
    maxHeight: '100%',
  },
  listInner: {
    position: 'relative',
    margin: theme.spacing(1.5, 0),
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  listItem: {
    backgroundColor: 'white',
    height: theme.spacing(8),
    paddingLeft: theme.spacing(0.3),
    cursor: 'default',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  emptyItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  listItemChosen: {
    backgroundColor: '#cbeef2',
  },
  batteryText: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    lineHeight: '0.875rem',
  },
  green: {
    color: theme.palette.common.green,
  },
  red: {
    color: theme.palette.common.red,
  },
  gray: {
    color: theme.palette.common.gray,
  },
  orange: {
    color: theme.palette.common.orange,
  },
  indicators: {
    display: 'flex',
    right: theme.spacing(1),
    lineHeight: 1,
    alignItems: 'center',
  },
  iconTrackColor: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 20,
    height: 20,
    backgroundColor: '#fff',
  },
  statusLine: {
    marginRight: theme.spacing(1),
  },
  statusLineHide: {
    opacity: 0,
  },
  menuButton: {
    padding: 4,
  },
  itemTextMain: {
    display: 'flex',
    alignItems: 'center',
  },
  itemTextContainer: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
  itemText: {
    whiteSpace: 'nowrap',
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: 6,
  },
  itemTextShort: {
    maxWidth: 'calc(48%)',
  },
  itemTextMiddle: {
    maxWidth: 'calc(58%)',
  },
  itemTextLong: {
    maxWidth: 'calc(72%)',
  },
  brightness: {
    fontSize: 12,
    paddingRight: 3,
  },
  scroll: {
    ...scrollStyles(),
  },
  details: {
    flexDirection: 'column',
    padding: 0,
  },
  accrodion: {
    width: '100%',
    borderBottomRightRadius: '0 !important',
    borderBottomLeftRadius: '0 !important',
    borderTopRightRadius: '0 !important',
    borderTopLeftRadius: '0 !important',
    padding: 0,
  },
  categoryPanel: {
    display: 'flex',
    background: '#F5F5F5',
    alignItems: 'center',
  },
  accrodionSummary: {
    height: '52px',
    minHeight: '0 !important',
  },
  accordionSummary: {
    padding: '0 5px',
    height: '52px',
    minHeight: '0 !important',
  },
  accordionSummaryContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  accordionSummaryCaption: {
    // maxWidth: 204,
    maxWidth: 198,
  },
  toRightIcon: {
    transform: 'rotateZ(90deg)',
  },
  dissabledPadding: {
    padding: 0,
  },
  containerScrollStyles: {
    ...scrollStyles(6),
  },
}));

export const useStylesCommandPanel = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'sticky',
    marginTop: 0,
    maxHeight: '50%',
    background: '#ebe9dd',
  },
  toolbar: {
    display: 'flex',
    padding: theme.spacing(0.8, 0.2),
    minHeight: 0,
    background: '#9c9a92',
  },
  head: {
    textAlign: 'center',
    width: '100%',
    color: 'white',
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  textField: {
    width: '90%',
  },
}));
