import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  formatDate, formatSmsType, getSortedArray,
} from '../../common/utils/formatter';
import { useTranslation } from '../../common/components/LocalizationProvider';
import usePersistedState from '../../common/utils/usePersistedState';
import { getIsAdmin } from '../../common/utils/selectors';
import { useEffectAsync } from '../../common/utils/reactHelper';
import logout from '../../common/utils/logout';
import ReportTemplate from './components/ReportTemplate';

const typeReport = 'smsReport';

const columnsArray = [
  ['deviceId', 'reportDevice', 1],
  ['group', 'reportGroup', 1],
  ['userId', 'reportSender', 0],
  ['smsTime', 'reportSmsTime', 1],
  ['message', 'reportMessage', 0],
  ['type', 'reportTypeMessage', 0],
  ['success', 'reportNote', 0],
];

const typesSorting = {
  deviceId: (a, b) => getSortedArray(a.deviceId, b.deviceId),
  deviceIdReverse: (a, b) => getSortedArray(a.deviceId, b.deviceId, true),
  group: (a, b) => getSortedArray(a.groupId, b.groupId),
  groupReverse: (a, b) => getSortedArray(a.groupId, b.groupId, true),
  smsTime: (a, b) => getSortedArray(a.smsTime, b.smsTime),
  smsTimeReverse: (a, b) => getSortedArray(a.smsTime, b.smsTime, true),
};

const SmsReportPage = () => {
  const t = useTranslation();
  const [offColumns, setOffColumns] = usePersistedState(typeReport, []);
  const groups = useSelector((state) => state.groups.items);
  const history = useHistory();
  const dispatch = useDispatch();
  const isAdmin = useSelector(getIsAdmin);
  const [items, setItems] = useState([]);
  const [users, setUsers] = useState([]);
  const [devicesList, setDevicesList] = useState([]);
  const [devicesObject, setDevicesObject] = useState({});

  const [progress, setProgress] = useState(0);
  const [progressDevices, setProgressDevices] = useState(true);
  const [page, setPage] = useState(0);

  const columnsArrayFiltered = columnsArray.filter((column) => !offColumns.includes(column[0]));

  const handleSubmit = async (deviceId, from, to, headers) => {
    setProgress(1);
    const query = new URLSearchParams({
      from, to,
    });
    deviceId.forEach((it) => query.append('deviceId', it));
    columnsArrayFiltered.forEach((it) => query.append('column', it[0]));
    const response = await fetch(`/api/reports/sms-history?${query.toString()}`, { headers });
    if (response.ok) {
      const contentType = response.headers.get('content-type');
      if (contentType) {
        if (contentType === 'application/json') {
          setPage(0);
          setItems(await response.json());
        } else {
          window.location.assign(window.URL.createObjectURL(await response.blob()));
        }
      }
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
    setProgress(100);
    setProgress(0);
  };

  const formatValue = (item, key, devices) => {
    switch (key) {
      case 'userId':
        return users.length && item[key] ? users.find((v) => v.id === item[key]).name : '';
      case 'smsTime':
        return formatDate(item[key]);
      case 'deviceId':
        return devices[item[key]]?.name;
      case 'group':
        return groups[devices[item.deviceId].groupId]?.name || '';
      case 'type':
        return formatSmsType(item[key], t);
      case 'success':
        return item[key] ? '' : t('reportSmsNoSuccess');
      default:
        return item[key];
    }
  };

  useEffectAsync(async () => {
    if (isAdmin) {
      const response = await fetch('/api/users');
      if (response.ok) {
        setUsers(await response.json());
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
    }
  }, []);

  useEffectAsync(async () => {
    const response = await fetch('/api/devices?object=true');
    if (response.ok) {
      const result = await response.json();
      setDevicesList(Object.values(result));
      setDevicesObject(result);
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
    setProgressDevices(false);
  }, []);

  return (
    <ReportTemplate
      items={items}
      progress={progress}
      progressDevices={progressDevices}
      columnsArray={columnsArray}
      handleSubmit={handleSubmit}
      typeSortingDefault="smsTime"
      formatValue={formatValue}
      page={page}
      setPage={setPage}
      typesSorting={typesSorting}
      typeReport={typeReport}
      offColumns={offColumns}
      setOffColumns={setOffColumns}
      columnsArrayFiltered={columnsArrayFiltered}
      breadcrumbs={['reportTitle', 'reportSms']}
      devicesList={devicesList}
      devicesObject={devicesObject}
    />
  );
};

export default SmsReportPage;
