import React, { useState } from 'react';
import {
  LinearProgress,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Column } from 'devextreme-react/data-grid';
import { useHistory } from 'react-router-dom';
import { useEffectAsync } from '../../common/utils/reactHelper';
import EditCollectionView from '../../EditCollectionView';
import { prefixString } from '../../common/utils/stringUtils';
import OptionsLayout from './OptionsLayout';
import { useTranslation } from '../../common/components/LocalizationProvider';
import logout from '../../common/utils/logout';
import SmartTable from '../../common/components/SmartTable';
import { allowedPageSizes, currentPageSize } from '../../common/settings/settingsPage';
import { getStringFromBoolean } from '../../common/components/SmartTable/funcs/utils';
import { NotificatorLinksRender } from '../../common/components/SmartTable/columns/cells';
import { concateStringsFromNotificator } from '../../common/components/SmartTable/funcs/calculateCellValue';

const NotificationsView = ({ updateTimestamp, onMenuClick, onAddNewRow }) => {
  const t = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const [requestProgress, setRequestProgress] = useState(true);

  useEffectAsync(async () => {
    const response = await fetch('/api/notifications');
    if (response.ok) {
      setItems(await response.json());
    } else if (response.status === 401) {
      if (response.status === 401) {
        logout(history, dispatch);
      }
    }
    setRequestProgress(false);
  }, [updateTimestamp]);

  if (requestProgress) {
    return (
      <LinearProgress style={{ top: '100px' }} />
    );
  }

  return (
    <SmartTable
      tableName="NotificationsPage"
      fileExportingName="sharedNotifications"
      allowedPageSizes={allowedPageSizes}
      currentPageSize={currentPageSize}
      arrayDependencies={[items]}
      dataSource={items}
      onAddNewRow={onAddNewRow}
      onMenuClick={onMenuClick}
    >
      <Column
        dataField="id"
        caption={t('deviceIdentifier')}
        width={100}
        visible={false}
      />
      <Column
        dataField="type"
        caption={t('notificationType')}
        customizeText={(text) => t(prefixString('event', text.value))}
      />
      <Column
        dataField="always"
        dataType="string"
        caption={t('notificationAlways')}
        calculateCellValue={(item) => getStringFromBoolean(item.always, t)}
      />
      <Column
        dataField="notificators"
        caption={t('notificationNotificators')}
        cellComponent={({ data }) => <NotificatorLinksRender dataset={data} t={t} />}
        calculateCellValue={(item) => concateStringsFromNotificator(item, t)}
      />
    </SmartTable>
  );
};

const NotificationsPage = () => {
  const t = useTranslation();

  return (
    <OptionsLayout>
      <EditCollectionView
        disableAdd
        stickyTop
        textOfAddButton={t('notificationAddNotification')}
        content={NotificationsView}
        editPath="/settings/notification"
        endpoint="notifications"
        forseAdd
      />
    </OptionsLayout>
  );
};

export default NotificationsPage;
