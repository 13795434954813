import maplibregl from 'maplibre-gl';
import { useEffect, useRef } from 'react';
import { map } from './Map';
import { useTranslation } from '../common/components/LocalizationProvider';

const CurrentLocationMap = () => {
  const timer = useRef();
  const t = useTranslation();

  const checkAndReplaceTitle = () => {
    try {
      const button = document.querySelector('button.maplibregl-ctrl-geolocate.mapboxgl-ctrl-geolocate');

      if (button) {
        const title = button.getAttribute('title');
        const translatedTitle = title === 'Find my location' ? t('GlobalFindMyLocation') : t('GlobalLocationNotAvailable');
        button.setAttribute('title', translatedTitle);
      } else {
        timer.current = setTimeout(checkAndReplaceTitle, 500);
      }
    } catch (error) {
      console.warn('Ошибка замены заголовка кнопки определения местоположения.', error);
    }
  };

  useEffect(() => {
    checkAndReplaceTitle();

    const control = new maplibregl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
        timeout: 5000,
      },
      trackUserLocation: true,
    });
    map.addControl(control);
    return () => {
      map.removeControl(control);
      clearTimeout(timer.current);
    };
  }, []);

  return null;
};

export default CurrentLocationMap;
