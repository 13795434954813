export default {
  temporary: { value: 30, time: 'second' },
  categories: {
    device: { value: 1, time: 'month' },
    radar: { value: 1, time: 'month' },
    uav: { value: 1, time: 'month' },
    anti_uav: { value: 1, time: 'month' },
    detected_object: { value: 5, time: 'minute' },
  },
};
