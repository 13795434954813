import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  LinearProgress,
  useTheme,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'devextreme-react/data-grid';
import { useHistory } from 'react-router-dom';
import { useEffectAsync } from '../../common/utils/reactHelper';
import EditCollectionView from '../../EditCollectionView';
import OptionsLayout from './OptionsLayout';
import { useTranslation } from '../../common/components/LocalizationProvider';
import logout from '../../common/utils/logout';
import { getIsAdmin } from '../../common/utils/selectors';
import SmartTable from '../../common/components/SmartTable';
import { getCategoryName, getGroupName, getTextFromIconWithSeparator } from '../../common/components/SmartTable/funcs/calculateCellValue';
import { allowedPageSizes, currentPageSize } from '../../common/settings/settingsPage';
import { ColorsCellRender, TextIconCellRender } from '../../common/components/SmartTable/columns/cells';
import { ColorsGroupCellRender, TextIconGroupCellRender } from '../../common/components/SmartTable/columns/groups';

const DevicesView = ({ updateTimestamp, onMenuClick, onAddNewRow }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useTranslation();
  const theme = useTheme();

  const isAdmin = useSelector(getIsAdmin);
  const groups = useSelector((state) => state.groups.items);

  const [items, setItems] = useState([]);
  const [requestProgress, setRequestProgress] = useState(true);

  useEffectAsync(async () => {
    const response = await fetch(`/api/devices${isAdmin ? '?all=true' : ''}`);
    if (response.ok) {
      setItems(await response.json());
    } else if (response.status === 401) {
      if (response.status === 401) {
        logout(history, dispatch);
      }
    }
    setRequestProgress(false);
  }, [updateTimestamp]);

  if (requestProgress) {
    return (
      <LinearProgress style={{ top: '100px' }} />
    );
  }

  return (
    <SmartTable
      tableName="DevicesPage"
      fileExportingName="ServerPageDevice"
      allowedPageSizes={allowedPageSizes}
      currentPageSize={currentPageSize}
      arrayDependencies={[items]}
      dataSource={items}
      onAddNewRow={onAddNewRow}
      onMenuClick={onMenuClick}
    >
      <Column
        dataField="id"
        caption={t('deviceIdentifier')}
        width={100}
      />
      <Column
        key="name"
        name="name"
        dataField="name"
        caption={t('deviceName')}
        minWidth={250}
      />
      <Column
        dataField="lastUpdate"
        width={190}
        dataType="datetime"
        caption={t('deviceLastUpdate')}
      />
      <Column
        dataField="icon"
        caption={t('deviceIcon')}
        width={250}
        cellComponent={({ data }) => <TextIconCellRender dataset={data} t={t} />}
        groupCellComponent={({ data }) => <TextIconGroupCellRender dataset={data} />}
        calculateCellValue={(item) => getTextFromIconWithSeparator(item, t)}
      />
      <Column
        key="groupId"
        name="groupId"
        dataField="groupId"
        dataType="string"
        calculateCellValue={(item) => getGroupName(item, groups)}
        caption={t('deviceGroup')}
        width={200}
      />
      <Column
        key="attributes.color"
        name="attributes.color"
        dataField="attributes.color"
        dataType="string"
        caption={t('sharedColor')}
        width={100}
        cellComponent={({ data }) => <ColorsCellRender dataset={data} theme={theme} />}
        groupCellComponent={({ data }) => <ColorsGroupCellRender dataset={data} theme={theme} />}
      />
      <Column
        dataField="category"
        caption={t('deviceCategory')}
        calculateCellValue={(item) => getCategoryName(item, t)}
      />
    </SmartTable>
  );
};

DevicesView.propTypes = {
  updateTimestamp: PropTypes.number.isRequired,
  onMenuClick: PropTypes.func.isRequired,
};

const DevicesPage = () => (
  <OptionsLayout>
    <EditCollectionView
      hideButtonAdd
      content={DevicesView}
      editPath="/device"
      endpoint="devices"
    />
  </OptionsLayout>
);

export default DevicesPage;
