import React, {
  useRef, useMemo, useState, useCallback,
} from 'react';
import { DeleteOutline } from '@material-ui/icons';
import {
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  DataGrid, Button as ButtonGrid,
} from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  ColumnChooser, ColumnFixing, FilterRow, GroupPanel, Grouping, LoadPanel, Pager,
  Paging, Position, StateStoring, Toolbar, Item as ItemGrid, Sorting, Column, Selection,
} from 'devextreme-react/data-grid';
import ExportForm from './ExportForm';
import ResetForm from './ResetForm';
import { useTranslation } from '../LocalizationProvider';
import useDevextremeTablesSettings from '../../hooks/useDevextremeTablesSettings';

const useStyles = makeStyles((theme) => ({
  pointerCursor: {
    cursor: 'pointer',
  },
  dataGridContainer: {
    // margin: '0 -10px',
    height: '100vh',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 80px)',
      margin: 0,
      marginTop: 20,
    },
  },
  backgroundColor: {
    backgroundColor: 'rgb(255 92 77)',
  },
  maxWidth: {
    maxWidth: 150,
  },
}));

const defaultAllowedPageSizes = [10, 25];
const defaultPageSize = 25;

const SmartTable = ({
  onMenuClick, onAddNewRow, tableName, dataSource, children, fileExportingName,
  disableExport, disableSaveSettings, disableFilters, disableActionColumn,
  allowedPageSizes, currentPageSize,
  dataUrl, columns,
  arrayDependencies, classes,
  onSelectingRows,
  headerButtons,
}) => {
  const innerClasses = useStyles();
  const t = useTranslation();
  const theme = useTheme();
  const tableSettings = useDevextremeTablesSettings(tableName ?? '');
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const selectedRowKeysCount = useRef([]);
  const dataGridRef = useRef();
  const init = useRef(false);

  const [isShowExportingForm, setIsShowExportingForm] = useState(false);
  const [isShowToDefaultResetForm, setIsShowToDefaultResetForm] = useState(false);

  const customLoad = useCallback(() => {
    if (init.current) return false;
    init.current = true;
    return tableSettings.get();
  }, []);

  const customSave = useCallback((value) => {
    const newValue = { ...value };

    delete newValue.selectedRowKeys;

    newValue.columns.forEach((column) => {
      delete column.filterValue;
      delete column.sortIndex;
      delete column.sortOrder;
      delete column.groupIndex;
    });

    tableSettings.save(newValue);
  }, []);

  const openResetForm = useCallback(() => setIsShowToDefaultResetForm(true), []);
  const closeResetForm = useCallback(() => setIsShowToDefaultResetForm(false), []);

  const openExportForm = useCallback(() => setIsShowExportingForm(true), []);
  const closeExportingForm = useCallback(() => setIsShowExportingForm(false), []);

  const handleDeleteAll = useCallback(() => {
    if (selectedRowKeysCount.current.length === 0) {
      return;
    }
    onMenuClick(null, selectedRowKeysCount.current);
  }, []);

  const handleSelectedRowKeysChange = useCallback((keys) => {
    selectedRowKeysCount.current = keys;
    if (onSelectingRows) {
      onSelectingRows(keys);
    }
  }, []);

  const handleErrors = useCallback((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }, []);

  const jsonDataSource = useMemo(() => new CustomStore({
    key: 'id',
    loadMode: 'raw',
    load: () => fetch(dataUrl)
      .then(handleErrors)
      .then((response) => response.json())
      .catch((e) => console.error(e)),
    select: columns,
  }), []);

  const actionsMenuRender = useCallback(({ data }) => (
    <MoreVertIcon className={innerClasses.pointerCursor} color="action" onClick={(e) => onMenuClick(e.currentTarget, data.data.id)} />
  ), []);

  const headerActionsMenuRender = useCallback(() => (
    <DeleteOutline
      className={innerClasses.pointerCursor}
      color="action"
      onClick={handleDeleteAll}
    />
  ), []);

  return (
    <>
      {useMemo(() => (
        <DataGrid
          ref={dataGridRef}
          dataSource={dataSource ?? jsonDataSource}
          className={`dx-card wide-card ${classes?.dataGridContainer ?? innerClasses.dataGridContainer}`}
          showBorders={false}
          columnResizingMode="widget"
          hoverStateEnabled
          allowColumnReordering
          allowColumnResizing
          columnAutoWidth
          onSelectedRowKeysChange={handleSelectedRowKeysChange}
        /*     onContentReady={async (eventInfo) => {
              const currentSelected = [...selectedRowKeysCount.current];
              await eventInfo.component.selectRows(currentSelected, true);
            }} */
        /*      onRowPrepared={(rowInfo) => {
               if (rowInfo.isSelected) {
                 rowInfo.rowElement.classList.add(innerClasses.any);
               }
               return (rowInfo);
             }} */
        >
          <StateStoring
            type="custom"
            enabled={!disableSaveSettings}
            // enabled={false}
            customSave={customSave}
            customLoad={customLoad}
            savingTimeout={150}
          />
          <ColumnChooser enabled mode="select" allowSearch height={400} width={300}>
            <Position
              my="right top"
              at="right top"
              of=".dx-datagrid-column-chooser-button"
            />
          </ColumnChooser>
          <LoadPanel enabled={false} />
          <ColumnFixing enabled />
          <Paging enabled defaultPageSize={currentPageSize ?? defaultPageSize} />
          <Pager
            visible
            showPageSizeSelector
            showInfo
            showNavigationButtons
            allowedPageSizes={allowedPageSizes ?? defaultAllowedPageSizes}
          />
          <FilterRow visible={!disableFilters} />
          <Selection mode="multiple" showCheckBoxesMode="always" />
          <Grouping contextMenuEnabled autoExpandAll={false} expandMode="rowClick" />
          <GroupPanel visible />
          <Sorting mode="multiple" />

          <Toolbar visible>
            {headerButtons && (
              <ItemGrid location="after" locateInMenu="auto">
                <ButtonGrid
                  className={innerClasses.backgroundColor}
                  hint={t('globalStopAll')}
                  text={t('globalStopAll')}
                  stylingMode={isMobile ? 'text' : 'outlined'}
                  onClick={headerButtons}
                />
              </ItemGrid>
            )}
            {onAddNewRow && (
              <ItemGrid location="after" locateInMenu="auto">
                <ButtonGrid
                  icon="add"
                  hint={t('sharedAdd')}
                  text={isMobile ? t('sharedAdd') : ''}
                  stylingMode={isMobile ? 'text' : 'outlined'}
                  onClick={onAddNewRow}
                />
              </ItemGrid>
            )}
            {!disableExport && (
              <ItemGrid location="after" locateInMenu="auto">
                <ButtonGrid
                  icon="export"
                  hint={t('GlobalExport')}
                  text={isMobile ? t('GlobalExport') : ''}
                  stylingMode={isMobile ? 'text' : 'outlined'}
                  onClick={openExportForm}
                />
              </ItemGrid>
            )}
            <ItemGrid name="columnChooserButton" />
            <ItemGrid name="applyFilterButton" />
            <ItemGrid name="groupPanel" />
            <ItemGrid location="after" locateInMenu="auto" showText="inMenu">
              <ButtonGrid
                className={innerClasses.maxWidth}
                hint={t('devextremeResetDefaultSettings')}
                icon="revert"
                text={isMobile ? t('devextremeResetDefaultSettings') : ''}
                stylingMode={isMobile ? 'text' : 'outlined'}
                onClick={openResetForm}
              />
            </ItemGrid>
          </Toolbar>

          {(onMenuClick && !disableActionColumn) && (
            <Column
              key="GlobalActions"
              caption={t('GlobalActions')}
              allowExporting={false}
              allowFiltering={false}
              allowGrouping={false}
              headerCellComponent={headerActionsMenuRender}
              cellComponent={actionsMenuRender}
              width={40}
            />
          )}

          {children}
        </DataGrid>
      ), arrayDependencies ?? [])}

      {useMemo(() => (
        <ResetForm
          isShowToDefaultResetForm={isShowToDefaultResetForm}
          closeResetForm={closeResetForm}
          devicesTableSettings={tableSettings}
        />
      ), [isShowToDefaultResetForm])}

      {useMemo(() => (
        <ExportForm
          closeExportingForm={closeExportingForm}
          dataGridRef={dataGridRef}
          isShowExportingForm={isShowExportingForm}
          fileExportingName={fileExportingName}
        />
      ), [isShowExportingForm])}
    </>
  );
};

export default SmartTable;
