import React, { useState } from 'react';
import {
  makeStyles, IconButton, Typography, Divider, Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import RemoveIcon from '@material-ui/icons/Remove';
import LaunchIcon from '@material-ui/icons/Launch';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch, useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import { useTranslation } from '../LocalizationProvider';
import { positionsActions } from '../../../store';
import Data from './Data';
import ListDataStepper from './ListDataStepper';
import CompactPanel from './CompactPanel';
import SidePanel from './SidePanel';
import PositionDataEdit from './PositionDataEdit';

const useStyles = makeStyles((theme) => ({
  paperSidePanel: {
    height: '100%',
    width: '100%',
    paddingBottom: '0',
  },
  paperCompactPanel: {
    width: '100%',
    height: 'calc(100% - 34px)',
  },
  edit: {
    marginLeft: 'auto',
  },
  iconButton: {
    height: theme.spacing(1),
    width: theme.spacing(1),
  },
}));

const DataPanel = ({
  setNextPosition, countPosition, setCountPosition, data, redFields, getTitle,
  setPositionsBS, openChoice, off, includeProperties, setOpenChoice, speedUnit, positionsSimilar,
  setPositionDataVisible, popup, setPopup, isCompactPanel, selectedMarkerComponents, setMoveableDevice,
}) => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();

  const selectedPositionData = useSelector((state) => state.positions.selectedPositionData);

  const [openExpandTooltip, setOpenExpandTooltip] = useState(false);
  const [openCollapseTooltip, setOpenCollapseTooltip] = useState(false);
  const [openCloseTooltip, setOpenCloseTooltip] = useState(false);
  const [isOpenEditForm, setIsOpenEditForm] = useState(false);

  const isHaveList = !!(selectedPositionData.numberPoints > 1 && !selectedPositionData.startPoint);
  const bodyContentOutside = selectedMarkerComponents.bodyContent;
  const headerSidebarOutside = selectedMarkerComponents.sidebarHeader;
  const headerCompactOutside = selectedMarkerComponents.compactHeader;

  const handleClick = () => {
    setOpenExpandTooltip(false);
    dispatch(positionsActions.updatePopupData(false));
  };

  const removePopup = () => {
    try {
      if (popup) {
        popup.remove();
        setPopup(null);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  useState(() => {
    if (selectedMarkerComponents.callbackDeletePopup) {
      selectedMarkerComponents.callbackDeletePopup.current = removePopup;
    }
  }, []);

  const handleMove = async () => {
    setMoveableDevice({
      deviceId: selectedPositionData.deviceId,
      latitude: selectedPositionData.latitude,
      longitude: selectedPositionData.longitude,
    });
  };

  const handleCloseEditForm = () => {
    setIsOpenEditForm(false);
  };

  const handleOpenEditForm = () => {
    setIsOpenEditForm(true);
  };

  const compactPanelHeader = (
    <>
      <Tooltip title={getTitle()}>
        <Typography variant="body1" color="inherit" noWrap style={{ width: '100%' }}>
          {getTitle()}
        </Typography>
      </Tooltip>
      {setMoveableDevice && (
        <Tooltip title={t('sharedMove')}>
          <IconButton size="small" onClick={() => handleMove()}>
            <OpenWithIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={t('backTitle')}>
        <IconButton className={classes.iconButton} onClick={() => setOpenChoice(!openChoice)}>
          <SettingsIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('sharedEdit')}>
        <IconButton className={classes.iconButton} onClick={handleOpenEditForm}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('collapseTitle')}
        open={openCollapseTooltip}
        onOpen={() => setOpenCollapseTooltip(true)}
        onClose={() => setOpenCollapseTooltip(false)}
      >
        <IconButton
          size="small"
          onClick={() => {
            setOpenCollapseTooltip(false);
            setPositionDataVisible(false);
          }}
        >
          <RemoveIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('expandPanelTitle')}
        open={openExpandTooltip}
        onOpen={() => setOpenExpandTooltip(true)}
        onClose={() => setOpenExpandTooltip(false)}
      >
        <IconButton className={classes.iconButton} onClick={handleClick}>
          <ZoomOutMapIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('closeTitle')}
        open={openCloseTooltip}
        onOpen={() => setOpenCloseTooltip(true)}
        onClose={() => setOpenCloseTooltip(false)}
      >
        <IconButton
          className={classes.iconButton}
          onClick={() => {
            setOpenCloseTooltip(false);
            dispatch(positionsActions.unselectPosition());
            setNextPosition(null);
            setPositionsBS([]);
            removePopup();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </>
  );

  const sidePanelHeader = (
    <>
      <Tooltip title={getTitle()}>
        <Typography variant="h6" color="inherit" noWrap style={{ width: '100%' }}>
          {getTitle()}
        </Typography>
      </Tooltip>
      {setMoveableDevice && (
        <Tooltip title={t('sharedMove')}>
          <IconButton size="small" onClick={() => handleMove()}>
            <OpenWithIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={t('settingsTitle')}>
        <IconButton className={classes.edit} onClick={() => setOpenChoice(!openChoice)}>
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('sharedEdit')}>
        <IconButton onClick={handleOpenEditForm}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('collapseTitle')}>
        <IconButton onClick={() => setPositionDataVisible(false)}>
          <RemoveIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('shrinkPanelTitle')}>
        <IconButton onClick={() => dispatch(positionsActions.updatePopupData(true))}>
          <LaunchIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('closeTitle')}>
        <IconButton
          onClick={() => {
            dispatch(positionsActions.unselectPosition());
            setNextPosition(null);
            setPositionsBS([]);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </>
  );

  const bodyContent = (
    <>
      <Data
        rootClass={isCompactPanel ? classes.paperCompactPanel : classes.paperSidePanel}
        size={isCompactPanel ? 'small' : 'medium'}
        data={data}
        off={off}
        includeProperties={includeProperties}
        redFields={redFields}
        speedUnit={speedUnit}
        openChoice={openChoice}
      />

      {isHaveList && (
        <>
          <Divider />
          <ListDataStepper
            gridStyle={{ padding: isCompactPanel ? '0' : '5px' }}
            classes={classes}
            countPosition={countPosition}
            positionsSimilar={positionsSimilar}
            setCountPosition={setCountPosition}
            setNextPosition={setNextPosition}
          />
        </>
      )}
    </>
  );

  return (
    <>
      {isCompactPanel ? (
        <CompactPanel
          paperStyle={{ marginBottom: isHaveList ? '26px' : '0' }}
          headerContent={headerCompactOutside ?? compactPanelHeader}
          bodyContent={bodyContentOutside ?? bodyContent}
        />
      ) : (
        <SidePanel
          headerContent={headerSidebarOutside ?? sidePanelHeader}
          bodyContent={bodyContentOutside ?? bodyContent}
          upper
        />
      )}

      {isOpenEditForm && (
        <PositionDataEdit
          deviceProperties={selectedPositionData}
          open={isOpenEditForm}
          onClose={handleCloseEditForm}
        />
      )}
    </>
  );
};

export default DataPanel;
