import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  Typography,
  Button,
  FormControl,
  Container,
  Checkbox,
  FormControlLabel,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormGroup,
  Grid,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions } from '../../../store';
import OptionsLayout from '../OptionsLayout';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import logout from '../../../common/utils/logout';
import {
  checkUrl, formatUrl, getPeriodName, toTitleCase,
} from '../../../common/utils/formatter';
import tailPeriodsChoosing from '../../../common/static/tailPeriodsChoosing';
import tailPeriods from '../../../common/static/tailPeriods';
import lifetimes from '../../../common/static/lifetimes';
import SelectDeviceIcon from '../components/SelectDeviceIcon';
import icons from '../../../common/static/icons';
import SelectField from '../../../common/form/SelectField';
import ExtraMapsBlock from './ExtraMapsBlock';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  details: {
    flexDirection: 'column',
  },
  adornmentButton: {
    padding: 0,
  },
}));

const timesList = ['second', 'minute', 'hour', 'day', 'month'];
/* const availableTime = [
  { value: 10, time: 'second' },
  { value: 20, time: 'second' },
  { value: 30, time: 'second' },
  { value: 1, time: 'minute' },
  { value: 2, time: 'minute' },
  { value: 5, time: 'minute' },
]; */

const MIN_VALUE = 0;
const MAX_VALUE = 500;

const ServerPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useTranslation();

  const server = useSelector((state) => state.session.server);

  const [item, setItem] = useState(server);
  const [errorBscoder, setErrorBscoder] = useState(false);
  const [errorOsrm, setErrorOsrm] = useState(false);
  const [periodDialogOpen, setPeriodDialogOpen] = useState(false);
  // Настройки периода времени, когда скрывать устройства в левом боковом меню
  const [lifetimeDevicesDialog, setLifetimeDevicesDialog] = useState(false);

  const [categoryLifetime, setCategoryLifetime] = useState(Object.keys(lifetimes.categories).reduce((a, v) => (
    { ...a, [v]: (item.attributes?.categoryLifetime ?? {})[v] ?? lifetimes.categories[v] }
  ), {}));

  const [temporaryLifetime, setTemporaryLifetime] = useState(item.attributes?.temporaryLifetime ?? lifetimes.temporary);

  const [zoomCenter, setZoomCenter] = useState({
    zoom: server.zoom,
    longitude: server.longitude,
    latitude: server.latitude,
  });

  const checkValidation = () => {
    let valid = true;
    if (!checkUrl(item.attributes.services?.bscoder?.url)) {
      setErrorBscoder(true);
      valid = false;
    }
    if (!checkUrl(item.attributes.services?.osrm?.url)) {
      setErrorOsrm(true);
      valid = false;
    }
    return valid;
  };

  const handleSave = async () => {
    if (!checkValidation()) {
      return;
    }

    const services = {
      bscoder: { url: formatUrl(item.attributes.services?.bscoder?.url) },
      osrm: { url: formatUrl(item.attributes.services?.osrm?.url) },
    };

    const body = {
      ...item,
      zoom: Number(zoomCenter.zoom ?? 0),
      longitude: Number(zoomCenter.longitude ?? 0),
      latitude: Number(zoomCenter.latitude ?? 0),
      attributes: {
        ...item.attributes,
        services,
        categoryLifetime,
        temporaryLifetime,
      },
    };

    const response = await fetch('/api/server', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      dispatch(sessionActions.updateServer(body));
      history.goBack();
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
  };

  /**
   * Получает значение состояние значения From для трека
   */
  /* const getFrom = (period) => {
    const periodArray = period.split('_');
    const selectedFrom = moment().subtract(Number(periodArray[0]), period[1]);
    console.error(selectedFrom);
    return selectedFrom;
  }; */

  const changeService = (value, service) => {
    const services = { ...item.attributes.services || {} };
    services[service] = { url: value };
    setItem({ ...item, attributes: { ...item.attributes, services } });
  };

  const sortPeriod = (a, b) => {
    const aArray = a.split('_');
    const bArray = b.split('_');
    const aSuf = Number(aArray[0]);
    const bSuf = Number(bArray[0]);
    const aPre = aArray[1];
    const bPre = bArray[1];
    if (aPre === bPre) {
      return aSuf - bSuf;
    }
    if (aPre === 's' && ['m', 'h'].includes(bPre)) {
      return -1;
    }
    if (aPre === 'm' && bPre === 'h') {
      return -1;
    }
    return 0;
  };

  const changeTailPeriod = (val) => {
    const periods = [...(item.attributes.tailPeriods || tailPeriods)];
    const valIndex = periods.indexOf(val);
    if (valIndex === -1) {
      periods.push(val);
    } else {
      periods.splice(valIndex, 1);
    }
    setItem({ ...item, attributes: { ...item.attributes, tailPeriods: periods.sort(sortPeriod) } });
  };

  const getCheckedPeriod = (val) => {
    const periods = item.attributes.tailPeriods || tailPeriods;
    return periods.indexOf(val) !== -1;
  };

  // const concatValue = (val, symbol = '_') => `${val.value}${symbol}${val.time}`;

  const isNumberRangeCorrect = (event) => event.target.value > MIN_VALUE && event.target.value <= MAX_VALUE;

  const handleChoiceForTemporaryDevice = (item) => {
    if (typeof item === 'number' && (item > MIN_VALUE && item <= MAX_VALUE)) {
      setTemporaryLifetime((prev) => ({ ...prev, value: item }));
    } else {
      setTemporaryLifetime((prev) => ({ ...prev, time: item }));
    }
  };

  const onChangeIcon = (icon) => {
    setItem(
      { ...item, attributes: { ...item.attributes, registerUnknownDefaultIcon: icon } },
    );
  };

  const isValidatedInt = (value) => {
    const numValue = Number(value);
    if (Number.isInteger(numValue)) {
      return true;
    }
    return false;
  };

  const isValidatedNum = (value) => {
    const numValue = Number(value);
    if (Number.isNaN(numValue)) {
      return false;
    }
    return true;
  };

  const isValidatedLongitude = (value) => {
    const numValue = Number(value);
    if (isValidatedNum(numValue) && numValue >= -180 && numValue <= 180) {
      return true;
    }
    return false;
  };

  const isValidatedLatitude = (value) => {
    const numValue = Number(value);
    if (isValidatedNum(numValue) && numValue >= -90 && numValue <= 90) {
      return true;
    }
    return false;
  };

  const isValidatedZoom = (value) => {
    const numValue = Number(value);
    if (isValidatedInt(numValue) && numValue >= 0 && numValue <= 15) {
      return true;
    }
    return false;
  };

  const isDisabled = () => {
    if (!isValidatedLongitude(zoomCenter.longitude)) {
      return true;
    }
    if (!isValidatedLatitude(zoomCenter.latitude)) {
      return true;
    }
    if (!isValidatedZoom(zoomCenter.zoom)) {
      return true;
    }
    if (item.attributes.registerUnknown && !item.attributes.registerUnknownDefaultGroupId) {
      return true;
    }
    return false;
  };

  return (
    <OptionsLayout>
      <Container maxWidth="xs" className={classes.container}>
        {item && (
          <>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedPreferences')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                {/* <SelectField
                  value={item.attributes.timezone || ''}
                  emptyValue=""
                  onChange={(e) => setItem({ ...item, attributes: { ...item.attributes, timezone: e.target.value } })}
                  endpoint="/api/server/timezones"
                  keyGetter={(it) => it}
                  titleGetter={(it) => it}
                  label={t('sharedTimezone')}
                  margin="normal"
                  variant="filled"
                /> */}
                <TextField
                  label={t('mapCustomLabel')}
                  margin="normal"
                  value={item.mapUrl || ''}
                  onChange={(event) => setItem({ ...item, mapUrl: event.target.value })}
                  variant="filled"
                />
                <FormControl variant="filled" fullWidth margin="normal">
                  <InputLabel>{t('mapScheme')}</InputLabel>
                  <Select value={item.map} onChange={(e) => setItem({ ...item, map: e.target.value })}>
                    <MenuItem value="osm">OSM</MenuItem>
                    <MenuItem value="tms">TMS</MenuItem>
                  </Select>
                </FormControl>
                <Typography variant="body2" style={{ textAlign: 'center' }}>
                  {t('serverCenterZommTitle')}
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <TextField
                      margin="normal"
                      value={zoomCenter.latitude}
                      onChange={(event) => setZoomCenter({ ...zoomCenter, latitude: event.target.value })}
                      label={t('serverLatitudeLow')}
                      variant="filled"
                      error={!isValidatedLatitude(zoomCenter.latitude)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      margin="normal"
                      value={zoomCenter.longitude}
                      onChange={(event) => setZoomCenter({ ...zoomCenter, longitude: event.target.value })}
                      label={t('serverLongitudeLow')}
                      variant="filled"
                      error={!isValidatedLongitude(zoomCenter.longitude)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      margin="normal"
                      value={zoomCenter.zoom}
                      onChange={(event) => setZoomCenter({ ...zoomCenter, zoom: event.target.value })}
                      label={t('serverZoomLow')}
                      variant="filled"
                      error={!isValidatedZoom(zoomCenter.zoom)}
                    />
                  </Grid>
                </Grid>

                <FormControl variant="filled" fullWidth margin="normal">
                  <InputLabel>{t('settingsSpeedUnit')}</InputLabel>
                  <Select
                    value={item.attributes.speedUnit ?? 'kn'}
                    onChange={(e) => setItem({ ...item, attributes: { ...item.attributes, speedUnit: e.target.value } })}
                  >
                    <MenuItem value="kmh">{t('sharedKmh')}</MenuItem>
                    <MenuItem value="mps">{t('sharedMps')}</MenuItem>
                    <MenuItem value="mph">{t('sharedMph')}</MenuItem>
                    <MenuItem value="kn">{t('sharedKn')}</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  label={t('webUrlLabel')}
                  margin="normal"
                  value={item.webUrl || ''}
                  onChange={(event) => setItem({ ...item, webUrl: event.target.value })}
                  placeholder="http://your-web-address"
                  variant="filled"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={`${t('insertWebUrlTitle')}: ${window.location.origin}`}>
                          <IconButton
                            className={classes.adornmentButton}
                            onClick={() => setItem({ ...item, webUrl: window.location.origin })}
                          >
                            <PostAddIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  value={item.logLifetime}
                  onChange={(event) => {
                    const value = Number(event.target.value);
                    if (!Number.isNaN(value)) {
                      setItem({ ...item, logLifetime: value });
                    }
                  }}
                  label={`${t('logLifetimeTitle')}, ${t('sharedDays')}`}
                  variant="filled"
                />

                <TextField
                  margin="normal"
                  value={item.attributes.temporaryStoragetime ?? 0}
                  onChange={(event) => {
                    const value = Number(event.target.value);
                    if (!Number.isNaN(value)) {
                      setItem({ ...item, attributes: { ...item.attributes, temporaryStoragetime: value } });
                    }
                  }}
                  label={`${t('temporaryStoragetimeTitle')}, ${t('sharedDays')}`}
                  variant="filled"
                />

                <TextField
                  margin="normal"
                  value={item.attributes.positionStoragetime ?? 0}
                  onChange={(event) => {
                    const value = Number(event.target.value);
                    if (!Number.isNaN(value)) {
                      setItem({ ...item, attributes: { ...item.attributes, positionStoragetime: value } });
                    }
                  }}
                  label={`${t('positionStoragetimeTitle')}, ${t('sharedDays')}`}
                  variant="filled"
                />

                <Button onClick={() => setPeriodDialogOpen(true)} fullWidth variant="contained">
                  {t('tailTimeTitle')}
                </Button>

                <Dialog
                  maxWidth="xs"
                  open={periodDialogOpen}
                  onClose={() => setPeriodDialogOpen(false)}
                >
                  <DialogTitle>{t('tailTimeTitle')}</DialogTitle>
                  <DialogContent
                    style={{ width: 250 }}
                  >
                    <FormGroup>
                      {tailPeriodsChoosing.map((val) => (
                        <FormControlLabel
                          key={val}
                          control={<Checkbox checked={getCheckedPeriod(val)} onChange={() => changeTailPeriod(val)} />}
                          label={getPeriodName(val, t)}
                        />
                      ))}
                    </FormGroup>
                  </DialogContent>

                  <DialogActions>
                    <Button onClick={() => setPeriodDialogOpen(false)}>Закрыть</Button>
                  </DialogActions>
                </Dialog>

                {/* Кнопка для открытия формы настройки периода времени, когда скрывать устройства в левом боковом меню */}
                <Button onClick={() => setLifetimeDevicesDialog(true)} fullWidth variant="contained">
                  {t('ServerPageDeviceDisplayPeriod')}
                </Button>

                {/* Форма для настройки периода времени, когда скрывать устройства в левом боковом меню */}
                <Dialog
                  maxWidth="xs"
                  open={lifetimeDevicesDialog}
                  onClose={() => setLifetimeDevicesDialog(false)}
                >
                  <DialogTitle>{t('ServerPageDeviceDisplayPeriod')}</DialogTitle>
                  <DialogContent
                    style={{ width: 350 }}
                  >
                    <Grid container spacing={1}>
                      {Object.entries(categoryLifetime).map(([category, lifetime]) => (
                        <Grid item xs={12}>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={8}>
                              <TextField
                                label={t(`ServerPage${toTitleCase(category)}`)}
                                value={lifetime.value}
                                inputProps={{ min: MIN_VALUE, max: MAX_VALUE }}
                                fullWidth
                                margin="normal"
                                variant="filled"
                                type="number"
                                onChange={(e) => isNumberRangeCorrect(e)
                                  && setCategoryLifetime((prevValue) => ({ ...prevValue, [category]: { ...prevValue[category], value: Number(e.target.value) } }))}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <FormControl
                                style={{ whiteSpace: 'nowrap' }}
                                fullWidth
                                margin="normal"
                              >
                                <Select
                                  value={lifetime.time}
                                  fullWidth
                                  onChange={(e) => setCategoryLifetime((prevValue) => ({ ...prevValue, [category]: { ...prevValue[category], time: e.target.value } }))}
                                >
                                  {timesList.map((val) => (
                                    <MenuItem key={val} value={val}>
                                      {t(`GlobalTime${toTitleCase(val)}`)}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}

                      <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item xs={8}>
                            <TextField
                              label={t('ServerPageTemporary')}
                              value={temporaryLifetime.value}
                              inputProps={{ min: MIN_VALUE, max: MAX_VALUE }}
                              fullWidth
                              margin="normal"
                              variant="filled"
                              type="number"
                              onChange={(event) => handleChoiceForTemporaryDevice(Number(event.target.value), temporaryLifetime.value)}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl
                              style={{ whiteSpace: 'nowrap' }}
                              fullWidth
                              margin="normal"
                            >
                              <Select
                                value={temporaryLifetime.time}
                                fullWidth
                                onChange={(event) => handleChoiceForTemporaryDevice(event.target.value, temporaryLifetime.time)}
                              >
                                {timesList.map((val) => (
                                  <MenuItem key={val} value={val}>
                                    {t(`GlobalTime${toTitleCase(val)}`)}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setLifetimeDevicesDialog(false)}>Закрыть</Button>
                  </DialogActions>
                </Dialog>
              </AccordionDetails>
            </Accordion>

            <ExtraMapsBlock item={item} setItem={setItem} />

            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedServices')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <TextField
                  margin="normal"
                  value={item.attributes.services?.bscoder?.url || ''}
                  onChange={(event) => {
                    setErrorBscoder(false);
                    changeService(event.target.value, 'bscoder');
                  }}
                  label={t('bscoderServiceLabel')}
                  error={errorBscoder}
                  helperText={errorBscoder ? t('errorUrl') : ''}
                  placeholder="http://your-bscoder-service"
                  variant="filled"
                />
                <TextField
                  margin="normal"
                  value={item.attributes.services?.osrm?.url || ''}
                  onChange={(event) => {
                    setErrorOsrm(false);
                    changeService(event.target.value, 'osrm');
                  }}
                  label={t('OSRMServiceLabel')}
                  error={errorOsrm}
                  helperText={errorOsrm ? t('errorUrl') : ''}
                  placeholder="http://your-osrm-service"
                  variant="filled"
                />
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('autoregistrationTitle')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!item.attributes.registerUnknown}
                      onChange={(event) => setItem(
                        { ...item, attributes: { ...item.attributes, registerUnknown: event.target.checked } },
                      )}
                    />
                  )}
                  label={t('registerUnknownTitle')}
                />
                <SelectField
                  margin="normal"
                  value={item.attributes.registerUnknownDefaultGroupId || 0}
                  onChange={(event) => setItem(
                    { ...item, attributes: { ...item.attributes, registerUnknownDefaultGroupId: event.target.value } },
                  )}
                  endpoint="/api/groups"
                  label={t('groupParent')}
                  variant="filled"
                  disabled={!item.attributes.registerUnknown}
                  required
                  error={item.attributes.registerUnknown && !item.attributes.registerUnknownDefaultGroupId}
                />
                <SelectDeviceIcon
                  icons={icons}
                  currentIcon={item.attributes.registerUnknownDefaultIcon}
                  onChange={onChangeIcon}
                  disabled={!item.attributes.registerUnknown}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedPermissions')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={item.registration}
                      onChange={(event) => setItem({ ...item, registration: event.target.checked })}
                    />
                  )}
                  label={t('serverRegistration')}
                />
              </AccordionDetails>
            </Accordion>
          </>
        )}
        <FormControl fullWidth margin="normal">
          <div className={classes.buttons}>
            <Button type="button" color="primary" variant="outlined" onClick={() => history.goBack()}>
              {t('sharedCancel')}
            </Button>
            <Button type="button" color="primary" variant="contained" onClick={handleSave} disabled={isDisabled()}>
              {t('sharedSave')}
            </Button>
          </div>
        </FormControl>
      </Container>
    </OptionsLayout>
  );
};

export default ServerPage;
