import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogContent, DialogTitle, LinearProgress,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from './LocalizationProvider';

const PageProgress = () => {
  const t = useTranslation();
  const [open, setOpen] = useState(true);
  const [title, setTitle] = useState('');
  const initMap = useSelector((state) => state.map.init);
  const initBscoder = useSelector((state) => state.bs.init);
  const bscoderUrl = useSelector((state) => state.session.server?.attributes.services?.bscoder?.url);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (initMap) {
      handleClose();
    }
    if ((!initBscoder && bscoderUrl) || !initMap) {
      setTitle(t('mapLoading'));
    } else {
      setTitle('');
    }
  }, [initMap, initBscoder]);

  return (
    <Dialog
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <LinearProgress color="secondary" />
      </DialogContent>
    </Dialog>
  );
};

export default PageProgress;
