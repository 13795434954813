import React from 'react';
import {
  Select, MenuItem, FormControl, makeStyles, IconButton, Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from './LocalizationProvider';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import tailPeriods from '../static/tailPeriods';
import { sortPeriods } from '../utils/selectors';
import { tailActions } from '../../store';

const useStyles = makeStyles((theme) => ({
  progress: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
}));

const TailFilter = ({
  period, setPeriod, progress,
}) => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();
  const item = useSelector((state) => state.tail.tail);
  const periods = useSelector((state) => state.session.server.attributes.tailPeriods || tailPeriods);

  const changePeriod = (value) => {
    setPeriod(value);
    // dispatch(tailActions.changePeriod(value));
    window.localStorage.setItem('periodTail', value);
  };

  return (
    <>
      {progress ? (
        <div className={classes.progress}>
          <CircularProgressWithLabel value={progress} />
        </div>
      ) : (
        <Tooltip title={t('tailTitle')}>
          <IconButton onClick={() => dispatch(tailActions.turnTail(!item))}>
            <img
              src={`/images/icon/switch/${item ? 'tail-1' : 'tail-off'}.svg`}
              alt=""
            />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={t('tailTimeTitle')}>
        <FormControl variant="filled" fullWidth>
          <Select value={period} onChange={(e) => changePeriod(e.target.value)}>
            {[...periods].sort(sortPeriods).map((val) => (
              <MenuItem key={val} value={val}>
                {`${val.split('_')[0]} ${t(`shared${val.split('_')[1].toUpperCase()}`)}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Tooltip>
    </>
  );
};

export default TailFilter;
