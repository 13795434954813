import {
  formatMsToTime, getCategoryParam, toTitleCase,
} from '../../../utils/formatter';
import { prefixString } from '../../../utils/stringUtils';
import { ICON_NAME_SEPARATOR } from '../constants';

const getShortDateTime = (item, groups, now, t) => formatMsToTime(now.valueOf() - new Date(item.lastUpdate).valueOf(), t);

const getDateTime = (value) => new Date(value).toLocaleString();

const getGroupName = (item, groups) => groups[item.groupId]?.name;

// eslint-disable-next-line no-confusing-arrow
const getCategoryName = (item, t) => item.category ? t(`device${toTitleCase(item.category)}`) : '';

const getSourceName = (item, sources, objects) => {
  const foundSourceId = getCategoryParam(item, 'sourceId');
  if (!foundSourceId) return '';
  const foundSource = sources[foundSourceId];
  return foundSource ? (objects[foundSource]?.name ?? '') : foundSourceId;
};

const getProperty = (item, positions, name) => positions[item.id]?.attributes?.categoryParams?.[name] ?? '';

const getColorConnectionStatus = (status, theme) => {
  switch (status) {
    case 'connected':
      return theme.palette.common.green;
    case 'disconnected':
    case 'error':
    default:
      return theme.palette.common.red;
  }
};

const getButtonControlModeColor = (workMode) => {
  switch (workMode) {
    case 'detection':
      return '#3397f5';
    case 'suppression':
      return '#fa675a';
    case 'idle':
    case 'unknown':
    default:
      return '#ababab';
  }
};

const getWorkModeButtonColor = (item, mode) => {
  if (item.attributes.categoryParams.workMode === mode) {
    return getButtonControlModeColor(mode);
  }
  return '#ededed';
};

const concateStringsFromNotificator = (item, t) => {
  if (item.notificators) {
    return item.notificators
      .split(/[, ]+/)
      .filter(Boolean)
      .map((it) => t(prefixString('notificator', it)))
      .join(', ');
  }
  return '';
};

// eslint-disable-next-line no-confusing-arrow
const getTextFromIconWithSeparator = (item, t) => item.icon
  ? `${t(`icon${item.icon.replace(/^\w/, (c) => c.toUpperCase())}`)}${ICON_NAME_SEPARATOR}${item.icon}`
  : t('iconDefault');

const getTextFromIcon = (item, t) => t(item.icon ? `icon${item.icon.replace(/^\w/, (c) => c.toUpperCase())}` : 'iconDefault');

export {
  getShortDateTime,
  getDateTime,
  getGroupName,
  getSourceName,
  getProperty,
  getColorConnectionStatus,
  getWorkModeButtonColor,
  getButtonControlModeColor,
  getCategoryName,
  concateStringsFromNotificator,
  getTextFromIconWithSeparator,
  getTextFromIcon,
};
