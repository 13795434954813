import 'maplibre-gl/dist/maplibre-gl.css';
import './switcher/switcher.css';
import maplibregl from 'maplibre-gl';
import React, {
  useRef, useLayoutEffect, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SwitcherControl } from './switcher/switcher';
import {
  styleCarto, styleCustom, styleOsm, styleYandex,
} from './mapStyles';
import { useTranslation } from '../common/components/LocalizationProvider';
import { mapActions } from '../store';
import PageProgress from '../common/components/PageProgress';
/* import { loadImage, prepareIcon } from './mapUtil';
import palette from '../common/theme/palette';
import icons from '../common/static/icons';
import theme from '../common/theme'; */

const element = document.createElement('div');
element.style.width = '100%';
element.style.height = '100%';
element.style.boxSizing = 'initial';

// const zoomInitialized = JSON.parse(localStorage.getItem('zoomInitialized'));

export const map = new maplibregl.Map({
  container: element,
  locale: {
    'NavigationControl.ZoomIn': 'Приблизить',
    'NavigationControl.ZoomOut': 'Отдалить',
    'NavigationControl.ResetBearing': 'Повернуть карту',
  },
  // center: zoomInitialized ? zoomInitialized.center : [0, 0],
  // zoom: zoomInitialized ? zoomInitialized.zoom : 0,
});

let ready = false;
const readyListeners = new Set();

const addReadyListener = (listener) => {
  readyListeners.add(listener);
  listener(ready);
};

const removeReadyListener = (listener) => {
  readyListeners.delete(listener);
};

const updateReadyValue = (value) => {
  ready = value;
  readyListeners.forEach((listener) => listener(value));
};

const initMap = async () => {
  if (ready) return;
  /*   if (!map.hasImage('background')) {
      const promises = [
        loadImage('/images/background.svg'),
        loadImage('/images/icon/position/start.svg'),
        loadImage('/images/icon/position/finish.svg'),
        loadImage('/images/icon/position/parking.svg'),
        loadImage('/images/icon/position/arrow.svg'),
        loadImage('/images/icon/position/bs.svg'),
        loadImage('/images/icon/position/point.svg'),
        loadImage('/images/icon/position/tower-bs.svg'),
      ];
      const [background, start, finish, parking, arrow, bs, point, towerBs] = await Promise.all(promises);
      map.addImage('background', prepareIcon(background), {
        pixelRatio: window.devicePixelRatio,
      });
      map.addImage('background-beige', prepareIcon(background, null, { colorBac: palette.common.beige }), {
        pixelRatio: window.devicePixelRatio,
      });
      map.addImage('background-parking', prepareIcon(background, null, { colorBac: palette.common.blue }), {
        pixelRatio: window.devicePixelRatio,
      });
      map.addImage('background-selected', prepareIcon(background, null, { colorBac: palette.common.red }), {
        pixelRatio: window.devicePixelRatio,
      });
      map.addImage('emptymoon', prepareIcon(background, start, { color: palette.common.burgundy, downSize: true }), {
        pixelRatio: window.devicePixelRatio,
      });
      map.addImage('fullmoon', prepareIcon(background, finish, { color: palette.common.burgundy }), {
        pixelRatio: window.devicePixelRatio,
      });
      map.addImage('parking', prepareIcon(background, parking, { downSize: true }), {
        pixelRatio: window.devicePixelRatio,
      });
      map.addImage('arrow', prepareIcon(background, arrow, { color: palette.common.black }), {
        pixelRatio: window.devicePixelRatio,
      });
      map.addImage('arrow-hot', prepareIcon(background, arrow, { color: palette.common.black, colorBac: palette.common.red }), {
        pixelRatio: window.devicePixelRatio,
      });
      map.addImage('bs', prepareIcon(background, bs, { color: palette.common.black }), {
        pixelRatio: window.devicePixelRatio,
      });
      map.addImage('bs-hot', prepareIcon(background, bs, { color: palette.common.black, colorBac: palette.common.red }), {
        pixelRatio: window.devicePixelRatio,
      });
      map.addImage('point', prepareIcon(background, point, { color: palette.common.black }), {
        pixelRatio: window.devicePixelRatio,
      });
      map.addImage('point-hot', prepareIcon(background, point, { color: palette.common.black, colorBac: palette.common.red }), {
        pixelRatio: window.devicePixelRatio,
      });
      map.addImage('tower-bs', prepareIcon(background, towerBs, { color: palette.common.blue2 }), {
        pixelRatio: window.devicePixelRatio,
      });
      loadImage('/images/course.svg').then((icon) => {
        map.addImage('course', prepareIcon(null, icon, { downSize: true }), { pixelRatio: window.devicePixelRatio });
      });
      await Promise.all(icons.map(async (marker) => {
        const results = [];
        Object.values(palette.markers).forEach((color) => {
          results.push(loadImage(`/images/icon/device/${marker}.svg`).then((icon) => {
            map.addImage(`${marker}-${color.toLowerCase()}`, prepareIcon(null, icon, { color, downSize: true }), {
              pixelRatio: window.devicePixelRatio,
            });
          }));
        });
        await Promise.all(results);
      }));
      await Promise.all(icons.map(async (iconName) => {
        const results = [];
        Object.values(theme.trackColors).forEach((color) => {
          results.push(loadImage(`/images/icon/device/${iconName}.svg`).then((icon) => {
            map.addImage(`temporary-${iconName}-${color.toLowerCase()}`, prepareIcon(background, icon, { color: '#fff', colorBac: color }), {
              pixelRatio: window.devicePixelRatio,
            });
          }));
        });
        await Promise.all(results);
      }));
      await Promise.all(icons.map(async (iconName) => {
        const results = [];
        ['green', 'red', 'gray'].forEach((color) => {
          results.push(loadImage(`/images/icon/device/${iconName}.svg`).then((icon) => {
            map.addImage(`${iconName}-${color}`, prepareIcon(background, icon, { color: palette.common[color] }), {
              pixelRatio: window.devicePixelRatio,
            });
          }));
        });
        await Promise.all(results);
      }));
      await Promise.all(icons.map(async (iconName) => {
        const results = [];
        Object.values(theme.trackColors).forEach((colorStroke) => {
          ['green', 'red', 'gray'].forEach((color) => {
            results.push(loadImage(`/images/icon/device/${iconName}.svg`).then((icon) => {
              map.addImage(`${iconName}-${color}-${colorStroke}`, prepareIcon(background, icon, { color: palette.common[color], colorStroke }), {
                pixelRatio: window.devicePixelRatio,
              });
            }));
          });
        });
        await Promise.all(results);
      }));
    } */
  updateReadyValue(true);
};

map.addControl(new maplibregl.NavigationControl({
  showCompass: true,
}));

const switcher = new SwitcherControl(
  () => updateReadyValue(false),
  () => {
    const waiting = () => {
      if (!map.loaded()) {
        setTimeout(waiting, 100);
      } else {
        initMap();
      }
    };
    waiting();
  },
);

map.addControl(switcher);

const Map = ({ children, noFixed, needMoveMap }) => {
  const containerEl = useRef(null);
  const t = useTranslation();
  const dispatch = useDispatch();

  const [mapReady, setMapReady] = useState(false);

  const mapUrl = useSelector((state) => state.session.server?.mapUrl);
  const mapScheme = useSelector((state) => state.session.server?.map);
  const zoom = useSelector((state) => state.session.server.zoom);
  const center = useSelector((state) => [state.session.server.longitude, state.session.server.latitude]);
  const init = useSelector((state) => state.map.init);

  useEffect(() => {
    map.resize();
  }, [needMoveMap]);

  useEffect(() => {
    if (mapReady && !init) {
      map.setZoom(zoom);
      map.setCenter(center);
      dispatch(mapActions.init(true));
    }
  }, [init, zoom, mapReady]);

  useEffect(() => {
    switcher.updateDispatch(dispatch);
  }, []);

  useEffect(() => {
    switcher.updateStyles([
      { id: 'yandex', title: t('mapYandexMap'), uri: styleYandex() },
      { id: 'osm', title: t('mapOsm'), uri: styleOsm() },
      { id: 'carto', title: t('mapCarto'), uri: styleCarto() },
      { id: 'custom', title: t('mapCustomLabel'), uri: styleCustom(mapUrl, '', mapScheme) },
    ], localStorage.getItem('selectedMap') || 'yandex');
  }, [mapUrl]);

  useEffect(() => {
    const listener = (ready) => setMapReady(ready);
    addReadyListener(listener);
    return () => {
      removeReadyListener(listener);
    };
  }, []);

  useLayoutEffect(() => {
    const currentEl = containerEl.current;
    currentEl.appendChild(element);
    map.resize();
    return () => {
      currentEl.removeChild(element);
    };
  }, [containerEl]);

  return (
    <div
      style={{
        width: needMoveMap ? 'calc(100% - 390px)' : '100%',
        height: '100%',
        position: noFixed ? 'static' : 'fixed',
        left: 0,
      }}
      ref={containerEl}
    >
      {mapReady && children}
      <PageProgress />
    </div>
  );
};

export default Map;
