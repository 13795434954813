import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, FormControl, IconButton, InputLabel, LinearProgress, ListItemText, MenuItem, Select, Table, TableBody,
  TableCell, TableHead, TablePagination, TableRow, Tooltip, useTheme,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import PageLayout from '../../../common/components/PageLayout';
import useReportStyles from '../hooks/useReportStyles';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import SuperMenuItem from '../../../common/components/SuperMenuItem';
import ReportFilter from './ReportFilter';
import ReportsMenu from './ReportsMenu';

const ReportTemplate = ({
  items, progress, progressDevices, selectedItem, setSelectedItem, columnsArray, handleSubmit, typeSortingDefault, formatValue, page,
  setPage, typesSorting, typeReport, offColumns, setOffColumns, setPositionsPath, columnsArrayFiltered,
  mapOn, chooseEvents, eventTypes, events, breadcrumbs, map, withoutDevices, devicesObject, devicesList,
}) => {
  const theme = useTheme();
  const t = useTranslation();
  const classes = useReportStyles();
  const [typeSorting, setTypeSorting] = useState(typeSortingDefault);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const pageEndRef = useRef(null);

  const scrollToBottom = () => pageEndRef.current?.scrollIntoView({ behavior: 'smooth' });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    scrollToBottom();
  }, [page]);

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={breadcrumbs}>
      <div className={classes.container}>
        {map}
        <div className={classes.containerMain}>
          <div className={classes.header}>
            <ReportFilter
              progress={progress}
              progressDevices={progressDevices}
              handleSubmit={handleSubmit}
              columns={columnsArray}
              typeReport={typeReport}
              off={offColumns}
              setOff={setOffColumns}
              setSelectedItem={setSelectedItem}
              setPositionsPath={setPositionsPath}
              withoutDevices={withoutDevices}
              devicesList={devicesList}
            >
              {events && (
                <div className={classes.filterItem}>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel>{t('reportEventTypes')}</InputLabel>
                    <Select
                      value={eventTypes}
                      onChange={(e) => chooseEvents(e.target.value)}
                      multiple
                      renderValue={(selected) => {
                        const eventsSelected = selected.map((selectedItem) => t(events[selectedItem]));
                        return eventsSelected.join(', ');
                      }}
                      MenuProps={theme.overrides.MenuProps}
                    >
                      <MenuItem value="all">
                        <SuperMenuItem all={eventTypes.length} />
                      </MenuItem>
                      {Object.keys(events).map((key) => (
                        <MenuItem value={key} style={{ padding: theme.spacing(0, 1) }} key={key}>
                          <Checkbox checked={eventTypes.includes(key)} />
                          <ListItemText primary={t(events[key])} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
            </ReportFilter>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                {mapOn && <TableCell className={classes.columnAction} />}
                {columnsArrayFiltered.map((column) => (
                  <TableCell key={column[0]} className={column[2] ? classes.field : ''}>
                    {t(column[1])}
                    {!!column[2] && (
                      <Tooltip title={t('sortingTitle')}>
                        <IconButton
                          size="small"
                          className={`${![column[0], `${column[0]}Reverse`].includes(typeSorting) && classes.iconSorting}
                       ${typeSorting.includes('Reverse') && classes.iconSortingReverse}`}
                          onClick={() => {
                            setTypeSorting(typeSorting === column[0] ? `${column[0]}Reverse` : column[0]);
                          }}
                        >
                          <ArrowDownwardIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!progress && items
                .sort(typesSorting[typeSorting])
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <TableRow key={item.id}>
                    {mapOn && (
                      <TableCell className={classes.columnAction} padding="none">
                        {selectedItem === item ? (
                          <Tooltip title={t('hidePositionTitle')}>
                            <IconButton size="small" onClick={() => setSelectedItem(null)}>
                              <GpsFixedIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title={t('selectPositionTitle')}>
                            <IconButton size="small" onClick={() => setSelectedItem(item)}>
                              <LocationSearchingIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    )}
                    {columnsArrayFiltered.map((key) => (
                      <TableCell key={key[0]} className={classes.field}>
                        {formatValue(item, key[0], devicesObject)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            ref={pageEndRef}
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={items.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('labelRowsPerPage')}
            labelDisplayedRows={({ from, to, count }) => (
              `${from}–${to} ${t('ofRowsLable')} ${count !== -1 ? count : `${t('moreThenRowsLable')} ${to}`}`
            )}
          />
          {!!progress && (
            <LinearProgress />
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default ReportTemplate;

ReportTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  progress: PropTypes.number.isRequired,
  columnsArray: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  typeSortingDefault: PropTypes.string.isRequired,
  formatValue: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  typesSorting: PropTypes.objectOf(PropTypes.func).isRequired,
  typeReport: PropTypes.string.isRequired,
  offColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  setOffColumns: PropTypes.func.isRequired,
  columnsArrayFiltered: PropTypes.array.isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedItem: PropTypes.object,
  setSelectedItem: PropTypes.func,
  setPositionsPath: PropTypes.func,
  mapOn: PropTypes.bool,
  chooseEvents: PropTypes.func,
  eventTypes: PropTypes.array,
  events: PropTypes.objectOf(PropTypes.string),
  map: PropTypes.element,
  withoutDevices: PropTypes.bool,
};
