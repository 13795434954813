import theme from '../../common/theme';
import { getTitle, getCategory } from '../../common/utils/formatter';
import { getBeforeId } from './pathFuncs';
import { map } from '../Map';
import { convertToConfig } from '../MapControllerDynamic';

const onMouseEnter = () => map.getCanvas().style.cursor = 'pointer';
const onMouseLeave = () => map.getCanvas().style.cursor = '';

export const createLayers = (id, clustersId, data, onClusterClick, onMarkerClick, onClusters) => {
  const clustersCountId = `${id}-clusters-count`;
  const courseId = `${id}-course`;

  map.addSource(id, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
    cluster: !!onClusters,
    clusterMaxZoom: 14,
    clusterRadius: 20,
  });
  map.addLayer({
    id,
    type: 'symbol',
    source: id,
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-image': '{icon}',
      'icon-allow-overlap': data.type !== 'replay',
      'icon-size': { type: 'identity', property: 'iconSize' },
      'icon-rotate': data.type === 'replay' ? { type: 'identity', property: 'course' } : 0,
      'text-field': '{title}',
      'text-allow-overlap': data.type !== 'replay',
      'text-anchor': 'center',
      'text-offset': { type: 'identity', property: 'textOffset' },
      'text-font': ['Roboto Medium'],
      'text-size': 12,
    },
    paint: {
      'text-halo-color': 'white',
      'text-halo-width': 1,
      'text-color': { type: 'identity', property: 'textColor' },
    },
  }, getBeforeId(id));

  if (data.name === 'current') {
    map.addLayer({
      id: courseId,
      type: 'symbol',
      source: id,
      filter: [
        'all',
        ['!=', '$type', 'Polygon'],
      ],
      layout: {
        'icon-image': '{courseIcon}',
        'icon-rotate': { type: 'identity', property: 'course' },
      },
    }, getBeforeId(courseId));
  }

  if (onClusters) {
    map.addLayer({
      id: clustersId,
      type: 'circle',
      source: id,
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#bbbd55',
          3,
          '#d9a9a3',
          4,
          '#d6796d',
          6,
          '#e3513d',
        ],
        'circle-opacity': 0.8,
        'circle-blur': 0.1,
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          data.type === 'replay' ? 10 : 15,
          3,
          15,
          4,
          20,
          6,
          30,
        ],
      },
    }, getBeforeId(clustersId));

    map.addLayer({
      id: clustersCountId,
      type: 'symbol',
      source: id,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['Roboto Regular'],
        'text-size': [
          'step',
          ['get', 'point_count'],
          data.type === 'replay' ? 10 : 15,
          4,
          15,
        ],
      },
    }, getBeforeId(clustersCountId));

    map.on('mouseenter', clustersId, onMouseEnter);
    map.on('mouseleave', clustersId, onMouseLeave);
    map.on('click', clustersId, onClusterClick);
  }

  map.on('mouseenter', id, onMouseEnter);
  map.on('mouseleave', id, onMouseLeave);
  map.on('click', id, onMarkerClick);

  return () => {
    Array.from(map.getContainer().getElementsByClassName('maplibregl-popup'))
      .forEach((el) => el.remove());

    if (onClusters) {
      map.off('mouseenter', clustersId, onMouseEnter);
      map.off('mouseleave', clustersId, onMouseLeave);
      map.off('click', clustersId, onClusterClick);
      if (map.getLayer(clustersId)) {
        map.removeLayer(clustersId);
      }
      if (map.getLayer(clustersCountId)) {
        map.removeLayer(clustersCountId);
      }
    }

    map.off('mouseenter', id, onMouseEnter);
    map.off('mouseleave', id, onMouseLeave);
    map.off('click', id, onMarkerClick);

    if (map.getLayer(id)) {
      map.removeLayer(id);
    }
    if (map.getLayer(courseId)) {
      map.removeLayer(courseId);
    }
    if (map.getSource(id)) {
      map.removeSource(id);
    }
  };
};

export const crateTemporaryLayer = (id, data, onMarkerClick) => {
  map.addSource(id, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });

  map.addLayer({
    id,
    type: 'symbol',
    source: id,
    layout: {
      'icon-image': '{icon}',
      'icon-size': 0.7,
      'icon-allow-overlap': true,
      'text-field': '{title}',
      'text-allow-overlap': true,
      'text-anchor': 'bottom',
      'text-offset': data.type === 'extra' ? [0, -1] : [0, -2],
      'text-font': ['Roboto Medium'],
      'text-size': 12,
    },
    // paint: {
    //   'icon-opacity': 1,
    //   'icon-opacity-transition': {
    //     duration: formatTimeoutToMs(temporaryLifetime.value, temporaryLifetime.time),
    //   },
    //   'text-opacity': 1,
    //   'text-opacity-transition': {
    //     duration: formatTimeoutToMs(temporaryLifetime.value, temporaryLifetime.time),
    //   },
    // },
  }, getBeforeId(data?.name));

  map.on('mouseenter', id, onMouseEnter);
  map.on('mouseleave', id, onMouseLeave);
  map.on('click', id, onMarkerClick);

  // if (!isPersistance) {
  //   map.setPaintProperty(id, 'icon-opacity', 0);
  //   map.setPaintProperty(id, 'text-opacity', 0);
  // }

  return () => {
    map.off('click', id, onMarkerClick);
    map.off('mouseenter', id, onMouseEnter);
    map.off('mouseleave', id, onMouseLeave);

    if (map.getLayer(id)) {
      map.removeLayer(id);
    }
    if (map.getSource(id)) {
      map.removeSource(id);
    }
  };
};

export const createRadarLayers = (id, data) => {
  const radarId = `${id}-radar`;
  const sectorId = `${id}-sector`;
  if (data.name === 'current') {
    map.addSource(id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    map.addLayer({
      source: id,
      id: radarId,
      type: 'fill',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'category', 'radar'],
      ],
      paint: {
        'fill-color': { type: 'identity', property: 'radarColor' },
        'fill-outline-color': '#705439',
        'fill-opacity': 0.25,
      },
    }, getBeforeId(radarId));

    map.addLayer({
      source: id,
      id: sectorId,
      type: 'fill',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'category', 'sector'],
      ],
      paint: {
        'fill-color': { type: 'identity', property: 'radarColor' },
        'fill-outline-color': '#705439',
        'fill-opacity': { type: 'identity', property: 'opacity' },
      },
    }, getBeforeId(sectorId));
  }

  return () => {
    Array.from(map.getContainer().getElementsByClassName('maplibregl-popup'))
      .forEach((el) => el.remove());

    if (map.getLayer(radarId)) {
      map.removeLayer(radarId);
    }
    if (map.getLayer(sectorId)) {
      map.removeLayer(sectorId);
    }
    if (map.getSource(id)) {
      map.removeSource(id);
    }
  };
};

export const deviceColor = (device) => {
  switch (device.status) {
    case 'online':
      return 'green';
    case 'offline':
      return 'red';
    default:
      return 'gray';
  }
};

const getStatus = (device, position, t) => {
  let title = '';
  let textColor = '#000';
  const positionParams = position.attributes.categoryParams;
  if (positionParams) {
    switch (device.category) {
      case 'radar':
      case 'anti_uav':
        switch (positionParams.connectionStatus) {
          case 'disconnected':
          case 'error':
            title += `${positionParams.connectionStatusDescription || t(positionParams.connectionStatus)}`;
            textColor = '#9c0000';
            break;
          default:
            break;
        }
        if (positionParams.workModeDescription || positionParams.workMode) {
          title += `\n${positionParams.workModeDescription || t(positionParams.workMode)}`;
        }
        break;
      default:
        break;
    }
  }
  return { title, textColor };
};

export const getPositionProperties = (position, device, t, data, text, positionsSimilar) => {
  const params = { textColor: '#000' };
  const samePositions = positionsSimilar && positionsSimilar[position.deviceId] && positionsSimilar[position.deviceId][`${position.longitude}-${position.latitude}`];
  const numberPoints = samePositions?.length;
  let iconReplay;
  if (!data.icon) {
    if (data.type === 'replay') {
      if (position.attributes?.approximate) {
        iconReplay = numberPoints > 1 ? 'bs-hot' : 'bs';
      } else if (position.course !== -1) {
        iconReplay = numberPoints > 1 ? 'arrow-hot' : 'arrow';
      } else {
        iconReplay = numberPoints > 1 ? 'point-hot' : 'point';
      }
    } else if (data.type === 'current') {
      const primaryColor = device.attributes.color || theme.trackColors.replay0;
      if (device.temporary) {
        iconReplay = convertToConfig({
          temporary: 'temporary',
          icon: device.icon || 'default',
          primary: primaryColor,
          category: device.category,
        });
      } else {
        iconReplay = convertToConfig({
          icon: device.icon || 'default',
          primary: device.category === 'detected_object' ? primaryColor : deviceColor(device),
          colorStroke: primaryColor,
          category: device.category,
        });
      }
    }
  }
  let title = '';
  if (text) {
    title = getTitle(device.name, position.fixTime, data.name);
  }
  if (data.type === 'current') {
    const extraText = getStatus(device, position, t);
    if (extraText.title) {
      title += `\n${extraText.title}`;
      params.textColor = extraText.textColor;
    }
  }
  return {
    id: position.id,
    course: position.course || 0,
    numberPoints,
    icon: data.icon || iconReplay,
    iconSize: device.temporary && data.type === 'current' ? 0.7 : data.iconSize,
    courseIcon: position.course !== -1 ? 'course' : null,
    title,
    deviceId: device.id,
    radarColor: device.attributes.radarColor,
    textOffset: [0, -4],
    ...params,
  };
};

export const getStopProperties = (position, device, data, text, groups) => {
  let title = '';
  if (text) {
    title = `${getTitle(device.name, position.startTime, data.name)} - ${getTitle(device.name, position.endTime, data.name)}`;
  }
  return {
    id: position.positionId,
    title,
    course: 0,
    icon: data.icon,
    type: data.name,
    name: device.name,
    deviceCategory: getCategory(device),
    group: groups[device.groupId]?.name,
    deviceId: position.deviceId,
    positionId: position.id,
    duration: position.duration,
    startTime: position.startTime,
    endTime: position.endTime,
    latitude: position.latitude,
    longitude: position.longitude,
    coordinates: `${Number(position.latitude).toFixed(5)}, ${Number(position.longitude).toFixed(5)}`,
    address: position.address,
  };
};

export const getPositionExtraProperties = (position, device, data, groups) => ({
  type: data.name,
  startPoint: data.name === 'startPoint',
  finishPoint: data.name === 'finishPoint',
  replayPosition: data.type === 'replay' || data.type === 'extra',
  group: groups[device.groupId]?.name,
  name: device.name,
  deviceCategory: getCategory(device),
  status: data.type !== 'replay' && device.status,
  icon: device.icon || 'default',
  color: deviceColor(device),
  deviceId: position.deviceId,
  positionId: position.id,
  fixTime: position.fixTime,
  serverTime: position.serverTime,
  latitude: position.latitude,
  longitude: position.longitude,
  coordinates: `${Number(position.latitude).toFixed(5)}, ${Number(position.longitude).toFixed(5)}`,
  address: position.address,
  speed: position.speed,
  batteryLevel: position.attributes.batteryLevel,
  sat: position.attributes.sat !== 99 && position.attributes.sat,
  bs: position.network?.cellTowers.map((tower) => `${tower.locationAreaCode}-${tower.cellId}`)[0],
  lacCids: position.network?.cellTowers.map((tower) => `${tower.locationAreaCode}-${tower.cellId}`),
  cid: position.network?.cellTowers.length > 1
    && position.network.cellTowers.slice(1).map((tower) => (tower.cellId)).join(','),
  operator: position.network?.cellTowers
    .map((tower, index) => (index === 0 && `${tower.mobileCountryCode}-${tower.mobileNetworkCode}`))[0],
  power: position.attributes.powerLine,
  motion: position.attributes.motion,
  protocol: position.protocol,
  message: position.attributes.message,
  accuracy: position.accuracy !== 99 && Math.round(position.accuracy),
  extraAttributes: position.attributes.extraAttributes,
  categoryParams: position.attributes.categoryParams,
});
