import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, useTheme, FormControlLabel, Checkbox,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { formatPhone, getCategory, getModel } from '../../common/utils/formatter';
import SelectField from '../../common/form/SelectField';
import LinkField from '../../common/form/LinkField';
import { prefixString } from '../../common/utils/stringUtils';
import commands from '../../common/static/commands';
import { getIsAdmin } from '../../common/utils/selectors';
import EditItemView from './components/EditItemView';
import DeviceCategoryFields from './components/DeviceCategoryFields';
import icons from '../../common/static/icons';
import SelectDeviceIcon from './components/SelectDeviceIcon';
import categoryParamsStructure from '../../common/static/categoryParamsStructure';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const DevicePage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const theme = useTheme();
  const isAdmin = useSelector(getIsAdmin);

  const [item, setItem] = useState();
  const [permissions, setPermissions] = useState();
  const [errorPhone, setErrorPhone] = useState(false);
  const [categoryParams, setCategoryParams] = useState();
  const [latitudeError, setLatitudeError] = useState(false);
  const [longitudeError, setLongitudeError] = useState(false);
  const [altitudeError, setAltitudeError] = useState(false);

  const setDefaultIcon = (icons, defaultValue) => {
    if (!icons.includes(item.icon)) {
      setItem({ ...item, icon: defaultValue });
    }
  };

  const onChangeIcon = (icon) => {
    setItem({
      ...item,
      icon,
    });
  };

  const latLongIsValid = (value, border, setError) => {
    if (!value || (value && !Number.isNaN(value) && -border <= Number(value) && Number(value) <= border)) {
      setError(false);
    } else {
      setError(true);
    }
  };

  const validate = (data) => {
    data.attributes.source = 'SmartTrack';
    if (data.attributes.latitude) {
      data.attributes.latitude = Number(data.attributes.latitude);
    }
    if (data.attributes.longitude) {
      data.attributes.longitude = Number(data.attributes.longitude);
    }
    if (data.attributes.altitude) {
      data.attributes.altitude = Number(data.attributes.altitude);
    }
    return data;
  };

  useEffect(() => {
    latLongIsValid(item?.attributes.latitude, 90, setLatitudeError);
  }, [item?.attributes?.latitude]);

  useEffect(() => {
    latLongIsValid(item?.attributes.longitude, 180, setLongitudeError);
  }, [item?.attributes?.longitude]);

  useEffect(() => {
    latLongIsValid(item?.attributes.altitude, 180, setAltitudeError);
  }, [item?.attributes?.altitude]);

  useEffect(() => {
    if (item) {
      const fields = categoryParamsStructure[getCategory(item)]?.model[getModel(item)]?.writen || {};
      if (!categoryParams) {
        setCategoryParams(item.attributes?.categoryParams ?? fields);
      } else {
        setCategoryParams(fields);
      }
    }
  }, [item?.category, item?.model]);

  useEffect(() => {
    /**
     * Проверяет валидность номера телефона. Изменяет состояние errorPhone.
     */
    const lengthPhone = item?.phone?.length;
    if (lengthPhone) {
      if (lengthPhone !== 12 || (lengthPhone > 1 && item.phone.slice(0, 2) !== '+7')) {
        setErrorPhone(true);
        return;
      }
    }
    setErrorPhone(false);
  }, [item?.phone]);

  useEffect(() => {
    if (item && categoryParams) {
      setItem({ ...item, attributes: { ...item.attributes, categoryParams } });
    }
  }, [categoryParams]);

  useEffect(() => {
    /**
     * Устанавливает конфигурационный файл для permissions.
     * В дальнейшем в данном словаре будут изменяться поля linked, old, в которых указаны id элементом,
     * которые будут удаляться (поле old) или добавляться (поле linked) при нажатии на кнопку Сохранить.
     */
    if (item && !permissions) {
      const permissionsDict = {
        geofences: {
          keyLink: 'geofenceId',
          baseId: item.id,
          keyBase: 'deviceId',
          linked: [],
          old: [],
        },
        notifications: {
          keyLink: 'notificationId',
          baseId: item.id,
          keyBase: 'deviceId',
          linked: [],
          old: [],
        },
      };
      setPermissions(permissionsDict);
    }
  }, [item]);

  useEffect(() => {
    if (item?.category) {
      switch (item.category) {
        case 'radar':
          setDefaultIcon(icons, 'radar');
          break;
        case 'uav':
          setDefaultIcon(icons, 'uav');
          break;
        default:
          setDefaultIcon(icons, 'default');
          break;
      }
    }
  }, [item?.category]);

  return (
    <EditItemView
      endpoint="devices"
      item={item}
      setItem={setItem}
      permissions={permissions}
      disabledSave={isAdmin && !(item && item.name && item.uniqueId && !errorPhone && !longitudeError && !latitudeError && !altitudeError)}
      validate={validate}
    >
      {item
        && (
          <>
            {isAdmin && (
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">
                    {t('sharedDevice')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <TextField
                    required
                    margin="normal"
                    value={item.name || ''}
                    onChange={(event) => setItem({ ...item, name: event.target.value })}
                    label={t('sharedName')}
                    variant="filled"
                  />
                  <TextField
                    required
                    margin="normal"
                    value={item.uniqueId || ''}
                    onChange={(event) => setItem({ ...item, uniqueId: event.target.value })}
                    label={t('deviceIdentifier')}
                    variant="filled"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={item.temporary}
                        onChange={(e) => setItem({ ...item, temporary: e.target.checked })}
                      />
                    )}
                    label={t('deviceTemporary')}
                  />
                </AccordionDetails>
              </Accordion>
            )}
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {isAdmin ? t('sharedExtra') : `${t('sharedDevice')} — ${item.name}`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                {isAdmin && (
                  <SelectField
                    margin="normal"
                    value={item.groupId || 0}
                    onChange={(event) => setItem({ ...item, groupId: Number(event.target.value) })}
                    endpoint="/api/groups"
                    label={t('groupParent')}
                    variant="filled"
                  />
                )}
                {isAdmin && (
                  <TextField
                    margin="normal"
                    value={item.phone || ''}
                    onChange={(event) => formatPhone(event.target.value, item, setItem)}
                    label={t('sharedPhone')}
                    placeholder="+79XXXXXXXXX"
                    error={errorPhone}
                    helperText={errorPhone ? t('errorPhone') : ''}
                    variant="filled"
                  />
                )}
                <SelectField
                  margin="normal"
                  value={item.category || ''}
                  emptyValue={null}
                  onChange={(e) => setItem({ ...item, category: e.target.value, model: '' })}
                  endpoint="/api/devices/categories"
                  keyGetter={(it) => it}
                  titleGetter={(it) => t(prefixString('device', it))}
                  label={t('sharedCategory')}
                  variant="filled"
                />
                <SelectField
                  margin="normal"
                  value={getModel(item)}
                  emptyValue={null}
                  onChange={(e) => setItem({ ...item, model: e.target.value })}
                  data={Object.keys(categoryParamsStructure[item.category]?.model || [])}
                  keyGetter={(it) => it}
                  titleGetter={(it) => t(prefixString('deviceModel', it))}
                  label={t('deviceModel')}
                  variant="filled"
                />
                <SelectDeviceIcon icons={icons} currentIcon={item.icon} onChange={onChangeIcon} />
                <SelectField
                  margin="normal"
                  value={item.attributes?.protocol || ''}
                  data={Object.keys(commands).map((protocol) => ({
                    id: protocol,
                    name: protocol,
                  }))}
                  onChange={(event) => setItem({ ...item, attributes: { ...item.attributes, protocol: event.target.value } })}
                  label={t('deviceCommunicationProtocol')}
                  variant="filled"
                />
                <SelectField
                  margin="normal"
                  value={item.attributes?.movement || ''}
                  data={['car', 'foot'].map((way) => ({
                    id: way,
                    name: t(`${way}Movement`),
                  }))}
                  onChange={(event) => setItem({ ...item, attributes: { ...item.attributes, movement: event.target.value } })}
                  label={t('deviceMovement')}
                  variant="filled"
                />
                <SelectField
                  margin="normal"
                  value={item.attributes?.color || theme.palette.tracks.replay0}
                  emptyValue={null}
                  onChange={(event) => setItem({ ...item, attributes: { ...item.attributes, color: event.target.value } })}
                  data={Object.keys(theme.palette.tracks).map((color) => ({
                    id: theme.palette.tracks[color],
                    name: <FiberManualRecordIcon style={{ color: theme.palette.tracks[color] }} />,
                  }))}
                  label={t('trackColor')}
                  variant="filled"
                />
                <TextField
                  fullWidth
                  value={item.attributes?.latitude || ''}
                  helperText={latitudeError && '-90.0 - 90.0'}
                  placeholder="56.018134"
                  label={t('deviceLatitude')}
                  error={latitudeError}
                  multiline
                  onChange={(e) => setItem({ ...item, attributes: { ...item.attributes, latitude: e.target.value } })}
                />
                <TextField
                  fullWidth
                  helperText={longitudeError && '-180.0 - 180.0'}
                  placeholder="149.145755"
                  value={item.attributes?.longitude || ''}
                  label={t('deviceLongitude')}
                  error={longitudeError}
                  multiline
                  onChange={(e) => setItem({ ...item, attributes: { ...item.attributes, longitude: e.target.value } })}
                />
                <TextField
                  fullWidth
                  helperText={altitudeError && '0 - ...'}
                  placeholder="10.5"
                  value={item.attributes?.altitude || ''}
                  label={t('deviceAltitude')}
                  error={altitudeError}
                  multiline
                  onChange={(e) => setItem({ ...item, attributes: { ...item.attributes, altitude: e.target.value } })}
                />
                <TextField
                  fullWidth
                  placeholder="45"
                  value={item.attributes?.azimuth ?? 0}
                  emptyValue
                  label={t('deviceAzimuth')}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (!Number.isNaN(value)) {
                      setItem({ ...item, attributes: { ...item.attributes, azimuth: value } });
                    }
                  }}
                />
                {!!(item.id && permissions)
                  && (
                    <>
                      <LinkField
                        margin="normal"
                        endpointAll="/api/geofences"
                        endpointLinked={`/api/geofences?deviceId=${item.id}`}
                        label={t('sharedGeofences')}
                        variant="filled"
                        permissions={permissions}
                        setPermissions={setPermissions}
                        type="geofences"
                      />
                      <LinkField
                        margin="normal"
                        endpointAll="/api/notifications"
                        endpointLinked={`/api/notifications?deviceId=${item.id}`}
                        titleGetter={(it) => t(prefixString('event', it.type))}
                        label={t('sharedNotifications')}
                        variant="filled"
                        permissions={permissions}
                        setPermissions={setPermissions}
                        type="notifications"
                      />
                    </>
                  )}
                {isAdmin && (
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={!!item.saveLogs}
                        onChange={
                          (e) => setItem({ ...item, saveLogs: e.target.checked })
                        }
                      />
                    )}
                    label={t('deviceSaveLogs')}
                  />
                )}
              </AccordionDetails>
            </Accordion>
            {!!(categoryParams && Object.keys(categoryParams).length > 0) && (
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">
                    {t('settingsDeviceCategory')}
                  </Typography>
                </AccordionSummary>
                <DeviceCategoryFields item={item} categoryParams={categoryParams} setCategoryParams={setCategoryParams} />
              </Accordion>
            )}
          </>
        )}
    </EditItemView>
  );
};

export default DevicePage;
