import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from '../../LocalizationProvider';
import ModalEdit from '../../ModalEdit';
import { useEffectAsync } from '../../../utils/reactHelper';
import logout from '../../../utils/logout';
import { savePermissions } from '../../../utils/savePermisionsFuncs';
import { deviceColor } from '../../../../map/funcs/positionSource';
import SkeletonEdit from './SkeletonEdit';
import DeviceEdit from './DeviceEdit';
import icons from '../../../static/icons';
import { getCategory, getModel } from '../../../utils/formatter';
import categoryParamsStructure from '../../../static/categoryParamsStructure';
import { getIsAdmin } from '../../../utils/selectors';

const ENDPOINT = 'devices';

const PositionDataEdit = ({
  open, onClose, deviceProperties,
}) => {
  const t = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const groups = useSelector((state) => state.groups.items);

  const [deviceInfo, setDeviceInfo] = useState();
  const [permissions, setPermissions] = useState();
  const [errorPhone, setErrorPhone] = useState(false);
  const [categoryParams, setCategoryParams] = useState();
  const [latitudeError, setLatitudeError] = useState(false);
  const [longitudeError, setLongitudeError] = useState(false);
  const [altitudeError, setAltitudeError] = useState(false);
  const isAdmin = useSelector(getIsAdmin);

  const setDefaultIcon = (icons, defaultValue) => {
    if (!icons.includes(deviceInfo.icon)) {
      setDeviceInfo({ ...deviceInfo, icon: defaultValue });
    }
  };

  const latLongIsValid = (value, border, setError) => {
    if (!value || (value && !Number.isNaN(value) && -border <= Number(value) && Number(value) <= border)) {
      setError(false);
    } else {
      setError(true);
    }
  };

  const validate = (data) => {
    data.attributes.source = 'SmartTrack';
    if (data.attributes.latitude) {
      data.attributes.latitude = Number(data.attributes.latitude);
    }
    if (data.attributes.longitude) {
      data.attributes.longitude = Number(data.attributes.longitude);
    }
    if (data.attributes.altitude) {
      data.attributes.altitude = Number(data.attributes.altitude);
    }
    return data;
  };

  useEffect(() => {
    latLongIsValid(deviceInfo?.attributes.latitude, 90, setLatitudeError);
  }, [deviceInfo?.attributes?.latitude]);

  useEffect(() => {
    latLongIsValid(deviceInfo?.attributes.longitude, 180, setLongitudeError);
  }, [deviceInfo?.attributes?.longitude]);

  useEffect(() => {
    latLongIsValid(deviceInfo?.attributes.altitude, 180, setAltitudeError);
  }, [deviceInfo?.attributes?.altitude]);

  useEffectAsync(async () => {
    if (deviceProperties.deviceId) {
      const response = await fetch(`/api/${ENDPOINT}/${deviceProperties.deviceId}`);
      if (response.ok) {
        setDeviceInfo(await response.json());
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
    } else {
      setDeviceInfo({});
    }
  }, [deviceProperties.deviceId]);

  const handleSave = async () => {
    let url = `/api/${ENDPOINT}`;
    if (deviceProperties.deviceId) {
      url += `/${deviceProperties.deviceId}`;
    }
    const response = await fetch(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(validate(deviceInfo)),
    });

    if (response.ok) {
      try {
        const bodyResponse = await response.json();

        // Добавление свойств к устройству после успешного запроса на редактирование.
        deviceProperties.name = bodyResponse.name;
        deviceProperties.group = groups[bodyResponse.groupId]?.name;
        deviceProperties.deviceType = getCategory(bodyResponse);
        deviceProperties.status = bodyResponse.status;
        deviceProperties.icon = bodyResponse.icon;
        deviceProperties.color = deviceColor(bodyResponse);
      } catch (error) {
        console.warn('Не удалось задать свойства устройства.', error);
      }

      if (permissions) {
        Object.values(permissions).forEach((permission) => savePermissions(permission, false));
      }
    } else if (response.status === 401) {
      logout(history, dispatch);
    }

    onClose();
  };

  useEffect(() => {
    if (deviceInfo) {
      const fields = categoryParamsStructure[getCategory(deviceInfo)]?.model[getModel(deviceInfo)]?.writen || {};
      if (!categoryParams) {
        setCategoryParams(deviceInfo.attributes?.categoryParams ?? fields);
      } else {
        setCategoryParams(fields);
      }
    }
  }, [deviceInfo?.category, deviceInfo?.model]);

  useEffect(() => {
    /**
     * Проверяет валидность номера телефона. Изменяет состояние errorPhone.
     */
    const lengthPhone = deviceInfo?.phone?.length;
    if (lengthPhone) {
      if (lengthPhone !== 12 || (lengthPhone > 1 && deviceInfo.phone.slice(0, 2) !== '+7')) {
        setErrorPhone(true);
        return;
      }
    }
    setErrorPhone(false);
  }, [deviceInfo?.phone]);

  useEffect(() => {
    if (deviceInfo && categoryParams) {
      setDeviceInfo({ ...deviceInfo, attributes: { ...deviceInfo.attributes, categoryParams } });
    }
  }, [categoryParams]);

  useEffect(() => {
    /**
     * Устанавливает конфигурационный файл для permissions.
     * В дальнейшем в данном словаре будут изменяться поля linked, old, в которых указаны id элементом,
     * которые будут удаляться (поле old) или добавляться (поле linked) при нажатии на кнопку Сохранить.
     */
    if (deviceInfo && !permissions) {
      const permissionsDict = {
        geofences: {
          keyLink: 'geofenceId',
          baseId: deviceInfo.id,
          keyBase: 'deviceId',
          linked: [],
          old: [],
        },
        notifications: {
          keyLink: 'notificationId',
          baseId: deviceInfo.id,
          keyBase: 'deviceId',
          linked: [],
          old: [],
        },
      };
      setPermissions(permissionsDict);
    }
  }, [deviceInfo]);

  useEffect(() => {
    if (deviceInfo?.category) {
      switch (deviceInfo.category) {
        case 'temporary':
          setDefaultIcon(icons, 'unknown');
          break;
        case 'radar':
          setDefaultIcon(icons, 'radar');
          break;
        case 'uav':
          setDefaultIcon(icons, 'uav');
          break;
        default:
          setDefaultIcon(icons, 'default');
          break;
      }
    }
  }, [deviceInfo?.category]);

  return (
    <ModalEdit
      title={t('PositionDataEditTitle')}
      styles={
        {
          root: {
            margin: '10px 0',
          },
          data: {
            padding: '10px 0',
          },
        }
      }
      open={open}
      onAccept={handleSave}
      onClose={onClose}
      disabledSave={isAdmin && !(deviceInfo && deviceInfo.name && deviceInfo.uniqueId && !errorPhone && !longitudeError && !latitudeError && !altitudeError)}
    >
      {deviceInfo ? (
        <DeviceEdit
          deviceInfo={deviceInfo}
          errorPhone={errorPhone}
          latitudeError={latitudeError}
          longitudeError={longitudeError}
          altitudeError={altitudeError}
          permissions={permissions}
          categoryParams={categoryParams}
          setDeviceInfo={setDeviceInfo}
          setPermissions={setPermissions}
          setCategoryParams={setCategoryParams}
        />
      ) : (
        <SkeletonEdit />
      )}
    </ModalEdit>
  );
};

export default PositionDataEdit;
