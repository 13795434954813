import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  Switch,
  ListItemText, Checkbox,
} from '@material-ui/core';
import moment from 'moment';
import { useTheme } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import SuperMenuItem from '../../../common/components/SuperMenuItem';
import useReportStyles from '../hooks/useReportStyles';

const ELEMENT_HEIGHT = 48;
const MIN_CONTAINER_HEIGHT = 360;

const ReportFilter = ({
  children, handleSubmit, showOnly, columns, typeReport, off, setOff, progress, onBs, setT, setF, setSelectedItem,
  setPositionsPath, withoutDevices, devicesList, progressDevices,
}) => {
  const t = useTranslation();
  const classes = useReportStyles();

  const [deviceIds, setDeviceIds] = useState([]);
  const [period, setPeriod] = useState('today');
  const [from, setFrom] = useState(moment().subtract(1, 'hour'));
  const [to, setTo] = useState(moment());
  const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
  const [selectedAnchorElExport, setSelectedAnchorElExport] = useState(null);
  const theme = useTheme();
  const [item, setItem] = useState(false);

  const disabled = withoutDevices ? !!progress : !deviceIds.length || !!progress || progressDevices;

  const includeProperties = (property) => {
    /**
     * Обновляет список выключенных столбцов в таблице отчета
     */
    let updatedOff;
    if (off.includes(property)) {
      updatedOff = off.filter((item) => item !== property);
    } else {
      updatedOff = [...off, property];
    }
    window.localStorage.setItem(typeReport, JSON.stringify(updatedOff));
    setOff(updatedOff);
  };

  const handleClick = async (mail, format) => {
    if (setSelectedItem) {
      setSelectedItem(null);
    }
    if (setPositionsPath) {
      setPositionsPath({});
    }
    setItem(true);
    let selectedFrom;
    let selectedTo;
    switch (period) {
      case 'today':
        selectedFrom = moment().startOf('day');
        selectedTo = moment().endOf('day');
        break;
      case 'yesterday':
        selectedFrom = moment().subtract(1, 'day').startOf('day');
        selectedTo = moment().subtract(1, 'day').endOf('day');
        break;
      case 'thisWeek':
        selectedFrom = moment().subtract(1, 'day').startOf('week').add(1, 'day');
        selectedTo = moment().subtract(1, 'day').endOf('week').add(1, 'day');
        break;
      case 'previousWeek':
        selectedFrom = moment().subtract(1, 'week').startOf('week').add(1, 'day');
        selectedTo = moment().subtract(1, 'week').endOf('week').add(1, 'day');
        break;
      case 'thisMonth':
        selectedFrom = moment().startOf('month');
        selectedTo = moment().endOf('month');
        break;
      case 'previousMonth':
        selectedFrom = moment().subtract(1, 'month').startOf('month');
        selectedTo = moment().subtract(1, 'month').endOf('month');
        break;
      default:
        selectedFrom = from;
        selectedTo = to;
        break;
    }
    let accept;
    switch (format) {
      case 'csv':
        accept = 'text/csv';
        break;
      case 'excel':
        accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;
      default:
        accept = 'application/json';
        break;
    }
    if (setF) {
      setF(selectedFrom);
      setT(selectedTo);
    }
    await handleSubmit(
      deviceIds,
      selectedFrom.toISOString(),
      selectedTo.toISOString(),
      { Accept: accept },
    );
  };

  useEffect(() => item && handleClick(false), [onBs]);

  const clearOrFill = () => {
    if (deviceIds.length) {
      setDeviceIds([]);
    } else {
      setDeviceIds(devicesList.map((device) => device.id));
    }
  };

  const deleteOrAddDevice = (device) => {
    let newDeviceIds = [...deviceIds];
    const { id } = device;

    const isHaveId = newDeviceIds.find((i) => i === id);
    if (isHaveId) {
      newDeviceIds = newDeviceIds.filter((cur) => cur !== id);
    } else {
      newDeviceIds.push(id);
    }

    setDeviceIds(newDeviceIds);
  };

  const handleElementClick = (device) => {
    if (device === 'all') {
      clearOrFill();
    } else {
      deleteOrAddDevice(device);
    }
  };

  const menuItemLazyLoad = ({ index, style }) => {
    const device = devicesList[index];

    return (
      <MenuItem
        style={style}
        value={device.id}
        key={device.id}
        onClick={() => handleElementClick(device)}
      >
        <Checkbox checked={deviceIds.indexOf(device.id) > -1} />
        <ListItemText primary={device.name} />
      </MenuItem>
    );
  };

  const calcHeight = () => (devicesList.length >= 7 ? MIN_CONTAINER_HEIGHT : devicesList.length * ELEMENT_HEIGHT);

  return (
    <div className={classes.filter}>
      {!withoutDevices && (
        <div className={classes.filterItem}>
          {progressDevices ? (
            <Skeleton variant="rect" className={classes.deviceSkeleton} />
          ) : (
            <FormControl variant="filled" fullWidth>
              <InputLabel>{t('reportDevice')}</InputLabel>
              <Select
                multiple
                value={deviceIds}
                renderValue={(selected) => `${selected.length} ${t('sharedNumbers')}`}
                MenuProps={theme.overrides.MenuProps}
                disabled={progressDevices}
              >
                <MenuItem
                  style={{
                    marginTop: '-10px',
                    backgroundColor: theme.palette.grey[300],
                  }}
                  value="all"
                  onClick={() => handleElementClick('all')}
                >
                  <SuperMenuItem all={deviceIds.length > 0} />
                </MenuItem>
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <FixedSizeList
                      height={calcHeight()}
                      width={width}
                      itemCount={devicesList.length}
                      itemSize={ELEMENT_HEIGHT}
                      overscanCount={10}
                    >
                      {menuItemLazyLoad}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </Select>
            </FormControl>
          )}

        </div>
      )}
      <div className={classes.filterItem}>
        <FormControl variant="filled" fullWidth>
          <InputLabel>{t('reportPeriod')}</InputLabel>
          <Select
            value={period}
            onChange={(e) => setPeriod(e.target.value)}
            MenuProps={theme.overrides.MenuProps}
          >
            <MenuItem value="today">{t('reportToday')}</MenuItem>
            <MenuItem value="yesterday">{t('reportYesterday')}</MenuItem>
            <MenuItem value="thisWeek">{t('reportThisWeek')}</MenuItem>
            <MenuItem value="previousWeek">{t('reportPreviousWeek')}</MenuItem>
            <MenuItem value="thisMonth">{t('reportThisMonth')}</MenuItem>
            <MenuItem value="previousMonth">{t('reportPreviousMonth')}</MenuItem>
            <MenuItem value="custom">{t('reportCustom')}</MenuItem>
          </Select>
        </FormControl>
      </div>
      {period === 'custom' && (
        <div className={classes.filterItem}>
          <TextField
            variant="filled"
            label={t('reportFrom')}
            type="datetime-local"
            value={from.format(moment.HTML5_FMT.DATETIME_LOCAL)}
            onChange={(e) => {
              setFrom(moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL));
              if (setF) {
                setF(moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL));
              }
            }}
            fullWidth
          />
        </div>
      )}
      {period === 'custom' && (
        <div className={classes.filterItem}>
          <TextField
            variant="filled"
            label={t('reportTo')}
            type="datetime-local"
            value={to.format(moment.HTML5_FMT.DATETIME_LOCAL)}
            onChange={(e) => {
              setTo(moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL));
              if (setT) {
                setT(moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL));
              }
            }}
            fullWidth
          />
        </div>
      )}
      {children}
      <div className={classes.filterButtons}>
        {!showOnly && (
          <>
            <Button
              onClick={(event) => setSelectedAnchorEl(event.currentTarget)}
              variant="outlined"
              color="primary"
              className={classes.filterButton}
            >
              {t('reportColumns')}
            </Button>
            <Menu
              open={!!selectedAnchorEl}
              anchorEl={selectedAnchorEl}
              onClose={() => setSelectedAnchorEl(null)}
              className={classes.menuEditRemove}
            >
              {columns && columns.map((column) => (
                <MenuItem key={column[0]} onClick={() => includeProperties(column[0])}>
                  <Switch checked={!off.includes(column[0])} />
                  {t(column[1])}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
        <Button
          onClick={() => handleClick(false)}
          variant="outlined"
          color="secondary"
          className={classes.filterButton}
          disabled={disabled}
        >
          {t('reportShow')}
        </Button>
        {!showOnly && (
          <>
            <Button
              onClick={(event) => setSelectedAnchorElExport(event.currentTarget)}
              variant="outlined"
              color="secondary"
              className={classes.filterWideButton}
              disabled={disabled}
            >
              {t('reportExport')}
            </Button>
            <Menu
              open={!!selectedAnchorElExport}
              anchorEl={selectedAnchorElExport}
              onClose={() => setSelectedAnchorElExport(null)}
              className={classes.menuEditRemove}
            >
              <MenuItem
                key="csv"
                onClick={() => {
                  setSelectedAnchorElExport(null);
                  handleClick(false, 'csv');
                }}
              >
                CSV
              </MenuItem>
              <MenuItem
                key="excel"
                onClick={() => {
                  setSelectedAnchorElExport(null);
                  handleClick(false, 'excel');
                }}
              >
                EXCEL
              </MenuItem>
            </Menu>
          </>
        )}
      </div>
    </div>
  );
};

export default ReportFilter;

ReportFilter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
  showOnly: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.any),
  typeReport: PropTypes.string,
  off: PropTypes.arrayOf(PropTypes.string),
  setOff: PropTypes.func,
  onBs: PropTypes.number,
  setT: PropTypes.func,
  setF: PropTypes.func,
  setSelectedItem: PropTypes.func,
  setPositionsPath: PropTypes.func,
  withoutDevices: PropTypes.bool,
};
