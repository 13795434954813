import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonBack: {
    position: 'fixed',
    zIndex: 1300,
    top: theme.spacing(1),
    left: theme.spacing(1),
    backgroundColor: 'whitesmoke',
  },
  foundNothing: {
    maxWidth: '100%',
    zIndex: 1310,
  },
}));

export const useStylesControlPanel = makeStyles((theme) => ({
  controlPanel: {
    position: 'fixed',
    bottom: theme.spacing(1),
    transform: 'translateX(-50%)',
    /* [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    }, */
  },
  controlPanelWide: {
    left: '50%',
  },
  controlPanelShort: {
    width: 'calc(100% - 390px)',
    left: 'calc(50% - 195px)',
  },
  configForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  slider: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(2),
  },
  sliderBlock: {
    minHeight: theme.spacing(5),
    maxHeight: theme.spacing(15),
    overflow: 'auto',
  },
  sliderOneItem: {
    width: '92%',
    margin: '0 auto',
  },
  sliderButtons: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    gap: '5px',
  },
  accordion: {
    flex: '1',
    minWidth: 0,
  },
  accordionBar: {
    width: '100%',
    overflow: 'hidden',
  },
  buttonRow: {
    flex: '0',
    display: 'flex',
  },
}));

export const useTooltipStyles = makeStyles((theme) => createStyles({
  tooltip: {
    maxWidth: theme.spacing(9),
  },
  tooltipPlacementTop: {
    margin: '4px 0',
  },
  tooltipPlacementBottom: {
    margin: '4px 0',
  },
}));

export const useMarkStyles = makeStyles(() => createStyles({
  mark: {
    width: '20px',
    height: '20px',
    margin: 'auto',
  },
  icon: {
    maxWidth: '80%',
  },
}));
