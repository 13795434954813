import { toTitleCase } from '../../common/utils/formatter';
import { extraLayersActions } from '../../store';

const checkStatus = (extraLayersPanel, name) => (extraLayersPanel[`on${toTitleCase(name)}`]);

const updatePoints = (dispatch, name, points, status) => {
  dispatch(extraLayersActions.refresh({
    name,
    points,
  }));
  dispatch(extraLayersActions.switch({
    name: `on${toTitleCase(name)}`,
    status,
  }));
};

const getFlights = async (dispatch, extraLayersPanel, initMode) => {
  let result = [];
  const status = checkStatus(extraLayersPanel, 'flightRadarFlights');
  if (initMode ? status : !status) {
    try {
      const response = await fetch('http://127.0.0.1:8000/flight-radar/flights');
      if (response.ok) {
        result = await response.json();
        updatePoints(dispatch, 'flightRadarFlights', result, 1);
      }
    } catch (e) {
      updatePoints(dispatch, 'flightRadarFlights', result, 0);
      throw Error(e.message);
    }
  } else {
    updatePoints(dispatch, 'flightRadarFlights', result, 0);
  }
};

const getAirports = async (dispatch, extraLayersPanel, initMode) => {
  let result = [];
  const status = checkStatus(extraLayersPanel, 'flightRadarAirports');
  if (initMode ? status : !status) {
    try {
      const response = await fetch('http://127.0.0.1:8000/flight-radar/airports');
      if (response.ok) {
        result = await response.json();
        updatePoints(dispatch, 'flightRadarAirports', result, 1);
      }
    } catch (e) {
      updatePoints(dispatch, 'flightRadarAirports', result, 0);
      throw Error(e.message);
    }
  } else {
    updatePoints(dispatch, 'flightRadarAirports', result, 0);
  }
};

export default async (value, dispatch, extraLayersPanel, initMode) => {
  switch (value) {
    case 'flightRadarFlights':
      await getFlights(dispatch, extraLayersPanel, initMode);
      break;
    case 'flightRadarAirports':
      await getAirports(dispatch, extraLayersPanel, initMode);
      break;
    default:
      break;
  }
};
