import { useEffect, useRef } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import {
  positionsActions, devicesActions, sessionActions, smsActions, markersActions,
  eventsActions, tailActions,
} from './store';
import { useEffectAsync } from './common/utils/reactHelper';
import logout from './common/utils/logout';
import soundDeviceOnline from './common/static/sounds/deviceOnline.mp3';
import soundDefault from './common/static/sounds/default.mp3';
import soundAlarm from './common/static/sounds/alarm.mp3';
import soundTextMessage from './common/static/sounds/textMessage.mp3';
import { prefixString } from './common/utils/stringUtils';
import { useTranslation } from './common/components/LocalizationProvider';
import { filterCurrent } from './common/utils/formatter';
import useBufferedArray from './common/hooks/useBufferedArray';

const playSound = (type) => {
  let audio;
  switch (type) {
    case 'alarm':
      audio = new Audio(soundAlarm);
      break;
    case 'textMessage':
      audio = new Audio(soundTextMessage);
      break;
    case 'deviceOnline':
      audio = new Audio(soundDeviceOnline);
      break;
    default:
      audio = new Audio(soundDefault);
      break;
  }
  if (audio) {
    audio.play();
  }
};

const displayNotifications = (events, t, eventsSoundOn) => {
  if ('Notification' in window) {
    if (Notification.permission === 'granted') {
      events.forEach((event) => {
        if (eventsSoundOn) {
          playSound(event.type);
        }
        const notification = new Notification(
          `${t('positionEvent')}: ${t(prefixString('event', event.type))}`,
          { icon: '/favicon.ico', vibrate: true },
        );
        setTimeout(notification.close.bind(notification), 10 * 1000);
      });
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission((permission) => {
        if (permission === 'granted') {
          displayNotifications(events, t, eventsSoundOn);
        }
      });
    }
  }
};

const SocketController = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const t = useTranslation();
  const eventsSoundOn = useSelector((state) => state.events.sound);
  const eventsSoundOnRef = useRef(eventsSoundOn);

  const authenticated = useSelector((state) => !!state.session.user);
  const server = useSelector((state) => state.session.server);
  const serverRef = useRef(server);

  const getValuesFromObjectsArray = (array) => array.flatMap((item) => item.id);
  const getGroupedByMessageType = (data) => data.reduce((acc, obj) => {
    const { messageType } = obj;

    if (!acc[messageType]) {
      acc[messageType] = [];
    }

    delete obj.messageType;

    acc[messageType].push(obj);

    return acc;
  }, {});

  const actions = {
    devices: (devices) => dispatch(devicesActions.update(devices.filter((item) => filterCurrent(item, serverRef.current)))),
    positions: (positions) => {
      dispatch(positionsActions.update(positions));
      dispatch(tailActions.add(positions));
    },
    events: (events) => {
      dispatch(eventsActions.add(events));
      displayNotifications(events, t, eventsSoundOnRef.current);
    },
    sms: (sms) => dispatch(smsActions.update(sms)),
    markers: (markers) => dispatch(markersActions.update(markers)),
    deletedMarkerId: (markerIds) => dispatch(markersActions.remove(getValuesFromObjectsArray(markerIds))),
    deletedDeviceId: (deviceIds) => dispatch(devicesActions.remove(getValuesFromObjectsArray(deviceIds))),
  };

  const commonCaching = useBufferedArray((data) => {
    const groupedByMessageType = getGroupedByMessageType(data);

    // Выполняем действие на основе messageType
    for (const messageType in groupedByMessageType) {
      if (messageType in actions) {
        actions[messageType](groupedByMessageType[messageType]);
      }
    }
  }, 1000);

  const socketRef = useRef();

  const connectSocket = () => {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const socket = new WebSocket(`${protocol}//${window.location.host}/api/socket`);
    socketRef.current = socket;

    socket.onopen = () => {
      dispatch(sessionActions.updateSocketOpened(true));
    };

    socket.onerror = () => {
      setTimeout(() => connectSocket(), 60 * 1000);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // Извлекаем первый ключ.
      const key = Object.keys(data)[0];

      if (!key) return;

      const arr = [];
      const isObject = data[key][0]?.id;

      for (const item of data[key]) {
        if (isObject) {
          arr.push({ ...item, messageType: key });
        } else {
          // Если элемент не является объектом, обрабатываем как массив
          arr.push({ id: item, messageType: key });
        }
      }

      commonCaching(arr);
    };

    socket.onclose = (event) => {
      if (event.wasClean) {
        dispatch(sessionActions.updateSocketOpened(false));
      } else {
        // например, сервер убил процесс или сеть недоступна
        // обычно в этом случае event.code 1006
        dispatch(sessionActions.updateSocketOpened(false));
      }
      if (window.location.pathname === '/') {
        // eslint-disable-next-line no-alert
        window.alert('Соединение с сервером частично потеряно. Возможно, часть данных устарела. Обновите страницу');
      }
    };
  };

  useEffect(() => {
    serverRef.current = server;
  }, [server]);

  useEffect(() => {
    eventsSoundOnRef.current = eventsSoundOn;
  }, [eventsSoundOn]);

  useEffectAsync(async () => {
    const response = await fetch('/api/server');
    if (response.ok) {
      dispatch(sessionActions.updateServer(await response.json()));
    }
  }, []);

  useEffectAsync(async () => {
    if (authenticated) {
      const response = await fetch('/api/devices?isActive=true');
      if (response.ok) {
        dispatch(devicesActions.refresh(await response.json()));
        dispatch(devicesActions.init(true));
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
      connectSocket();
      return () => {
        const socket = socketRef.current;
        if (socket) {
          socket.close();
        }
      };
    }
    const response = await fetch('/api/session');
    if (response.ok) {
      dispatch(sessionActions.updateUser(await response.json()));
    } else {
      if (response.status === 400) {
        dispatch(sessionActions.updateTimeExpired(true));
      }
      logout(history, dispatch);
    }
    return null;
  }, [authenticated]);

  return null;
};

export default connect()(SocketController);
