import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Column } from 'devextreme-react/data-grid';
import {
  formatPosition,
} from '../../common/utils/formatter';
import { useTranslation } from '../../common/components/LocalizationProvider';
import usePersistedState from '../../common/utils/usePersistedState';
import { positionHandler } from '../../common/utils/requestRoute';
import logout from '../../common/utils/logout';
import ReportSmartTemplate from './components/ReportSmartTemplate';
import ReplayPathMap from '../../map/ReplayPathMap';
import PositionsMap from '../../map/PositionsMap';
import positionsTypes from '../../common/static/positionsTypes';
import MapCamera from '../../map/MapCamera';
import Map from '../../map/Map';
import useReportStyles from './hooks/useReportStyles';
import { useEffectAsync } from '../../common/utils/reactHelper';
import { getGroupName } from '../../common/components/SmartTable/funcs/calculateCellValue';

// Имя ключа в localStorage со списком выключенных колонок
const typeReport = 'detectedObjectReport';

// Список колонок [имя поля в json с бэкенда, имя поля в файле переводчике, возможность сортировки (0 - нет, 1 - да)]
const columnsArray = [
  ['deviceId', 'reportDevice', 1],
  ['group', 'reportGroup', 1],
  ['serverTime', 'reportServerTime', 1],
  ['fixTime', 'reportFixTime', 1],
  ['latitude', 'reportLatitude', 0],
  ['longitude', 'reportLongitude', 0],
  ['address', 'reportAddress', 0],
  ['sector', 'positionSector', 0],
  ['centerFrequency', 'positionCenterFrequency', 0],
  ['bandwidth', 'positionBandwidth', 0],
  ['averageSnr', 'positionAverageSnr', 0],
  ['comment', 'positionComment', 0],
  ['peleng', 'positionPeleng', 0],
  ['dist', 'positionDist', 0],
  ['zone', 'positionZone', 0],
];

const DetectedObjectReportPage = () => {
  const category = 'detected_object';
  const t = useTranslation();
  const classes = useReportStyles();
  const [offColumns, setOffColumns] = usePersistedState(typeReport, []);
  const [devicesObject, setDevicesObject] = useState({});
  const groups = useSelector((state) => state.groups.items);
  const history = useHistory();
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [progress, setProgress] = useState(0);
  const [progressDevices, setProgressDevices] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [positionsPath, setPositionsPath] = useState({});

  const columnsArrayFiltered = columnsArray.filter((column) => !offColumns.includes(column[0]));

  const handleSubmit = async (deviceId, from, to, headers) => {
    setProgress(1);
    const query = new URLSearchParams({
      from, to, category,
    });
    columnsArrayFiltered.forEach((it) => query.append('column', it[0]));
    const response = await fetch(`/api/reports/detected?${query.toString()}`, { headers });
    if (response.ok) {
      const contentType = response.headers.get('content-type');
      if (contentType) {
        if (contentType === 'application/json') {
          const result = await response.json();
          setItems(result);
        } else {
          window.location.assign(window.URL.createObjectURL(await response.blob()));
        }
      }
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
    setProgress(100);
    setProgress(0);
  };

  const formatSmartValue = (key, caption, devices) => {
    switch (key) {
      case 'serverTime':
      case 'fixTime':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="datetime"
            caption={t(caption)}
          />
        );
      case 'deviceId':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => devices[item[key]]?.name}
            caption={t(caption)}
          />
        );
      case 'group':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => getGroupName(devices[item.deviceId] ?? {}, groups)}
            caption={t(caption)}
          />
        );
      case 'longitude':
      case 'latitude':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => formatPosition(item[key], key, t)}
            caption={t(caption)}
          />
        );
      case 'address':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => formatPosition(item[key], key, t)}
            caption={t(caption)}
          />
        );
      case 'sector':
      case 'centerFrequency':
      case 'bandwidth':
      case 'averageSnr':
      case 'comment':
      case 'peleng':
      case 'dist':
      case 'zone':
        return (
          <Column
            key={key}
            name={`attributes.categoryParams.${key}`}
            dataField={`attributes.categoryParams.${key}`}
            calculateCellValue={(item) => (item.attributes.categoryParams ? (item.attributes.categoryParams[key] ?? '') : '')}
            caption={t(caption)}
          />
        );
      default:
        return (
          <Column
            dataField={key}
            caption={t(caption)}
            dataType="string"
          />
        );
    }
  };

  useEffect(() => {
    if (selectedItem && !Object.keys(positionsPath).length) {
      const path = items.reduce((a, v) => ({ ...a, [v.deviceId]: a[v.deviceId] ? [...a[v.deviceId], v] : [v] }), {});
      const deviceIds = Object.keys(path);
      const result = {};
      for (let i = 0; i < deviceIds.length; i += 1) {
        const deviceId = deviceIds[i];
        const clearResponsePath = [];
        positionHandler(path[deviceId], clearResponsePath, true);
        result[deviceId] = clearResponsePath;
      }
      setPositionsPath(result);
    }
  }, [selectedItem]);

  useEffectAsync(async () => {
    const response = await fetch('/api/devices?object=true');
    if (response.ok) {
      const result = await response.json();
      setDevicesObject(result);
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
    setProgressDevices(false);
  }, []);

  return (
    <ReportSmartTemplate
      items={items}
      progress={progress}
      progressDevices={progressDevices}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      columnsArray={columnsArray}
      handleSubmit={handleSubmit}
      typeSortingDefault="fixTime"
      formatValue={formatSmartValue}
      typeReport={typeReport}
      offColumns={offColumns}
      setOffColumns={setOffColumns}
      positionsPath={positionsPath}
      setPositionsPath={setPositionsPath}
      columnsArrayFiltered={columnsArrayFiltered}
      breadcrumbs={['reportTitle', 'reportRoute']}
      devicesObject={devicesObject}
      withoutDevices
      map={selectedItem && (
        <div className={classes.containerMap}>
          <Map noFixed>
            <ReplayPathMap positions={positionsPath} devices={devicesObject} />
            <PositionsMap
              positions={{ [selectedItem.deviceId]: [selectedItem] }}
              devices={devicesObject}
              data={positionsTypes.current}
              noClick
            />
            <MapCamera latitude={selectedItem.latitude} longitude={selectedItem.longitude} />
          </Map>
        </div>
      )}
      mapOn
    />
  );
};

export default DetectedObjectReportPage;
