import React, { useState } from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Column } from 'devextreme-react/data-grid';
import { useEffectAsync } from '../../common/utils/reactHelper';
import EditCollectionView from '../../EditCollectionView';
import OptionsLayout from './OptionsLayout';
import { useTranslation } from '../../common/components/LocalizationProvider';
import logout from '../../common/utils/logout';
import { groupsActions } from '../../store';
import SmartTable from '../../common/components/SmartTable';
import { allowedPageSizes, currentPageSize } from '../../common/settings/settingsPage';
import { getStringFromBoolean } from '../../common/components/SmartTable/funcs/utils';

const GroupsView = ({ updateTimestamp, onMenuClick, onAddNewRow }) => {
  const t = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const registerUnknownDefaultGroupId = useSelector((state) => state.session.server.attributes.registerUnknownDefaultGroupId);

  const [items, setItems] = useState([]);
  const [requestProgress, setRequestProgress] = useState(true);

  useEffectAsync(async () => {
    const response = await fetch('/api/groups?all=true');
    if (response.ok) {
      const body = await response.json();
      setItems(body);
      dispatch(groupsActions.update([...body]));
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
    setRequestProgress(false);
  }, [updateTimestamp]);

  if (requestProgress) {
    return (
      <LinearProgress style={{ top: '100px' }} />
    );
  }

  const iconCellRender = ({ data }) => (
    <>
      {data.data.id === registerUnknownDefaultGroupId ? (<CheckCircleOutlineIcon />) : ''}
    </>
  );

  return (
    <SmartTable
      tableName="GroupsPage"
      fileExportingName="settingsGroups"
      allowedPageSizes={allowedPageSizes}
      currentPageSize={currentPageSize}
      arrayDependencies={[items]}
      dataSource={items}
      onAddNewRow={onAddNewRow}
      onMenuClick={onMenuClick}
    >
      <Column
        dataField="id"
        caption={t('deviceIdentifier')}
        width={100}
        visible={false}
      />
      <Column
        dataField="name"
        caption={t('sharedName')}
      />
      <Column
        caption={t('autoregistrationTitle')}
        dataType="string"
        allowFiltering
        allowSorting
        allowGrouping
        cellComponent={iconCellRender}
        calculateCellValue={(item) => getStringFromBoolean(item.id === registerUnknownDefaultGroupId, t)}
      />
    </SmartTable>
  );
};

const GroupsPage = () => {
  const t = useTranslation();

  return (
    <OptionsLayout>
      <EditCollectionView
        disableAdd
        stickyTop
        textOfAddButton={t('groupsAddDevice')}
        content={GroupsView}
        editPath="/settings/group"
        endpoint="groups"
        forseAdd
      />
    </OptionsLayout>
  );
};

export default GroupsPage;
