import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useHistory } from 'react-router-dom';
import { useTranslation } from './LocalizationProvider';
import { devicesActions, geofencesActions } from '../../store';
import logout from '../utils/logout';

const RemoveDialog = ({
  open,
  endpoint,
  itemId,
  onResult,
}) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isRequestSending, setIsRequestSending] = useState(false);

  const cancelation = useRef(new AbortController());

  const clearResources = () => {
    cancelation.current.abort();
    cancelation.current = new AbortController();
  };

  const clearResourcesAndCallOnResult = () => {
    clearResources();
    onResult();
  };

  const refreshGeofences = async () => {
    const query = new URLSearchParams({});
    if (endpoint === 'devices') {
      query.append('isActive', true);
    }
    const response = await fetch(`/api/${endpoint}?${query.toString()}`);
    if (response.ok) {
      if (endpoint === 'geofences') {
        dispatch(geofencesActions.refresh(await response.json()));
      } else if (endpoint === 'devices') {
        dispatch(devicesActions.refresh(await response.json()));
      }
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
  };

  const handleResponse = (successPredicate, errorPredicate) => {
    if (successPredicate) {
      onResult();
      if (endpoint === 'geofences' || endpoint === 'devices') {
        refreshGeofences();
      }
    } else if (errorPredicate) {
      logout(history, dispatch);
    }
  };

  const handleRemove = async () => {
    setIsRequestSending(true);

    try {
      const response = await fetch(`/api/${endpoint}/bulk`, {
        method: 'DELETE',
        body: JSON.stringify({ ids: Array.isArray(itemId) ? itemId : [itemId] }),
        headers: { 'Content-Type': 'application/json' },
      });

      handleResponse(
        response.ok,
        response.status === 401,
      );
    } catch (error) {
      if (error.name === 'AbortError') return;
      console.warn('Ошибка при обработке ответа от запроса на удаление.', error);
    } finally {
      setIsRequestSending(false);
    }
  };

  const handleCancelButtonClick = () => {
    clearResourcesAndCallOnResult();
  };

  return (
    <Dialog open={open} onClose={handleCancelButtonClick}>
      <DialogContent>
        <DialogContentText>
          {itemId?.length > 0 ? t('sharedManyRemoveConfirm') : t('sharedRemoveConfirm')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={isRequestSending} color="primary" onClick={handleRemove}>
          {isRequestSending ? t('sharedRemoving') : t('sharedRemove')}
        </Button>
        <Button autoFocus onClick={handleCancelButtonClick}>{t('sharedCancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveDialog;
