import React, { useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import moment from 'moment';
import MapPadding from '../../../map/MapPadding';
import PositionsMap from '../../../map/PositionsMap';
import BSMap from '../../../map/BSMap';
import GeofenceMap from '../../../map/GeofenceMap';
// import AccuracyMap from '../../../map/AccuracyMap';
import SelectedDeviceMap from '../../../map/SelectedDeviceMap';
import SelectedPositionMap from '../../../map/SelectedPositionMap';
import CurrentPositionsMap from '../../../map/CurrentPositionsMap';
import Map, { map } from '../../../map/Map';
import positionsTypes from '../../../common/static/positionsTypes';
import ReplayPathMap from '../../../map/ReplayPathMap';
import MarkerOneMap from '../../../map/MarkerOneMap';
import MarkersMap from '../../../map/MarkersMap';
import RulerMap from '../../../map/RulerMap';
import CurrentLocationMap from '../../../map/CurrentLocationMap';
import MapCamera from '../../../map/MapCamera';
import { runLoadingResources } from '../../../map/MapControllerDynamic';
import ExtraLayers from '../../../map/ExtraLayers';
import ExtraMap from '../../../map/ExtraMap';
import { positionsActions, tailActions } from '../../../store';
import { getDeviceIds } from '../../../common/utils/formatter';
import { useEffectAsync } from '../../../common/utils/reactHelper';
import requestRoute from '../../../common/utils/requestRoute';

const MainMap = ({
  isTablet, item, panel, setNewMarkerCoordinates, setSelectedMarkerComponents,
  nextPosition, setCountPosition, setNextPosition, statePositions, needMoveMap, addMarkerMode, setAddMarkerMode,
  moveableMarker, setMoveableMarker, rulerMode, setRulerDistance, setPositionDataVisible, setPositionsBS,
  markerState, clearMarkerState, moveableDevice, setMoveableDevice, opacityMaps, getFrom, setProgress, period,
}) => {
  const theme = useTheme();

  const devices = useSelector((state) => state.devices.items);
  const dispatch = useDispatch();
  const stateSyncDevices = useSelector((state) => state.devices.stateSyncDevices);
  const stateSyncClosedDevices = useSelector((state) => state.devices.stateSyncClosedDevices);
  const closedDevices = useSelector((state) => state.devices.closedDevices);
  const stateSyncCurrentPositions = useSelector((state) => state.positions.stateSyncCurrentPositions);

  const selectedPositionData = useSelector((state) => state.positions.selectedPositionData);

  const positions = useSelector((state) => state.tail.positions);
  const positionsPath = useSelector((state) => state.tail.positionsPath);
  const positionsStop = useSelector((state) => state.tail.positionsStop);
  const positionsSimilar = useSelector((state) => state.tail.positionsSimilar);
  const pathMatching = useSelector((state) => state.tail.pathMatching);

  const stateSyncTail = useSelector((state) => state.tail.stateSyncTail);

  const eventClosedDevices = async () => {
    /**
     * Добавляет или удаляет трек для новых устройств
     * при включенной функции "Построить трек" и при изменении списка включенных устройств
     */
    if (selectedPositionData) {
      if (closedDevices[selectedPositionData.deviceId]) {
        unstable_batchedUpdates(() => {
          dispatch(positionsActions.unselectPosition());
          setPositionsBS([]);
        });
      }
    }
    if (item) {
      const deviceIds = getDeviceIds(devices);
      if (Object.keys(closedDevices).filter((deviceId) => closedDevices[deviceId]).length !== deviceIds.length) {
        setProgress(1);
        const newDevices = [];
        const hidenIds = [];
        deviceIds.forEach((id) => {
          const deviceId = Number(id);
          if (closedDevices[deviceId] || !devices[deviceId]) {
            hidenIds.push(deviceId);
          } else if (!positions[deviceId]) {
            newDevices.push(devices[deviceId]);
          }
        });
        dispatch(tailActions.remove(hidenIds));
        if (newDevices.length) {
          const selectedFrom = getFrom(period);
          await requestRoute(
            newDevices,
            selectedFrom.toISOString(),
            moment().toISOString(),
            { Accept: 'application/json' },
            {},
            panel.onBs,
            true,
            setProgress,
            dispatch,
          );
        }
        setProgress(0);
      } else {
        unstable_batchedUpdates(() => {
          dispatch(tailActions.clear());
        });
      }
    }
  };

  // useEffectAsync(async () => {
  //   await eventClosedDevices();
  // }, [stateSyncClosedDevices]);

  // Запуск инициализации ресурсов карты.
  useEffect(() => runLoadingResources(), []);

  useEffect(() => {
    /**
     * Изменение стиля курсора при использовании инструмента Маркер
     */
    if (addMarkerMode || moveableMarker || moveableDevice) {
      map.getCanvas().style.cursor = 'crosshair';
    } else {
      map.getCanvas().style.cursor = 'grab';
    }
  }, [addMarkerMode, moveableMarker, moveableDevice, map.getCanvas().style.cursor]);

  return (
    <Map needMoveMap={needMoveMap}>
      <ExtraMap opacityMaps={opacityMaps} />
      {!isTablet && <MapPadding left={parseInt(theme.dimensions.drawerWidthDesktop, 10)} />}
      {item && (
        <>
          {panel.onLine === 1 && (
            <ReplayPathMap positions={positionsPath} devices={devices} stateSyncDevices={stateSyncDevices} stateSyncPositions={stateSyncTail} tail />
          )}
          {panel.onLine === 2 && (
            <ReplayPathMap positions={pathMatching} devices={devices} stateSyncDevices={stateSyncDevices} stateSyncPositions={stateSyncTail} tail matching />
          )}
          {panel.onPositions && (
            <PositionsMap
              positions={positions}
              devices={devices}
              stateSyncDevices={stateSyncDevices}
              stateSyncPositions={stateSyncTail}
              stateSyncClosedDevices={stateSyncClosedDevices}
              closedDevices={closedDevices}
              positionsSimilar={positionsSimilar}
              data={positionsTypes.arrow}
              onClusters={panel.onClustersPositions}
              text={panel.onTextPositions}
              nextPosition={nextPosition}
              setCountPosition={setCountPosition}
              setNextPosition={setNextPosition}
              setPositionsBS={statePositions.setPositionsBS}
              onTower={panel.onTower}
              noClick={addMarkerMode || rulerMode}
            />
          )}
          {panel.onSides && (
            <PositionsMap
              positions={positions}
              devices={devices}
              stateSyncDevices={stateSyncDevices}
              stateSyncPositions={stateSyncTail}
              stateSyncClosedDevices={stateSyncClosedDevices}
              closedDevices={closedDevices}
              data={positionsTypes.startPoint}
              onClusters={panel.onClustersSides}
              text={panel.onTextSides}
              setNextPosition={setNextPosition}
              setPositionsBS={statePositions.setPositionsBS}
              onTower={panel.onTower}
              noClick={addMarkerMode || rulerMode}
            />
          )}
          {!!(panel.onSides && !panel.onCurrent) && (
            <PositionsMap
              positions={positions}
              devices={devices}
              stateSyncDevices={stateSyncDevices}
              stateSyncPositions={stateSyncTail}
              stateSyncClosedDevices={stateSyncClosedDevices}
              closedDevices={closedDevices}
              data={positionsTypes.finishPoint}
              onClusters={panel.onClustersSides}
              text={panel.onTextSides}
              setNextPosition={setNextPosition}
              setPositionsBS={statePositions.setPositionsBS}
              onTower={panel.onTower}
              noClick={addMarkerMode || rulerMode}
            />
          )}
          {panel.onStops && (
            <PositionsMap
              positions={positionsStop}
              devices={devices}
              stateSyncDevices={stateSyncDevices}
              stateSyncPositions={stateSyncTail}
              stateSyncClosedDevices={stateSyncClosedDevices}
              closedDevices={closedDevices}
              data={positionsTypes.stop}
              setNextPosition={setNextPosition}
              onClusters={panel.onClustersStops}
              text={panel.onTextStops}
              setPositionsBS={statePositions.setPositionsBS}
              noClick={addMarkerMode || rulerMode}
            />
          )}
        </>
      )}
      {!!(statePositions.positionsBS.length && panel.onTower) && (
        <BSMap
          positions={statePositions.positionsBS}
          data={positionsTypes.bs}
          onTower={panel.onTower}
        />
      )}
      {panel.onGeofence && (
        <GeofenceMap />
      )}
      {/* {panel.onCurrent && (
        <AccuracyMap closedDevices={closedDevices} />
      )} */}
      <SelectedDeviceMap />
      {Object.keys(selectedPositionData).length && <SelectedPositionMap position={selectedPositionData} />}
      {Object.keys(selectedPositionData).length && <MapCamera latitude={selectedPositionData.latitude} longitude={selectedPositionData.longitude} />}
      <CurrentPositionsMap
        setNextPosition={setNextPosition}
        onClusters={panel.onClustersCurrent}
        text={panel.onTextCurrent}
        visibility="hidden"
        onCurrent={panel.onCurrent}
        setPositionsBS={statePositions.setPositionsBS}
        onTower={panel.onTower}
        noClick={addMarkerMode || rulerMode}
        stateSyncPositions={stateSyncCurrentPositions}
        moveableDevice={moveableDevice}
        setMoveableDevice={setMoveableDevice}
        setNewMarkerCoordinates={setNewMarkerCoordinates}
      />
      <MarkerOneMap
        addMarkerMode={addMarkerMode}
        setAddMarkerMode={setAddMarkerMode}
        markerState={markerState}
        clearMarkerState={clearMarkerState}
        setNewMarkerCoordinates={setNewMarkerCoordinates}
      />
      {panel.onMarkers && (
        <MarkersMap
          noClick={addMarkerMode || rulerMode}
          setPositionDataVisible={setPositionDataVisible}
          setPositionsBS={setPositionsBS}
          setNextPosition={setNextPosition}
          setNewMarkerCoordinates={setNewMarkerCoordinates}
          moveableMarker={moveableMarker}
          setMoveableMarker={setMoveableMarker}
          onClusters={panel.onClustersMarkers}
          text={panel.onTextMarkers}
          markerState={markerState}
          clearMarkerState={clearMarkerState}
          setSelectedMarkerComponents={setSelectedMarkerComponents}
        />
      )}
      <RulerMap rulerMode={rulerMode} setDistance={setRulerDistance} />
      <CurrentLocationMap />
      <ExtraLayers />
    </Map>
  );
};

export default MainMap;
