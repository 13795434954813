import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Column } from 'devextreme-react/data-grid';
import { LinearProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { useEffectAsync } from '../../common/utils/reactHelper';
import EditCollectionView from '../../EditCollectionView';
import OptionsLayout from './OptionsLayout';
import logout from '../../common/utils/logout';
import SmartTable from '../../common/components/SmartTable';
import { allowedPageSizes, currentPageSize } from '../../common/settings/settingsPage';
import { getStringFromBoolean } from '../../common/components/SmartTable/funcs/utils';

const UsersView = ({ updateTimestamp, onMenuClick, onAddNewRow }) => {
  const t = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const [requestProgress, setRequestProgress] = useState(true);

  useEffectAsync(async () => {
    const response = await fetch('/api/users');
    if (response.ok) {
      setItems(await response.json());
    } else if (response.status === 401) {
      if (response.status === 401) {
        logout(history, dispatch);
      }
    }
    setRequestProgress(false);
  }, [updateTimestamp]);

  if (requestProgress) {
    return (
      <LinearProgress style={{ top: '100px' }} />
    );
  }

  return (
    <SmartTable
      tableName="UsersPage"
      fileExportingName="settingsUsers"
      allowedPageSizes={allowedPageSizes}
      currentPageSize={currentPageSize}
      arrayDependencies={[items]}
      dataSource={items}
      onAddNewRow={onAddNewRow}
      onMenuClick={onMenuClick}
    >
      <Column
        dataField="id"
        caption={t('deviceIdentifier')}
        width={100}
        visible={false}
      />
      <Column
        dataField="name"
        caption={t('userName')}
      />
      <Column
        dataField="login"
        caption={t('userLogin')}
      />
      <Column
        dataField="email"
        caption={t('userEmail')}
      />
      <Column
        dataField="administrator"
        dataType="string"
        caption={t('userAdmin')}
        calculateCellValue={(item) => getStringFromBoolean(item.administrator, t)}
      />
    </SmartTable>
  );
};

const UsersPage = () => {
  const t = useTranslation();

  return (
    <OptionsLayout>
      <EditCollectionView
        disableAdd
        stickyTop
        textOfAddButton={t('usersAddUser')}
        content={UsersView}
        editPath="/user"
        endpoint="users"
      />
    </OptionsLayout>
  );
};

export default UsersPage;
