import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, useTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../common/components/LocalizationProvider';
import EditItemView from './components/EditItemView';
import LinkField from '../../common/form/LinkField';
import SelectField from '../../common/form/SelectField';
import { useEffectAsync } from '../../common/utils/reactHelper';
// import markerCategoryParams from '../../common/static/markerCategoryParams';
// import { prefixString } from '../../common/utils/stringUtils';
// import MarkerCategoryFields from './components/MarkerCategoryFields';
import { getIsAdmin } from '../../common/utils/selectors';
import SelectMarkerIcon from './components/SelectMarkerIcon';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const MarkerPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const theme = useTheme();
  const isAdmin = useSelector(getIsAdmin);
  const userId = useSelector((state) => state.session.user?.id);
  const [item, setItem] = useState();
  const [permissions, setPermissions] = useState();
  const [icons, setIcons] = useState([]);
  const [latitudeError, setLatitudeError] = useState(false);
  const [longitudeError, setLongitudeError] = useState(false);
  // const [categoryParams, setCategoryParams] = useState();

  const latLongIsValid = (value, border, setError) => {
    if (value && !Number.isNaN(value) && -border <= Number(value) && Number(value) <= border) {
      setError(false);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    latLongIsValid(item?.latitude, 90, setLatitudeError);
  }, [item?.latitude]);

  useEffect(() => {
    latLongIsValid(item?.longitude, 180, setLongitudeError);
  }, [item?.longitude]);

  useEffect(() => {
    if (item && !permissions) {
      const permissionsDict = {
        unions: {
          baseId: item.id,
          keyBase: 'markerId',
          keyLink: 'unionId',
          linked: [],
          old: [],
        },
      };
      setPermissions(permissionsDict);
    }
  }, [item]);

  useEffectAsync(async () => {
    const response = await fetch('/api/icons');
    if (response.ok) {
      setIcons(await response.json());
    } else {
      setIcons([]);
    }
  }, []);

  const isNumber = (value) => {
    if (value === '' || !Number.isNaN(Number(value))) {
      return true;
    }
    return false;
  };

  // useEffect(() => {
  //   if (item) {
  //     if (!categoryParams) {
  //       setCategoryParams(item.attributes?.categoryParams || markerCategoryParams.marker);
  //     } else if (item.category !== categoryParams.category) {
  //       setCategoryParams(markerCategoryParams[item.category || 'marker']);
  //     }
  //   }
  // }, [item?.category]);

  // useEffect(() => {
  //   if (item && categoryParams) {
  //     setItem({ ...item, attributes: { ...item.attributes, categoryParams } });
  //   }
  // }, [categoryParams]);

  return (
    <EditItemView
      endpoint="markers"
      item={item}
      setItem={setItem}
      permissions={permissions}
      permissionsReverse
      disabledSave={!item?.color
        || !item?.iconName || latitudeError || longitudeError}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('settingsMarker')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                fullWidth
                value={item.name || ''}
                label={t('markerName')}
                onChange={(e) => e.target.value.length < 26 && setItem({ ...item, name: e.target.value })}
              />
              <TextField
                fullWidth
                value={item.description || ''}
                label={t('markerDescription')}
                multiline
                onChange={(e) => e.target.value.length < 4001 && setItem({ ...item, description: e.target.value })}
              />
              <TextField
                fullWidth
                value={item.latitude || ''}
                required
                helperText={latitudeError && '-90.0 - 90.0'}
                placeholder="56.018134"
                label={t('markerLatitude')}
                error={latitudeError}
                multiline
                onChange={(e) => setItem({ ...item, latitude: e.target.value })}
              />
              <TextField
                fullWidth
                required
                helperText={longitudeError && '-180.0 - 180.0'}
                placeholder="149.145755"
                value={item.longitude || ''}
                label={t('markerLongitude')}
                error={longitudeError}
                multiline
                onChange={(e) => setItem({ ...item, longitude: e.target.value })}
              />

              <SelectMarkerIcon icons={icons} item={item} setItem={setItem} />

              <SelectField
                required
                label={t('markerColor')}
                value={item.color || ''}
                keyGetter={(it) => it.toLowerCase()}
                onChange={(e) => setItem({ ...item, color: e.target.value.toLowerCase() })}
                titleGetter={(color) => <FiberManualRecordIcon style={{ color }} />}
                data={Object.values(theme.palette.markers)}
                margin="normal"
                variant="filled"
                emptyValue={null}
                fullWidth
              />
              {/* <SelectField
                margin="normal"
                value={item.category || ''}
                emptyValue={null}
                onChange={(e) => setItem({ ...item, type: e.target.value })}
                endpoint="/api/markers/types"
                keyGetter={(it) => it.category}
                titleGetter={(it) => t(prefixString('marker', it?.type || it))}
                label={t('sharedType')}
                variant="filled"
                fullWidth
              />
              <MarkerCategoryFields categoryParams={categoryParams} setCategoryParams={setCategoryParams} /> */}
              <TextField
                fullWidth
                value={item.course}
                label={t('markerCourse')}
                onChange={(e) => isNumber(e.target.value) && setItem({ ...item, course: e.target.value })}
              />
            </AccordionDetails>
          </Accordion>
          {permissions && (
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedConnections')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <LinkField
                  margin="normal"
                  endpointAll={`/api/unions?${isAdmin ? 'all=true' : `userId=${userId}`}`}
                  endpointLinked={`/api/unions?markerId=${item.id}`}
                  label={t('settingsUnions')}
                  variant="filled"
                  permissions={permissions}
                  setPermissions={setPermissions}
                  type="unions"
                  fullWidth
                  newItem={!item.id}
                />
              </AccordionDetails>
            </Accordion>
          )}
        </>
      )}
    </EditItemView>
  );
};

export default MarkerPage;
