import React, { useState } from 'react';
import {
  useTheme, LinearProgress,
} from '@material-ui/core';
import { Column } from 'devextreme-react/data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffectAsync } from '../../common/utils/reactHelper';
import EditCollectionView from '../../EditCollectionView';
import OptionsLayout from './OptionsLayout';
import { useTranslation } from '../../common/components/LocalizationProvider';
import logout from '../../common/utils/logout';
import { markersActions } from '../../store';
import { getIsAdmin } from '../../common/utils/selectors';
import SmartTable from '../../common/components/SmartTable';
import { allowedPageSizes, currentPageSize } from '../../common/settings/settingsPage';
import { ColorsCellRender, CustomizeCourseRender, TextIconCellRender } from '../../common/components/SmartTable/columns/cells';
import { ColorsGroupCellRender, TextIconGroupCellRender } from '../../common/components/SmartTable/columns/groups';
import { getTextFromIconWithSeparator } from '../../common/components/SmartTable/funcs/calculateCellValue';

const MarkersView = ({ updateTimestamp, onMenuClick, onAddNewRow }) => {
  const t = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  const isAdmin = useSelector(getIsAdmin);
  const userId = useSelector((state) => state.session.user?.id);

  const [items, setItems] = useState([]);
  const [requestProgress, setRequestProgress] = useState(true);

  useEffectAsync(async () => {
    let response;

    if (isAdmin) {
      response = await fetch('/api/markers?all=true');
    } else {
      response = await fetch(`/api/markers?userId=${userId}`);
    }

    if (response.ok) {
      const data = await response.json();
      setItems(data);
      dispatch(markersActions.refresh(data));
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
    setRequestProgress(false);
  }, [updateTimestamp]);

  if (requestProgress) {
    return (
      <LinearProgress style={{ top: '100px' }} />
    );
  }

  return (
    <SmartTable
      tableName="MarkersPage"
      fileExportingName="settingsMarkers"
      allowedPageSizes={allowedPageSizes}
      currentPageSize={currentPageSize}
      arrayDependencies={[items]}
      dataSource={items}
      onAddNewRow={onAddNewRow}
      onMenuClick={onMenuClick}
    >
      <Column
        dataField="id"
        caption={t('deviceIdentifier')}
        width={100}
        visible={false}
      />
      <Column
        key="name"
        name="name"
        dataField="name"
        caption={t('deviceName')}
        minWidth={250}
      />
      <Column
        dataField="icon"
        caption={t('deviceIcon')}
        width={250}
        cellComponent={({ data }) => <TextIconCellRender dataset={data} t={t} />}
        groupCellComponent={({ data }) => <TextIconGroupCellRender dataset={data} t={t} />}
        calculateCellValue={(item) => getTextFromIconWithSeparator(item, t)}
      />
      <Column
        key="color"
        name="color"
        dataField="color"
        dataType="string"
        caption={t('sharedColor')}
        width={100}
        cellComponent={({ data }) => <ColorsCellRender dataset={data} theme={theme} />}
        groupCellComponent={({ data }) => <ColorsGroupCellRender dataset={data} theme={theme} />}
      />
      <Column
        alignment="left"
        dataField="course"
        caption={t('positionCourse')}
        width={100}
        cellComponent={({ data }) => <CustomizeCourseRender dataset={data} />}
      />
    </SmartTable>
  );
};

const MarkersPage = () => {
  const t = useTranslation();

  return (
    <OptionsLayout>
      <EditCollectionView
        hideButtonAdd
        stickyTop
        textOfAddButton={t('markerAddMarker')}
        content={MarkersView}
        editPath="/settings/marker"
        endpoint="markers"
        forseAdd
      />
    </OptionsLayout>
  );
};

export default MarkersPage;
