import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, Checkbox, FormControlLabel,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from '../../common/components/LocalizationProvider';
import EditItemView from './components/EditItemView';
import LinkField from '../../common/form/LinkField';
import { formatPhone } from '../../common/utils/formatter';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const UserPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const adminEnabled = useSelector((state) => state.session.user?.administrator);
  const [item, setItem] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [confirmationTrouble, setConfirmationTrouble] = useState(false);
  const [tokenTrouble, setTokenTrouble] = useState(false);
  const [permissions, setPermissions] = useState();
  const groups = useSelector((state) => state.groups.items);
  const { id } = useParams();
  const [errorPhone, setErrorPhone] = useState(false);

  useEffect(() => {
    const lengthPhone = item?.phone?.length;
    if (lengthPhone) {
      if (lengthPhone !== 12 || (lengthPhone > 1 && item.phone.slice(0, 2) !== '+7')) {
        setErrorPhone(true);
        return;
      }
    }
    setErrorPhone(false);
  }, [item?.phone]);

  useEffect(() => {
    if (item && !permissions) {
      const permissionsDict = {
        devices: {
          baseId: item.id,
          keyBase: 'userId',
          keyLink: 'deviceId',
          linked: [],
          old: [],
          groupReserve: [],
        },
        groups: {
          baseId: item.id,
          keyBase: 'userId',
          keyLink: 'groupId',
          linked: [],
          old: [],
        },
        unions: {
          baseId: item.id,
          keyBase: 'userId',
          keyLink: 'unionId',
          linked: [],
          old: [],
          reverse: true,
        },
      };
      setPermissions(permissionsDict);
    }
  }, [item]);

  useEffect(() => {
    if (!item?.password) {
      setConfirmPassword(item?.password);
    }
  }, [item]);

  return (
    <EditItemView
      endpoint="users"
      item={item}
      setItem={setItem}
      permissions={permissions}
      disabledSave={!(item && item.name && item.login && ((item.password && confirmPassword) || id) && !errorPhone)}
      confirmPassword={confirmPassword}
      setConfirmationTrouble={setConfirmationTrouble}
    >
      {item
        && (
          <>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('settingsUser')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                {adminEnabled && (
                  <TextField
                    required
                    margin="normal"
                    value={item.login || ''}
                    onChange={(event) => setItem({ ...item, login: event.target.value })}
                    label={t('userLogin')}
                    variant="filled"
                  />
                )}
                <TextField
                  required
                  margin="normal"
                  value={item.name || ''}
                  onChange={(event) => setItem({ ...item, name: event.target.value })}
                  label={t('sharedName')}
                  variant="filled"
                />
                <TextField
                  margin="normal"
                  value={item.email || ''}
                  onChange={(event) => setItem({ ...item, email: event.target.value })}
                  label={t('userEmail')}
                  variant="filled"
                />
                <TextField
                  margin="normal"
                  type="password"
                  required={!id}
                  error={confirmationTrouble}
                  onChange={(event) => {
                    setItem({ ...item, password: event.target.value });
                    if (confirmationTrouble) {
                      setConfirmationTrouble(false);
                    }
                  }}
                  label={t('userPassword')}
                  variant="filled"
                />
                <TextField
                  margin="normal"
                  type="password"
                  required={!id}
                  error={confirmationTrouble}
                  helperText={confirmationTrouble && t('userConfirmationTrouble')}
                  onChange={(event) => {
                    setConfirmPassword(event.target.value);
                    if (confirmationTrouble) {
                      setConfirmationTrouble(false);
                    }
                  }}
                  label={t('userConfirmPassword')}
                  variant="filled"
                />
                <TextField
                  margin="normal"
                  value={item.token || ''}
                  onChange={(event) => {
                    const { value } = event.target;
                    setItem({ ...item, token: value });
                    if (value && !value.match(/^[a-zA-Z0-9-]{16,}$/g)) {
                      setTokenTrouble(true);
                    } else {
                      setTokenTrouble(false);
                    }
                  }}
                  label={t('userToken')}
                  error={tokenTrouble}
                  helperText={tokenTrouble ? t('userTokenTrouble') : ''}
                  variant="filled"
                />
                <TextField
                  margin="normal"
                  value={item.phone || ''}
                  onChange={(event) => (formatPhone(event.target.value, item, setItem))}
                  label={t('sharedPhone')}
                  placeholder="+79XXXXXXXXX"
                  error={errorPhone}
                  helperText={errorPhone ? t('errorPhone') : ''}
                  variant="filled"
                />
                <TextField
                  margin="normal"
                  value={item.attributes?.telegramChatId || ''}
                  onChange={(event) => setItem({ ...item, attributes: { telegramChatId: event.target.value } })}
                  label={t('sharedTelegram')}
                  variant="filled"
                />
                {!!(item.id && adminEnabled && permissions) && (
                  <LinkField
                    margin="normal"
                    endpointAll="/api/unions?all=true"
                    endpointLinked={`/api/unions?userId=${item.id}`}
                    label={t('settingsUnions')}
                    variant="filled"
                    permissions={permissions}
                    setPermissions={setPermissions}
                    type="unions"
                  />
                )}
                {adminEnabled && (
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={item.administrator}
                        onChange={(event) => setItem({
                          ...item,
                          administrator: event.target.checked,
                          deviceLimit: event.target.checked ? -1 : 0,
                        })}
                      />
                    )}
                    label={t('userAdmin')}
                  />
                )}
              </AccordionDetails>
            </Accordion>
            {!!(item.id && adminEnabled && permissions)
              && (
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1">
                      {t('sharedConnections')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    <LinkField
                      margin="normal"
                      endpointAll="/api/groups?all=true"
                      endpointLinked={`/api/groups?userId=${item.id}`}
                      label={t('settingsGroups')}
                      variant="filled"
                      permissions={permissions}
                      setPermissions={setPermissions}
                      type="groups"
                    />
                    <LinkField
                      margin="normal"
                      endpointAll="/api/devices?all=true"
                      endpointLinked={`/api/devices?userId=${item.id}`}
                      label={t('deviceTitle')}
                      variant="filled"
                      subtitleGetter={(i) => groups[i.groupId]?.name}
                      permissions={permissions}
                      setPermissions={setPermissions}
                      type="devices"
                    />
                  </AccordionDetails>
                </Accordion>
              )}
          </>
        )}
    </EditItemView>
  );
};

export default UserPage;
