export default [
  'airplane_silhouette',
  'alert_circle',
  'animal',
  'anti_aircraft_gun',
  'arrow_bounce',
  'artificial_object',
  'asterisk',
  'bicycle',
  'bird',
  'birds',
  'boat',
  'bunker',
  'bus',
  'cannon',
  'car',
  'circle_dashed',
  'crane',
  'decoy',
  'default',
  'explosion',
  'helicopter',
  'helicopter_silhouette',
  'hierarchy',
  'location',
  'message_circle',
  'motorcycle',
  'navigation',
  'offroad',
  'pentagon',
  'person',
  'pickup',
  'plane',
  'point',
  'point_fill',
  'radar',
  'radar_dish',
  'rocket',
  'run',
  'scooter',
  'ship',
  'soldiers',
  'square_rotated',
  'square',
  'square_arrow_down',
  'square_dot',
  'submarine',
  'tank_canon',
  'tank_gun',
  'tank_torpedo',
  'torpedo',
  'tractor',
  'train',
  'tram',
  'trolleybus',
  'truck',
  'uav',
  'uav_big',
  'uav_copter',
  'uav_plane',
  'uav_wing',
  'unknown',
  'users',
  'van',
  'vessel',
  'viewfinder',
  'remote_control',
];
