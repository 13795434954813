import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  Typography, AccordionActions, AccordionSummary,
  Checkbox, Tooltip, IconButton, MenuItem, ListItemIcon, ButtonGroup, Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import {
  ExpandLess, LaunchRounded,
} from '@material-ui/icons';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import { toTitleCase } from '../../../../common/utils/formatter';
import { FULL, LINKS_TO_CATEGORIES_FOR_HEADERS, NOT_FULL } from './constants';
import useStatusCheckboxChecker from '../../../../common/hooks/useStatusCheckboxChecker';

const VirtualHeaderRow = ({
  style, deviceHeader, classes,
  turnOnDevices, expandHandler, turnIconText, turnIconCluster,
  menuHeaderContent, handleOnClickMoreInfo, handleMenuHeaderClose,
}) => {
  const t = useTranslation();
  const checkboxStatus = useStatusCheckboxChecker();

  checkboxStatus.defineStatus(deviceHeader.visibilityStatus === FULL, deviceHeader.visibilityStatus === NOT_FULL);

  const handleTurnOnDevices = () => {
    turnOnDevices(deviceHeader);
    handleMenuHeaderClose();
  };

  const handleTurnIconTextCategory = (val) => {
    turnIconText(deviceHeader, val);
    handleMenuHeaderClose();
  };

  const handleTurnIconClusterCategory = () => {
    turnIconCluster(deviceHeader);
    handleMenuHeaderClose();
  };

  const handleLinkClick = (link) => {
    window.open(link, '_blank');
    handleMenuHeaderClose();
  };

  const getIconTextButtonStyle = (val) => ({
    margin: 0,
    backgroundColor: (deviceHeader.iconText ?? null) === val ? '#deefd8' : '',
  });

  const handleMenuOpening = (event) => {
    menuHeaderContent.current = () => {
      const componentsArray = [
        <MenuItem key={1} button onClick={handleTurnOnDevices}>
          <ListItemIcon>
            <Checkbox style={checkboxStatus.getStyles()} checked={checkboxStatus.notEmpty()} />
          </ListItemIcon>
          <Typography>
            {t('allShowDevicesOnMap')}
          </Typography>
        </MenuItem>,
      ];

      if (LINKS_TO_CATEGORIES_FOR_HEADERS[deviceHeader.title]) {
        componentsArray.push(
          <MenuItem key={4} button onClick={() => handleLinkClick(LINKS_TO_CATEGORIES_FOR_HEADERS[deviceHeader.title])}>
            <ListItemIcon style={{ paddingLeft: 8 }}>
              <LaunchRounded color="action" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              {t('sharedShowDetails')}
            </Typography>
          </MenuItem>,
        );
      }
      componentsArray.push((
        <MenuItem style={{ paddingTop: 0, paddingBottom: 0 }} key={2}>
          <ButtonGroup fullWidth variant="outlined" size="small">
            <Button size="small" style={getIconTextButtonStyle(null)} onClick={() => handleTurnIconTextCategory(null)}>
              <Typography variant="caption">{t('auto')}</Typography>
            </Button>
            <Button size="small" style={getIconTextButtonStyle(true)} onClick={() => handleTurnIconTextCategory(true)}>
              <IconButton style={{ color: '#198cff' }} size="small"><TextFieldsIcon size="small" /></IconButton>
            </Button>
            <Button size="small" style={getIconTextButtonStyle(false)} onClick={() => handleTurnIconTextCategory(false)}>
              <IconButton size="small"><TextFieldsIcon size="small" /></IconButton>
            </Button>
          </ButtonGroup>
        </MenuItem>
      ));

      return componentsArray;
    };

    handleOnClickMoreInfo(event);
  };

  return (
    <div style={style} className={classes.categoryPanel}>
      <AccordionSummary
        style={{ flex: 1 }}
        className={classes.accordionSummary}
        onClick={() => expandHandler(deviceHeader)}
      >
        <div className={classes.accordionSummaryContainer}>
          {deviceHeader.expanded ? (<ExpandMoreIcon color="action" />) : (<ExpandLess className={classes.toRightIcon} color="action" />)}

          <div className={`${classes.accordionSummaryCaption} ${classes.accordionSummaryContainer}`}>
            <Tooltip title={t(`deviceCategories${toTitleCase(deviceHeader.title)}`)}>
              <Typography noWrap variant="subtitle1">
                {t(`deviceCategories${toTitleCase(deviceHeader.title)}`)}
              </Typography>
            </Tooltip>
            <Typography variant="caption">
              (
              {deviceHeader.subtitle}
              )
            </Typography>
          </div>
        </div>
      </AccordionSummary>
      <AccordionActions>
        <IconButton onClick={handleMenuOpening}>
          <MoreVertIcon />
        </IconButton>
      </AccordionActions>
    </div>
  );
};

export default VirtualHeaderRow;
