import React from 'react';
import { useSelector } from 'react-redux';
import PositionsMap from './PositionsMap';
import positionsTypes from '../common/static/positionsTypes';

const CurrentPositionsMap = ({
  onClusters, onCurrent, setNextPosition, text, setPositionsBS, onTower, noClick, stateSyncPositions, setMoveableDevice,
  setNewMarkerCoordinates, moveableDevice,
}) => {
  const devices = useSelector((state) => state.devices.items);
  const stateSyncDevices = useSelector((state) => state.devices.stateSyncDevices);
  const stateSyncClosedDevices = useSelector((state) => state.devices.stateSyncClosedDevices);
  const closedDevices = useSelector((state) => state.devices.closedDevices);
  const positions = useSelector((state) => state.positions.items);

  return (
    onCurrent && (
      <PositionsMap
        positions={positions}
        stateSyncDevices={stateSyncDevices}
        stateSyncClosedDevices={stateSyncClosedDevices}
        devices={devices}
        closedDevices={closedDevices}
        data={positionsTypes.current}
        setNextPosition={setNextPosition}
        onClusters={onClusters}
        text={text}
        setPositionsBS={setPositionsBS}
        onTower={onTower}
        noClick={noClick}
        stateSyncPositions={stateSyncPositions}
        setMoveableDevice={setMoveableDevice}
        setNewMarkerCoordinates={setNewMarkerCoordinates}
        moveableDevice={moveableDevice}
      />
    )
  );
};

export default CurrentPositionsMap;
