import React from 'react';
import { useSelector } from 'react-redux';
import {
  useTheme, Typography,
  makeStyles, TextField, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, Checkbox,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from '../../LocalizationProvider';
import { formatPhone, getModel } from '../../../utils/formatter';
import { getIsAdmin } from '../../../utils/selectors';
import SelectField from '../../../form/SelectField';
import LinkField from '../../../form/LinkField';
import commands from '../../../static/commands';
import { prefixString } from '../../../utils/stringUtils';
import DeviceCategoryFields from '../../../../views/Settings/components/DeviceCategoryFields';
import theme from '../../../theme';
import icons from '../../../static/icons';
import SelectDeviceIcon from '../../../../views/Settings/components/SelectDeviceIcon';
import categoryParamsStructure from '../../../static/categoryParamsStructure';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
  accordionSummary: {
    backgroundColor: theme.palette.grey[100],
  },
}));

const DeviceEdit = ({
  deviceInfo, setDeviceInfo, errorPhone, permissions, setPermissions, categoryParams, setCategoryParams,
  latitudeError, longitudeError, altitudeError,
}) => {
  const t = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  const isAdmin = useSelector(getIsAdmin);

  const onChangeIcon = (icon) => {
    setDeviceInfo({
      ...deviceInfo,
      icon,
    });
  };

  return (
    <>
      {isAdmin && (
        <Accordion defaultExpanded elevation={0}>
          <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">
              {t('sharedDevice')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <TextField
              required
              value={deviceInfo.name || ''}
              onChange={(event) => setDeviceInfo({ ...deviceInfo, name: event.target.value })}
              label={t('sharedName')}
              margin="dense"
              variant="filled"
            />
            <TextField
              required
              value={deviceInfo.uniqueId || ''}
              onChange={(event) => setDeviceInfo({ ...deviceInfo, uniqueId: event.target.value })}
              label={t('deviceIdentifier')}
              margin="dense"
              variant="filled"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={deviceInfo.temporary}
                  onChange={(e) => setDeviceInfo({ ...deviceInfo, temporary: e.target.checked })}
                />
              )}
              label={t('deviceTemporary')}
            />
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion defaultExpanded elevation={0}>
        <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1">
            {isAdmin ? t('sharedExtra') : `${t('sharedDevice')} — ${deviceInfo.name}`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {isAdmin && (
            <SelectField
              value={deviceInfo.groupId || 0}
              onChange={(event) => setDeviceInfo({ ...deviceInfo, groupId: Number(event.target.value) })}
              endpoint="/api/groups"
              label={t('groupParent')}
              margin="dense"
              variant="filled"
            />
          )}
          {isAdmin && (
            <TextField
              value={deviceInfo.phone || ''}
              onChange={(event) => formatPhone(event.target.value, deviceInfo, setDeviceInfo)}
              label={t('sharedPhone')}
              placeholder="+79XXXXXXXXX"
              error={errorPhone}
              helperText={errorPhone ? t('errorPhone') : ''}
              margin="dense"
              variant="filled"
            />
          )}
          <SelectField
            value={deviceInfo.category || ''}
            emptyValue={null}
            onChange={(e) => setDeviceInfo({ ...deviceInfo, category: e.target.value, model: '' })}
            endpoint="/api/devices/categories"
            keyGetter={(it) => it}
            titleGetter={(it) => t(prefixString('device', it?.category || it))}
            label={t('sharedCategory')}
            margin="dense"
            variant="filled"
          />
          <SelectField
            margin="normal"
            value={getModel(deviceInfo)}
            emptyValue={null}
            onChange={(e) => setDeviceInfo({ ...deviceInfo, model: e.target.value })}
            data={Object.keys(categoryParamsStructure[deviceInfo.category]?.model || [])}
            keyGetter={(it) => it}
            titleGetter={(it) => t(prefixString('deviceModel', it))}
            label={t('deviceModel')}
            variant="filled"
          />
          <SelectDeviceIcon icons={icons} currentIcon={deviceInfo.icon} onChange={onChangeIcon} />
          <SelectField
            value={deviceInfo.attributes?.protocol || ''}
            data={Object.keys(commands).map((protocol) => ({
              id: protocol,
              name: protocol,
            }))}
            onChange={(event) => setDeviceInfo({ ...deviceInfo, attributes: { ...deviceInfo.attributes, protocol: event.target.value } })}
            label={t('deviceCommunicationProtocol')}
            margin="dense"
            variant="filled"
          />
          <SelectField
            value={deviceInfo.attributes?.movement || ''}
            data={['car', 'foot'].map((way) => ({
              id: way,
              name: t(`${way}Movement`),
            }))}
            onChange={(event) => setDeviceInfo({ ...deviceInfo, attributes: { ...deviceInfo.attributes, movement: event.target.value } })}
            label={t('deviceMovement')}
            margin="dense"
            variant="filled"
          />
          <SelectField
            value={deviceInfo.attributes?.color || theme.palette.tracks.replay0}
            emptyValue={null}
            onChange={(event) => setDeviceInfo({ ...deviceInfo, attributes: { ...deviceInfo.attributes, color: event.target.value } })}
            data={Object.keys(theme.palette.tracks).map((color) => ({
              id: theme.palette.tracks[color],
              name: <FiberManualRecordIcon style={{ color: theme.palette.tracks[color] }} />,
            }))}
            label={t('trackColor')}
            margin="dense"
            variant="filled"
          />
          <TextField
            fullWidth
            value={deviceInfo.attributes?.latitude || ''}
            helperText={latitudeError && '-90.0 - 90.0'}
            placeholder="56.018134"
            label={t('deviceLatitude')}
            error={latitudeError}
            multiline
            onChange={(e) => setDeviceInfo({ ...deviceInfo, attributes: { ...deviceInfo.attributes, latitude: e.target.value } })}
          />
          <TextField
            fullWidth
            helperText={longitudeError && '-180.0 - 180.0'}
            placeholder="149.145755"
            value={deviceInfo.attributes?.longitude || ''}
            label={t('deviceLongitude')}
            error={longitudeError}
            multiline
            onChange={(e) => setDeviceInfo({ ...deviceInfo, attributes: { ...deviceInfo.attributes, longitude: e.target.value } })}
          />
          <TextField
            fullWidth
            helperText={altitudeError && '0 - ...'}
            placeholder="10.5"
            value={deviceInfo.attributes?.altitude || ''}
            label={t('deviceAltitude')}
            error={altitudeError}
            multiline
            onChange={(e) => setDeviceInfo({ ...deviceInfo, attributes: { ...deviceInfo.attributes, altitude: e.target.value } })}
          />
          <TextField
            fullWidth
            placeholder="45"
            value={deviceInfo.attributes?.azimuth ?? 0}
            emptyValue
            label={t('deviceAzimuth')}
            onChange={(e) => {
              const value = Number(e.target.value);
              if (!Number.isNaN(value)) {
                setDeviceInfo({ ...deviceInfo, attributes: { ...deviceInfo.attributes, azimuth: value } });
              }
            }}
          />
          {!!(deviceInfo.id && permissions)
            && (
              <>
                <LinkField
                  margin="dense"
                  variant="filled"
                  endpointAll="/api/geofences"
                  endpointLinked={`/api/geofences?deviceId=${deviceInfo.id}`}
                  label={t('sharedGeofences')}
                  permissions={permissions}
                  setPermissions={setPermissions}
                  type="geofences"
                />
                <LinkField
                  margin="dense"
                  variant="filled"
                  endpointAll="/api/notifications"
                  endpointLinked={`/api/notifications?deviceId=${deviceInfo.id}`}
                  titleGetter={(it) => t(prefixString('event', it.type))}
                  label={t('sharedNotifications')}
                  permissions={permissions}
                  setPermissions={setPermissions}
                  type="notifications"
                />
              </>
            )}
          {isAdmin && (
            <FormControlLabel
              control={(
                <Checkbox
                  checked={!!deviceInfo.saveLogs}
                  onChange={
                    (e) => setDeviceInfo({ ...deviceInfo, saveLogs: e.target.checked })
                  }
                />
              )}
              label={t('deviceSaveLogs')}
            />
          )}
        </AccordionDetails>
      </Accordion>
      {!!(categoryParams && Object.keys(categoryParams).length > 1) && (
        <Accordion defaultExpanded elevation={0}>
          <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">
              {t('settingsDeviceCategory')}
            </Typography>
          </AccordionSummary>
          <DeviceCategoryFields item={deviceInfo} categoryParams={categoryParams} setCategoryParams={setCategoryParams} />
        </Accordion>
      )}
    </>
  );
};

export default DeviceEdit;
