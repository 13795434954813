import React from 'react';
import PropTypes from 'prop-types';
import {
  AccordionDetails, Checkbox, Divider, FormControlLabel, TextField, Typography, makeStyles, useTheme,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import SelectField from '../../../common/form/SelectField';
import categoryParamsStructure from '../../../common/static/categoryParamsStructure';
import { getCategory } from '../../../common/utils/formatter';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const DeviceCategoryFields = ({ item, categoryParams, setCategoryParams }) => {
  const t = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const getDefaultValue = (field) => categoryParams[field]
    ?? categoryParamsStructure[getCategory(item)].model[item.model || 'default'].writen[field];

  switch (item.category) {
    case 'radar':
      switch (item.model) {
        case 'rubezh':
          return (
            <AccordionDetails className={classes.details}>
              <TextField
                required
                margin="normal"
                value={getDefaultValue('azimuth')}
                onChange={(event) => setCategoryParams({ ...categoryParams, azimuth: Number(event.target.value) })}
                label={t('deviceAzimuth')}
                variant="filled"
              />
              <TextField
                required
                margin="normal"
                value={getDefaultValue('diagram')}
                onChange={(event) => setCategoryParams({ ...categoryParams, diagram: Number(event.target.value) })}
                label={t('deviceDiagram')}
                variant="filled"
              />
              <TextField
                required
                margin="normal"
                value={getDefaultValue('radius')}
                onChange={(event) => setCategoryParams({ ...categoryParams, radius: Number(event.target.value) })}
                label={t('deviceRadius')}
                variant="filled"
              />
              <SelectField
                margin="normal"
                value={getDefaultValue('radarColor')}
                emptyValue={null}
                onChange={(event) => setCategoryParams({ ...categoryParams, radarColor: event.target.value })}
                data={Object.keys(theme.palette.tracks).map((color) => ({
                  id: theme.palette.tracks[color],
                  name: <FiberManualRecordIcon style={{ color: theme.palette.tracks[color] }} />,
                }))}
                label={t('deviceRadarColor')}
                variant="filled"
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={!!getDefaultValue('showRadarArea')}
                    onChange={
                      (e) => setCategoryParams({ ...categoryParams, showRadarArea: e.target.checked })
                    }
                  />
                )}
                label={t('deviceShowRadarArea')}
              />
              <TextField
                required
                margin="normal"
                value={getDefaultValue('externalId')}
                onChange={(event) => setCategoryParams({ ...categoryParams, externalId: Number(event.target.value) })}
                label={t('deviceExternalId')}
                variant="filled"
              />
              <TextField
                required
                margin="normal"
                value={getDefaultValue('mti')}
                onChange={(event) => setCategoryParams({ ...categoryParams, mti: Number(event.target.value) })}
                label={t('deviceMti')}
                variant="filled"
              />
              <TextField
                required
                margin="normal"
                value={getDefaultValue('ip')}
                onChange={(event) => setCategoryParams({ ...categoryParams, ip: event.target.value })}
                label={t('deviceIp')}
                variant="filled"
              />
              <TextField
                required
                margin="normal"
                value={getDefaultValue('dataPort')}
                onChange={(event) => setCategoryParams({ ...categoryParams, dataPort: Number(event.target.value) })}
                label={t('deviceDataPort')}
                variant="filled"
              />
              <TextField
                required
                margin="normal"
                value={getDefaultValue('controlPort')}
                onChange={(event) => setCategoryParams({ ...categoryParams, controlPort: Number(event.target.value) })}
                label={t('deviceControlPort')}
                variant="filled"
              />
            </AccordionDetails>
          );
        case 'shade':
          return (
            <AccordionDetails className={classes.details}>
              <TextField
                required
                margin="normal"
                value={getDefaultValue('azimuth')}
                onChange={(event) => setCategoryParams({ ...categoryParams, azimuth: Number(event.target.value) })}
                label={t('deviceAzimuth')}
                variant="filled"
              />
              <TextField
                required
                margin="normal"
                value={getDefaultValue('diagram')}
                onChange={(event) => setCategoryParams({ ...categoryParams, diagram: Number(event.target.value) })}
                label={t('deviceDiagram')}
                variant="filled"
              />
              <TextField
                required
                margin="normal"
                value={getDefaultValue('radius')}
                onChange={(event) => setCategoryParams({ ...categoryParams, radius: Number(event.target.value) })}
                label={t('deviceRadius')}
                variant="filled"
              />
              <SelectField
                margin="normal"
                value={getDefaultValue('radarColor')}
                emptyValue={null}
                onChange={(event) => setCategoryParams({ ...categoryParams, radarColor: event.target.value })}
                data={Object.keys(theme.palette.tracks).map((color) => ({
                  id: theme.palette.tracks[color],
                  name: <FiberManualRecordIcon style={{ color: theme.palette.tracks[color] }} />,
                }))}
                label={t('deviceRadarColor')}
                variant="filled"
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={!!getDefaultValue('showRadarArea')}
                    onChange={
                      (e) => setCategoryParams({ ...categoryParams, showRadarArea: e.target.checked })
                    }
                  />
                )}
                label={t('deviceShowRadarArea')}
              />
              <TextField
                required
                margin="normal"
                value={getDefaultValue('externalId')}
                onChange={(event) => setCategoryParams({ ...categoryParams, externalId: Number(event.target.value) })}
                label={t('deviceExternalId')}
                variant="filled"
              />
              <TextField
                required
                margin="normal"
                value={getDefaultValue('mti')}
                onChange={(event) => setCategoryParams({ ...categoryParams, mti: Number(event.target.value) })}
                label={t('deviceMti')}
                variant="filled"
              />
              <TextField
                required
                margin="normal"
                value={getDefaultValue('ip')}
                onChange={(event) => setCategoryParams({ ...categoryParams, ip: event.target.value })}
                label={t('deviceIp')}
                variant="filled"
              />
              <TextField
                required
                margin="normal"
                value={getDefaultValue('dataPort')}
                onChange={(event) => setCategoryParams({ ...categoryParams, dataPort: Number(event.target.value) })}
                label={t('deviceDataPort')}
                variant="filled"
              />
            </AccordionDetails>
          );
        default:
          return (
            <AccordionDetails className={classes.details}>
              <TextField
                required
                margin="normal"
                value={getDefaultValue('azimuth')}
                onChange={(event) => setCategoryParams({ ...categoryParams, azimuth: Number(event.target.value) })}
                label={t('deviceAzimuth')}
                variant="filled"
              />
              <TextField
                required
                margin="normal"
                value={getDefaultValue('diagram')}
                onChange={(event) => setCategoryParams({ ...categoryParams, diagram: Number(event.target.value) })}
                label={t('deviceDiagram')}
                variant="filled"
              />
              <TextField
                required
                margin="normal"
                value={getDefaultValue('radius')}
                onChange={(event) => setCategoryParams({ ...categoryParams, radius: Number(event.target.value) })}
                label={t('deviceRadius')}
                variant="filled"
              />
              <SelectField
                margin="normal"
                value={getDefaultValue('radarColor')}
                emptyValue={null}
                onChange={(event) => setCategoryParams({ ...categoryParams, radarColor: event.target.value })}
                data={Object.keys(theme.palette.tracks).map((color) => ({
                  id: theme.palette.tracks[color],
                  name: <FiberManualRecordIcon style={{ color: theme.palette.tracks[color] }} />,
                }))}
                label={t('radarColor')}
                variant="filled"
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={!!getDefaultValue('showRadarArea')}
                    onChange={
                      (e) => setCategoryParams({ ...categoryParams, showRadarArea: e.target.checked })
                    }
                  />
                )}
                label={t('deviceShowRadarArea')}
              />
              <TextField
                required
                margin="normal"
                value={getDefaultValue('externalId')}
                onChange={(event) => setCategoryParams({ ...categoryParams, externalId: Number(event.target.value) })}
                label={t('deviceExternalId')}
                variant="filled"
              />
            </AccordionDetails>
          );
      }
    case 'anti_uav':
      switch (item.model) {
        case 'strizh3': {
          const modes = categoryParamsStructure.anti_uav.model.strizh3.fields.workMode;
          const controleModes = categoryParamsStructure.anti_uav.model.strizh3.fields.controlMode;
          const changeNumberOfHosts = (numberOfHosts) => {
            if (numberOfHosts >= 2) {
              const newCategoryParams = { ...categoryParams };
              Object.keys(categoryParams).forEach((key) => {
                ['ip', 'port', 'radius', 'azimuth'].forEach((pref) => {
                  if (key.startsWith(pref)) {
                    const num = Number(key.replace(pref, ''));
                    if (num + 1 >= numberOfHosts) {
                      delete newCategoryParams[key + num];
                    }
                  }
                });
              });
            }
            setCategoryParams({ ...categoryParams, numberOfHosts });
          };
          return (
            <AccordionDetails className={classes.details}>
              <SelectField
                margin="normal"
                value={getDefaultValue('workMode')}
                emptyValue={null}
                onChange={(event) => setCategoryParams({ ...categoryParams, workMode: event.target.value })}
                data={Object.entries(modes).filter(([mode]) => mode !== 'unknown').map(([mode, title]) => ({ id: mode, name: t(title) }))}
                label={t('deviceWorkMode')}
                variant="filled"
              />
              <TextField
                required
                margin="normal"
                value={getDefaultValue('numberOfHosts')}
                onChange={(event) => changeNumberOfHosts(Number(event.target.value))}
                label={t('deviceNumberOfHosts')}
                variant="filled"
              />
              {[...Array((getDefaultValue('numberOfHosts') ?? 0) < 2 ? 2 : getDefaultValue('numberOfHosts')).keys()].map((n) => (
                <>
                  <Typography>
                    {n === 0 ? t('deviceHostMainAntena') : `${t('deviceHost')} №${n}`}
                  </Typography>
                  <TextField
                    required
                    margin="normal"
                    value={getDefaultValue(`ip${n}`)}
                    onChange={(event) => setCategoryParams({ ...categoryParams, [`ip${n}`]: event.target.value })}
                    label={t('deviceIp0')}
                    variant="filled"
                  />
                  <TextField
                    required
                    margin="normal"
                    value={getDefaultValue(`port${n}`)}
                    onChange={(event) => setCategoryParams({ ...categoryParams, [`port${n}`]: Number(event.target.value) })}
                    label={t('devicePort0')}
                    variant="filled"
                  />
                  <TextField
                    required
                    margin="normal"
                    value={getDefaultValue(`radius${n}`)}
                    onChange={(event) => setCategoryParams({ ...categoryParams, [`radius${n}`]: Number(event.target.value) })}
                    label={t('deviceRadius0')}
                    variant="filled"
                  />
                  <TextField
                    required
                    margin="normal"
                    value={getDefaultValue(`azimuth${n}`)}
                    onChange={(event) => setCategoryParams({ ...categoryParams, [`azimuth${n}`]: Number(event.target.value) })}
                    label={t('deviceAzimuth0')}
                    variant="filled"
                  />
                  <Divider />
                </>
              ))}
              <SelectField
                margin="normal"
                value={getDefaultValue('controlMode')}
                emptyValue={null}
                onChange={(event) => setCategoryParams({ ...categoryParams, controlMode: event.target.value })}
                data={Object.entries(controleModes).map(([mode, title]) => ({ id: mode, name: t(title) }))}
                label={t('deviceControlMode')}
                variant="filled"
              />
            </AccordionDetails>
          );
        }
        default: {
          const modes = categoryParamsStructure.anti_uav.model.default.fields.workMode;
          return (
            <AccordionDetails className={classes.details}>
              <SelectField
                margin="normal"
                value={getDefaultValue('workMode')}
                emptyValue={null}
                onChange={(event) => setCategoryParams({ ...categoryParams, workMode: event.target.value })}
                data={Object.entries(modes).map(([mode, title]) => ({ id: mode, name: t(title) }))}
                label={t('deviceWorkMode')}
                variant="filled"
              />
            </AccordionDetails>
          );
        }
      }
    default:
      return null;
  }
};

export default DeviceCategoryFields;

DeviceCategoryFields.propTypes = {
  item: PropTypes.object.isRequired,
  categoryParams: PropTypes.object.isRequired,
  setCategoryParams: PropTypes.func.isRequired,
};
