import React from 'react';
import {
  Drawer, makeStyles, Divider,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    // Первый блок заголовка.
    '& > div:first-child p': {
      fontWeight: 'bold',
    },
    '& hr': {
      marginRight: '-10px',
      marginLeft: '-10px',
    },
    position: 'fixed',
    padding: '0 10px',
    [theme.breakpoints.up('sm')]: {
      width: '390px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '300px',
      maxHeight: '50%',
      bottom: '0',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: 'calc(100% - 56px)',
    },
    overflow: 'hidden',
  },
  drawerHeader: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    marginBottom: '10px',
  },
  upper: {
    zIndex: 1201,
  },
}));

const SidePanel = ({
  bodyContent, headerContent, drawerStyle, upper,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Drawer
      style={drawerStyle}
      variant="permanent"
      anchor={!isTablet ? 'right' : 'bottom'}
      classes={{ paper: `${classes.root} ${upper ? classes.upper : ''}` }}
    >
      <div className={classes.drawerHeader}>
        {headerContent}
      </div>

      <Divider className={classes.divider} />

      {bodyContent}
    </Drawer>
  );
};

export default SidePanel;
