import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { formatDate, getSortedArray } from '../../common/utils/formatter';
import usePersistedState from '../../common/utils/usePersistedState';
import logout from '../../common/utils/logout';
import ReportTemplate from './components/ReportTemplate';
import { prefixString } from '../../common/utils/stringUtils';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { useEffectAsync } from '../../common/utils/reactHelper';

const typeReport = 'logReport';

const columnsArray = [
  ['level', 'reportLevel', 0],
  ['deviceId', 'reportDevice', 1],
  ['logTime', 'reportLogTime', 1],
  ['protocol', 'reportProtocol', 1],
  ['direction', 'reportDirection', 1],
  ['remoteAddress', 'reportRemoteAddress', 1],
  ['message', 'reportMessage', 0],
];

const typesSorting = {
  logTime: (a, b) => getSortedArray(a.logTime, b.logTime),
  logTimeReverse: (a, b) => getSortedArray(a.logTime, b.logTime, true),
  deviceId: (a, b) => getSortedArray(a.deviceId, b.deviceId),
  deviceIdReverse: (a, b) => getSortedArray(a.deviceId, b.deviceId, true),
  protocol: (a, b) => getSortedArray(a.protocol, b.protocol),
  protocolReverse: (a, b) => getSortedArray(a.protocol, b.protocol, true),
  direction: (a, b) => getSortedArray(a.direction, b.direction),
  directionReverse: (a, b) => getSortedArray(a.direction, b.direction, true),
  remoteAddress: (a, b) => getSortedArray(a.remoteAddress, b.remoteAddress),
  remoteAddressReverse: (a, b) => getSortedArray(a.remoteAddress, b.remoteAddress, true),
};

const LogReportPage = () => {
  const [offColumns, setOffColumns] = usePersistedState(typeReport, []);
  const t = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [devicesList, setDevicesList] = useState([]);
  const [devicesObject, setDevicesObject] = useState({});
  const [progress, setProgress] = useState(0);
  const [progressDevices, setProgressDevices] = useState(true);
  const [page, setPage] = useState(0);

  const columnsArrayFiltered = columnsArray.filter((column) => !offColumns.includes(column[0]));

  const handleSubmit = async (_, from, to, headers) => {
    setProgress(1);
    const query = new URLSearchParams({
      from, to,
    });
    columnsArrayFiltered.forEach((it) => query.append('column', it[0]));
    const response = await fetch(`/api/reports/logs?${query.toString()}`, { headers });
    if (response.ok) {
      const contentType = response.headers.get('content-type');
      if (contentType) {
        if (contentType === 'application/json') {
          setPage(0);
          const result = await response.json();
          setItems(result);
        } else {
          window.location.assign(window.URL.createObjectURL(await response.blob()));
        }
      }
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
    setProgress(100);
    setProgress(0);
  };

  const formatValue = (item, key, devices) => {
    switch (key) {
      case 'logTime':
        return formatDate(item[key]);
      case 'deviceId':
        return devices[item[key]]?.name;
      case 'direction':
        if (item[key]) {
          return t(prefixString('sms', item[key]));
        }
        return '';
      case 'level':
        return t(prefixString('reportLogLevel', item[key]));
      default:
        return item[key];
    }
  };

  useEffectAsync(async () => {
    const response = await fetch('/api/devices?object=true');
    if (response.ok) {
      const result = await response.json();
      setDevicesList(Object.values(result));
      setDevicesObject(result);
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
    setProgressDevices(false);
  }, []);

  return (
    <ReportTemplate
      items={items}
      progress={progress}
      progressDevices={progressDevices}
      columnsArray={columnsArray}
      handleSubmit={handleSubmit}
      typeSortingDefault="logTime"
      formatValue={formatValue}
      page={page}
      setPage={setPage}
      typesSorting={typesSorting}
      typeReport={typeReport}
      offColumns={offColumns}
      setOffColumns={setOffColumns}
      columnsArrayFiltered={columnsArrayFiltered}
      breadcrumbs={['reportTitle', 'logReports']}
      devicesList={devicesList}
      devicesObject={devicesObject}
      withoutDevices
    />
  );
};

export default LogReportPage;
