import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { formatDate, getSortedArray } from '../../common/utils/formatter';
import { prefixString } from '../../common/utils/stringUtils';
import { useTranslation } from '../../common/components/LocalizationProvider';
import usePersistedState from '../../common/utils/usePersistedState';
import logout from '../../common/utils/logout';
import ReportTemplate from './components/ReportTemplate';
import { useEffectAsync } from '../../common/utils/reactHelper';

const typeReport = 'eventReport';

const columnsArray = [
  ['deviceId', 'reportDevice', 1],
  ['group', 'reportGroup', 1],
  ['eventTime', 'reportEventTime', 1],
  ['type', 'reportType', 0],
  ['geofenceId', 'reportGeofence', 0],
  ['message', 'reportMessage', 0],
];

const typesSorting = {
  deviceId: (a, b) => getSortedArray(a.deviceId, b.deviceId),
  deviceIdReverse: (a, b) => getSortedArray(a.deviceId, b.deviceId, true),
  group: (a, b) => getSortedArray(a.groupId, b.groupId),
  groupReverse: (a, b) => getSortedArray(a.groupId, b.groupId, true),
  eventTime: (a, b) => getSortedArray(a.eventTime, b.eventTime),
  eventTimeReverse: (a, b) => getSortedArray(a.eventTime, b.eventTime, true),
};

const events = {
  // allEvents: 'eventAll',
  deviceOnline: 'eventDeviceOnline',
  deviceUnknown: 'eventDeviceUnknown',
  deviceOffline: 'eventDeviceOffline',
  deviceInactive: 'eventDeviceInactive',
  deviceMoving: 'eventDeviceMoving',
  deviceStopped: 'eventDeviceStopped',
  geofenceEnter: 'eventGeofenceEnter',
  geofenceExit: 'eventGeofenceExit',
  textMessage: 'eventTextMessage',
  alarm: 'eventAlarm',
};

const EventReportPage = () => {
  const t = useTranslation();
  const [offColumns, setOffColumns] = usePersistedState(typeReport, []);

  const geofences = useSelector((state) => state.geofences.items);
  const groups = useSelector((state) => state.groups.items);
  const history = useHistory();
  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const [devicesList, setDevicesList] = useState([]);
  const [devicesObject, setDevicesObject] = useState({});

  const [progress, setProgress] = useState(0);
  const [progressDevices, setProgressDevices] = useState(true);
  const [eventTypes, setEventTypes] = useState([]);
  const [page, setPage] = useState(0);

  const columnsArrayFiltered = columnsArray.filter((column) => !offColumns.includes(column[0]));

  const handleSubmit = async (deviceId, from, to, headers) => {
    setProgress(1);
    const query = new URLSearchParams({
      from, to,
    });
    columnsArrayFiltered.forEach((it) => query.append('column', it[0]));
    deviceId.forEach((it) => query.append('deviceId', it));
    eventTypes.forEach((it) => query.append('type', it));
    const response = await fetch(`/api/reports/events?${query.toString()}`, { headers });
    if (response.ok) {
      const contentType = response.headers.get('content-type');
      if (contentType) {
        if (contentType === 'application/json') {
          setPage(0);
          setItems(await response.json());
        } else {
          window.location.assign(window.URL.createObjectURL(await response.blob()));
        }
      }
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
    setProgress(100);
    setProgress(0);
  };

  const chooseEvents = (values) => {
    if (values[values.length - 1] === 'all') {
      if (eventTypes.length) {
        setEventTypes([]);
      } else {
        setEventTypes(Object.keys(events));
      }
    } else {
      setEventTypes(values.filter((v) => v));
    }
  };

  const formatGeofence = (value) => {
    if (value > 0) {
      const geofence = geofences[value];
      return geofence ? geofence.name : '';
    }
    return '';
  };

  const formatValue = (item, key, devices) => {
    switch (key) {
      case 'eventTime':
        return formatDate(item[key]);
      case 'deviceId':
        return devices[item[key]]?.name;
      case 'group':
        return groups[devices[item.deviceId].groupId]?.name || '';
      case 'geofenceId':
        return formatGeofence(item[key]);
      case 'type':
        return t(prefixString('event', item[key]));
      case 'success':
        return item[key] ? '' : t('reportSmsNoSuccess');
      case 'message':
        return item.attributes[key];
      default:
        return item[key];
    }
  };

  useEffectAsync(async () => {
    const response = await fetch('/api/devices?object=true');
    if (response.ok) {
      const result = await response.json();
      setDevicesList(Object.values(result));
      setDevicesObject(result);
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
    setProgressDevices(false);
  }, []);

  return (
    <ReportTemplate
      items={items}
      progress={progress}
      progressDevices={progressDevices}
      columnsArray={columnsArray}
      handleSubmit={handleSubmit}
      typeSortingDefault="eventTime"
      formatValue={formatValue}
      page={page}
      setPage={setPage}
      typesSorting={typesSorting}
      typeReport={typeReport}
      offColumns={offColumns}
      setOffColumns={setOffColumns}
      columnsArrayFiltered={columnsArrayFiltered}
      events={events}
      eventTypes={eventTypes}
      chooseEvents={chooseEvents}
      breadcrumbs={['reportTitle', 'reportEvents']}
      devicesList={devicesList}
      devicesObject={devicesObject}
    />
  );
};

export default EventReportPage;
