import React, { useState, useEffect } from 'react';
import {
  Button, ButtonGroup, makeStyles, Paper, TextField,
} from '@material-ui/core';
import { useTranslation } from './LocalizationProvider';
import markerStatus from '../constants/markerStatus';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto',
    width: 'max-content',
    opacity: 0.8,
  },
  text: {
    padding: '1px 10px',
    textAlign: 'center',
  },
  formControl: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textField: {
    width: 110,
  },
  space: {
    marginLeft: 5,
  },
}));

const TextMarkerCoordinates = ({
  coordinates, setNewMarkerCoordinates, markerState, setMoveableMarker, setAddMarkerMode, clearMarkerState,
}) => {
  const classes = useStyles();
  const t = useTranslation();

  const [currentCoordinates, setCurrentCoordinates] = useState(coordinates);

  const isMove = markerState.current.actionType === markerStatus.Move;

  useEffect(() => {
    setCurrentCoordinates({ ...coordinates });
  }, [coordinates]);

  const handleOnChangeLatitude = (event) => {
    const lat = parseFloat(event.target.value);
    setCurrentCoordinates((prevCoordinates) => ({ ...prevCoordinates, lat }));
  };

  const handleOnChangeLength = (event) => {
    const lng = parseFloat(event.target.value);
    setCurrentCoordinates((prevCoordinates) => ({ ...prevCoordinates, lng }));
  };

  const handleOnClickCancel = (event) => {
    event.preventDefault();

    if (markerState.current.actionType === markerStatus.Create) {
      setAddMarkerMode(false);
    } else {
      setMoveableMarker(null);
    }

    clearMarkerState();
  };

  const handleOnClickMove = (event) => {
    event.preventDefault();
    setNewMarkerCoordinates({ ...currentCoordinates });
    markerState.current = { ...markerState.current, lngLat: { ...currentCoordinates } };
  };

  return (
    <Paper className={classes.root}>
      {markerState.current.actionType && (
        <form className={classes.formControl} noValidate autoComplete="off">
          <div>
            <TextField
              type="number"
              className={classes.textField}
              label={t('markerLatitude')}
              value={currentCoordinates.lng.toFixed(5)}
              onChange={handleOnChangeLatitude}
            />
            <TextField
              type="number"
              className={`${classes.textField} ${classes.space}`}
              label={t('markerLongitude')}
              value={currentCoordinates.lat.toFixed(5)}
              onChange={handleOnChangeLength}
            />
          </div>
          <div>
            <ButtonGroup color="primary" size="small">
              <Button onClick={handleOnClickCancel}>{t('sharedCancel')}</Button>
              <Button onClick={handleOnClickMove}>
                {isMove ? (<>{t('sharedMove')}</>) : (<>{t('panelMarkerAdd')}</>)}
              </Button>
            </ButtonGroup>
          </div>
        </form>
      )}
    </Paper>
  );
};

export default TextMarkerCoordinates;
