import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {
  Grid,
} from '@material-ui/core';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { getStatusColor } from '../../../utils/formatter';
import { ICON_NAME_SEPARATOR } from '../constants';

const ColorsGroupCellRender = ({
  dataset, theme,
}) => (
  <FiberManualRecordIcon style={{ color: dataset.value ?? theme.palette.tracks.replay0 }} />
);

const StatusGroupCellRender = ({
  dataset, deviceListClasses,
}) => (
  <Brightness1Icon className={`${deviceListClasses[getStatusColor(dataset.value)]} ${deviceListClasses.brightness}`} />
);

const TextIconGroupCellRender = ({
  dataset,
}) => {
  const [text, icon] = dataset.value.split(ICON_NAME_SEPARATOR);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <img src={`/images/icon/device/${icon ?? 'default'}.svg`} alt="" />
      </Grid>
      <Grid item>
        {text === 'undefined' ? '' : text}
      </Grid>
    </Grid>
  );
};

const IconGroupCellRender = ({
  dataset,
}) => (
  <Grid container alignItems="center" spacing={2}>
    <Grid item>
      <img src={`/images/icon/device/${dataset.value || 'default'}.svg`} alt="" />
    </Grid>
  </Grid>
);

export {
  ColorsGroupCellRender,
  StatusGroupCellRender,
  TextIconGroupCellRender,
  IconGroupCellRender,
};
