import React from 'react';
import VirtualDeviceRow from './VirtualDeviceRow';

const VirtualBodyRow = ({
  style, device, onMenuClick, handleClose, onTail, progress, groups, closedDevices,
  chosenDevice, deviceToolOff, now, setNow, changeVisibleDevice, clickItem, textWidthClass,
}) => (
  <VirtualDeviceRow
    style={style}
    data={{
      onMenuClick,
      handleClose,
      onTail,
      progress,
      groups,
      closedDevices,
      chosenDevice,
      deviceToolOff,
      now,
      setNow,
      changeVisibleDevice,
      clickItem,
      textWidthClass,
    }}
    item={device}
  />
);

export default VirtualBodyRow;
