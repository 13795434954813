import { createSlice } from '@reduxjs/toolkit';
import { getFrom } from '../common/utils/formatter';

const { reducer, actions } = createSlice({
  name: 'tail',
  initialState: {
    stateSyncTail: new Date().getTime(),
    tail: JSON.parse(window.localStorage.getItem('tailOn')) ?? false,
    working: false,
    positions: {},
    positionsPath: {},
    positionsSimilar: {},
    positionsStop: {},
    pathMatching: {},
  },
  reducers: {
    turnWorking(state, action) {
      state.working = action.payload;
    },
    turnTail(state, action) {
      state.tail = action.payload;
      window.localStorage.setItem('tailOn', action.payload);
      if (!action.payload) {
        state.positions = {};
        state.positionsPath = {};
        state.positionsSimilar = {};
        state.positionsStop = {};
        state.pathMatching = {};
        state.stateSyncTail = new Date().getTime();
      }
    },
    // changePeriod(state, action) {
    //   state.period = action.payload;
    // },
    init(state, action) {
      if (state.tail && state.working) {
        state.positions = { ...state.positions, ...action.payload.positions };
        state.positionsPath = { ...state.positionsPath, ...action.payload.positionsPath };
        state.positionsSimilar = { ...state.positionsSimilar, ...action.payload.positionsSimilar };
        state.positionsStop = { ...state.positionsStop, ...action.payload.positionsStop };
        state.stateSyncTail = new Date().getTime();
      }
    },
    clear(state) {
      state.positions = {};
      state.positionsPath = {};
      state.positionsSimilar = {};
      state.positionsStop = {};
      state.pathMatching = {};
      state.stateSyncTail = new Date().getTime();
    },
    add(state, action) {
      const check = (current, arrPositions, panel) => current?.longitude && !(arrPositions.length && arrPositions[arrPositions.length - 1].fixTime === current.fixTime);

      if (state.tail && state.working) {
        action.payload.forEach((item) => {
          const { deviceId } = item;
          if (!state.positions[deviceId]) {
            state.positions[deviceId] = [];
          }
          if (check(item, state.positions[deviceId])) {
            if (state.positionsSimilar[deviceId]) {
              const similarKey = `${item.longitude}-${item.longitude}`;
              const similar = state.positionsSimilar[deviceId];
              state.positionsSimilar[deviceId][similarKey] = similar[similarKey] ? [...similar[similarKey], item] : [item];
            }
            state.positions[deviceId].push(item);
          }

          if (!state.positionsPath[deviceId]) {
            state.positionsPath[deviceId] = [];
          }
          if (check(item, state.positionsPath[deviceId])) {
            state.positionsPath[deviceId].push(item);
          }

          if (!state.pathMatching[deviceId]) {
            state.pathMatching[deviceId] = [];
          }
          state.pathMatching[deviceId].push(item);
        });
        state.stateSyncTail = new Date().getTime();
      }
    },
    clean(state, action) {
      const selectedFrom = getFrom(action.payload);

      const cleanTail = (positionsF) => {
        let cleanedPositions = [];
        for (let i = 0; i < positionsF.length; i += 1) {
          if ((positionsF[i]?.fixTime ?? positionsF[i]?.endTime) >= selectedFrom.toISOString()) {
            cleanedPositions = positionsF.slice(i);
            break;
          }
        }
        return cleanedPositions;
      };

      const iterate = (sP) => Object.entries(sP).forEach(([deviceId, positions]) => sP[deviceId] = cleanTail(positions));

      if (state.tail && state.working) {
        iterate(state.positions);
        iterate(state.positionsSimilar);
        iterate(state.positionsStop);
        iterate(state.positionsPath);
        iterate(state.pathMatching);
        state.stateSyncTail = new Date().getTime();
      }
    },
    hideOpen(state) {
      state.stateSyncTail = { name: 'hideOpen', state: new Date().getTime() };
    },
    remove(state, action) {
      if (state.tail && state.working) {
        action.payload.forEach((deviceId) => {
          delete state.positions[deviceId];
          delete state.positionsPath[deviceId];
          delete state.pathMatching[deviceId];
          delete state.positionsSimilar[deviceId];
          delete state.positionsStop[deviceId];
        });
        state.stateSyncTail = new Date().getTime();
      }
    },
    changePathMatching(state, action) {
      state.pathMatching = action.payload;
    },
  },
});

export { actions as tailActions };
export { reducer as tailReducer };
