import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import ClosedDeviceInitialization from './ClosedDeviceInitialization';
import {
  bsActions, devicesActions, geofencesActions, groupsActions, markersActions,
  positionsActions, sessionActions, smsActions, tailActions, unionsActions,
} from './store';
import { useCatch, useEffectAsync } from './common/utils/reactHelper';
import logout from './common/utils/logout';
import extraLayersItems from './common/components/panel/extraLayersItems';
import getExtraLayers from './map/funcs/getExtraLayers';
import { filterCurrent } from './common/utils/formatter';

const CachingController = () => {
  const authenticated = useSelector((state) => !!state.session.user);
  const user = useSelector((state) => state.session.user);
  const server = useSelector((state) => state.session.server);
  const bscoderUrl = server?.attributes.services?.bscoder?.url;
  const dispatch = useDispatch();
  const history = useHistory();
  const devices = useSelector((state) => Object.values(state.devices.items));
  const stateSyncDevices = useSelector((state) => state.devices.stateSyncDevices);
  const closedDevices = useSelector((state) => state.devices.closedDevices);
  const stateSyncClosedDevices = useSelector((state) => state.devices.stateSyncClosedDevices);
  const categories = useSelector((state) => state.devices.categories);

  const extraLayersPanel = useSelector((state) => state.extraLayers.panel);

  const [groupInited, setGroupInited] = useState(false);

  const [closedDevicesInited, setClosedDevicesInited] = useState(false);

  const devicesRef = useRef(devices);
  const closedDevicesRef = useRef(closedDevices);
  const categoriesRef = useRef(categories);

  useEffect(() => {
    devicesRef.current = devices;
  }, [stateSyncDevices]);

  useEffect(() => {
    closedDevicesRef.current = closedDevices;
  }, [stateSyncClosedDevices]);

  useEffect(() => {
    categoriesRef.current = categories;
  }, [categories]);

  useEffectAsync(async () => {
    if (authenticated) {
      let response;
      if (user.administrator) {
        response = await fetch('/api/unions?all=true');
      } else {
        response = await fetch(`/api/unions?userId=${user.id}`);
      }
      if (response.ok) {
        dispatch(unionsActions.update(await response.json()));
      } else if (response.status === 401) {
        dispatch(sessionActions.updateUser(null));
      }
    }
  }, [authenticated, user]);

  useEffectAsync(async () => {
    if (authenticated) {
      let response;
      if (user.administrator) {
        response = await fetch('/api/groups?all=true');
      } else {
        response = await fetch(`/api/groups?userId=${user.id}`);
      }
      if (response.ok) {
        dispatch(groupsActions.update(await response.json()));
        setGroupInited(true);
      } else if (response.status === 401) {
        dispatch(sessionActions.updateUser(null));
      }
    }
  }, [authenticated, user]);

  useEffectAsync(async () => {
    if (authenticated) {
      const response = await fetch('/api/geofences');
      if (response.ok) {
        dispatch(geofencesActions.update(await response.json()));
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
    }
  }, [authenticated]);

  useEffectAsync(async () => {
    if (authenticated) {
      let response;
      if (user.administrator) {
        response = await fetch('/api/markers?all=true');
      } else {
        response = await fetch(`/api/markers?userId=${user.id}`);
      }
      if (response.ok) {
        dispatch(markersActions.update(await response.json()));
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
    }
  }, [authenticated]);

  useEffectAsync(async () => {
    if (authenticated) {
      const response = await fetch('/api/sms/new');
      if (response.ok) {
        dispatch(smsActions.update(await response.json()));
        dispatch(smsActions.updateNewSmsInitialized(true));
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
    }
  }, [authenticated]);

  useEffectAsync(async () => {
    if (authenticated && bscoderUrl) {
      try {
        const response = await fetch('/api/bscoder');
        if (response.ok) {
          dispatch(bsActions.update(true));
        } else if (response.status === 401) {
          logout(history, dispatch);
        }
      } catch {
        console.warn('BSCoder сервис недоступен');
      }
    }
  }, [authenticated, bscoderUrl]);

  useEffectAsync(async () => {
    if (authenticated) {
      const response = await fetch('/api/server/date_expiration');
      if (response.ok) {
        dispatch(sessionActions.updateTimeExpiration(await response.text()));
      }
    }
  }, [authenticated]);

  const handleExtraLayer = useCatch(async (button) => getExtraLayers(button, dispatch, extraLayersPanel, true));

  useEffect(() => {
    extraLayersItems.forEach((layer) => {
      handleExtraLayer(layer, dispatch, extraLayersPanel, true);
    });
  }, []);

  useEffect(() => {
    const clearDevicesInterval = setInterval(() => {
      const removedDevices = [];
      devicesRef.current.forEach((device) => {
        if (!filterCurrent(device, server)) {
          removedDevices.push(device.id);
        }
      });
      dispatch(devicesActions.remove(removedDevices));
      dispatch(positionsActions.remove(removedDevices));
      dispatch(tailActions.remove(removedDevices));
    }, 10000);
    return () => {
      clearInterval(clearDevicesInterval);
    };
  }, [server]);

  if (!closedDevicesInited) {
    return (
      <ClosedDeviceInitialization
        closedDevicesInited={closedDevicesInited}
        setClosedDevicesInited={setClosedDevicesInited}
        groupInited={groupInited}
      />
    );
  }

  return null;
};

export default connect()(CachingController);
