import React, {
  useCallback, useRef, useState,
} from 'react';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import Box, {
  Item,
} from 'devextreme-react/box';
import {
  Button,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  Popup, RadioGroup, SelectBox, TextBox,
} from 'devextreme-react';
import { useTranslation } from '../LocalizationProvider';

const filesFormats = Object.freeze({
  Csv: 'csv',
  Xlsx: 'xlsx',
});

const ExportForm = ({
  isShowExportingForm, dataGridRef,
  closeExportingForm, fileExportingName,
}) => {
  const t = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const exportDataSettings = useRef({
    fileName: t(fileExportingName) || '',
    fileFormat: filesFormats.Csv,
    isExportAllRows: true,
    delimiter: ';',
    availableFilesFormats: Object.values(filesFormats),
    availableSelectFormats: ['All', 'Selected'],
  });

  const [selectedFileFormat, setSelectedFileFormat] = useState(exportDataSettings.current.fileFormat);

  const processAndSaveExportingFile = useCallback(async () => {
    if (!exportDataSettings.current.fileName) {
      // eslint-disable-next-line no-alert
      alert(t('devextremeFileNameIsRequired'));
      return;
    }

    let buffer;
    const workbook = new Workbook();
    const currentWorksheet = workbook.addWorksheet(exportDataSettings.current.fileName);

    await exportDataGrid({
      component: dataGridRef.current.instance,
      worksheet: currentWorksheet,
      encodeExecutableContent: true,
      autoFilterEnabled: true,
      selectedRowsOnly: !exportDataSettings.current.isExportAllRows,
    });

    if (exportDataSettings.current.fileFormat === filesFormats.Csv) {
      buffer = await workbook.csv.writeBuffer({
        formatterOptions: {
          delimiter: exportDataSettings.current.delimiter,
        },
      });
    } else if (exportDataSettings.current.fileFormat === filesFormats.Xlsx) {
      buffer = await workbook.xlsx.writeBuffer();
    } else {
      console.log('Не найден указанный формат.');
      return;
    }

    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `${exportDataSettings.current.fileName}.${exportDataSettings.current.fileFormat}`,
    );

    closeExportingForm();
  }, []);

  return (
    <Popup
      visible={isShowExportingForm}
      maxWidth={500}
      fullScreen={isMobile}
      height="auto"
      position="center"
      title={t('GlobalDataExport')}
      showTitle
      shadingColor="rgba(80 80 80 / 10%)"
      dragEnabled={false}
      closeOnOutsideClick
      onHiding={closeExportingForm}
    >
      <Box>
        <Item ratio={1}>
          <div className="dx-field">
            <div className="dx-field-label">{t('devextremeFileFormat')}</div>
            <div className="dx-field-value">
              <SelectBox
                items={exportDataSettings.current.availableFilesFormats}
                defaultValue={exportDataSettings.current.fileFormat}
                placeholder={t('devextremeFileFormat')}
                onValueChange={(format) => {
                  exportDataSettings.current = { ...exportDataSettings.current, fileFormat: format };
                  setSelectedFileFormat(format);
                }}
              />
            </div>
          </div>
        </Item>
      </Box>
      <Box align="space-between">
        <Item ratio={1}>
          <div className="dx-field">
            <div className="dx-field-label">{t('devextremeFileName')}</div>
            <div className="dx-field-value">
              <TextBox
                placeholder={t('devextremeFileName')}
                defaultValue={exportDataSettings.current.fileName}
                onValueChange={(name) => {
                  exportDataSettings.current = { ...exportDataSettings.current, fileName: name };
                }}
              />
            </div>
          </div>
        </Item>
      </Box>
      {selectedFileFormat === filesFormats.Csv && (
        <Box>
          <Item ratio={1}>
            <div className="dx-field">
              <div className="dx-field-label">{t('devextremeDelimiter')}</div>
              <div className="dx-field-value">
                <TextBox
                  placeholder={`${t('devextremeDelimiterPlaceholder')} , | ;`}
                  defaultValue={exportDataSettings.current.delimiter}
                  onValueChange={(value) => {
                    exportDataSettings.current = { ...exportDataSettings.current, delimiter: value };
                  }}
                />
              </div>
            </div>
          </Item>
        </Box>
      )}
      <Box style={{ marginTop: 20 }}>
        <Item ratio={1}>
          <div className="dx-field">
            <div className="dx-field-label" style={{ textWrap: 'wrap' }}>{t('devextremeExportRow')}</div>
            <div className="dx-field-value">
              <RadioGroup
                items={exportDataSettings.current.availableSelectFormats}
                defaultValue={exportDataSettings.current.availableSelectFormats[0]}
                itemRender={(text) => t(`GlobalExport${text}`)}
                layout="horizontal"
                onValueChange={(e) => {
                  exportDataSettings.current = { ...exportDataSettings.current, isExportAllRows: e === exportDataSettings.current.availableSelectFormats[0] };
                }}
              />
            </div>
          </div>
        </Item>
      </Box>
      <Box style={{ marginTop: 20 }} align="space-between">
        <Item ratio={1}>
          <Button variant="text" color="default" onClick={closeExportingForm}>{t('closeTitle')}</Button>
        </Item>
        <Item ratio={1} />
        <Item ratio={1}>
          <Button variant="outlined" color="default" onClick={processAndSaveExportingFile}>
            {t('sharedSave')}
          </Button>
        </Item>
      </Box>
    </Popup>
  );
};

export default ExportForm;
