import React, { useState } from 'react';
import { Typography, Tooltip, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteIcon from '@material-ui/icons/Delete';
import LaunchIcon from '@material-ui/icons/Launch';
import EditIcon from '@material-ui/icons/Edit';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import { useDispatch } from 'react-redux';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { positionsActions } from '../../store';

const MarkerHeader = ({
  setEdit, setOpenConfirmDelete, handleMove, setPositionDataVisible,
  setPositionsBS, setNextPosition, isSmall, deletePopup,
}) => {
  const t = useTranslation();
  const dispatch = useDispatch();

  const [openExpandTooltip, setOpenExpandTooltip] = useState(false);

  const handleExpandPanelClick = () => {
    setOpenExpandTooltip(false);
    dispatch(positionsActions.updatePopupData(false));
  };

  return (
    <>
      <Typography variant={isSmall ? 'body1' : 'h6'} color="inherit" noWrap style={{ width: '100%' }}>
        {`${t('settingsMarker')}`}
      </Typography>

      <Tooltip title={t('sharedRemove')}>
        <IconButton
          size={isSmall && 'small'}
          onClick={() => setOpenConfirmDelete(true)}
        >
          <DeleteIcon style={{ color: '#cc0000' }} />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('sharedMove')}>
        <IconButton size={isSmall && 'small'} onClick={() => handleMove()}>
          <OpenWithIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('sharedEdit')}>
        <IconButton size={isSmall && 'small'} onClick={() => setEdit(true)}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('collapseTitle')}>
        <IconButton size={isSmall && 'small'} onClick={() => setPositionDataVisible(false)}>
          <RemoveIcon />
        </IconButton>
      </Tooltip>

      {isSmall ? (
        <Tooltip
          title={t('expandPanelTitle')}
          open={openExpandTooltip}
          onOpen={() => setOpenExpandTooltip(true)}
          onClose={() => setOpenExpandTooltip(false)}
        >
          <IconButton size="small" onClick={handleExpandPanelClick}>
            <ZoomOutMapIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={t('shrinkPanelTitle')}>
          <IconButton onClick={() => dispatch(positionsActions.updatePopupData(true))}>
            <LaunchIcon />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title={t('closeTitle')}>
        <IconButton
          size={isSmall && 'small'}
          onClick={() => {
            dispatch(positionsActions.unselectPosition());
            setNextPosition(null);
            setPositionsBS([]);
            if (deletePopup.current) {
              deletePopup.current();
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default MarkerHeader;
