import React from 'react';
import {
  Divider,
  Grid, Paper, Switch, Typography, makeStyles,
} from '@material-ui/core';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useSelector } from 'react-redux';
import { useTranslation } from '../LocalizationProvider';
import { formatPosition, toTitleCase } from '../../utils/formatter';
import scrollStyles from '../../theme/scrollStyles';

const useStyles = makeStyles((theme) => ({
  ...theme.palette.colors,
  listItemLong: {
    width: '100%',
  },
  smallText: {
    fontSize: 12,
  },
  middleText: {
    fontSize: 14,
  },
  textRed: {
    color: theme.palette.common.red,
  },
  subtitle: {
    width: '100%',
    textAlign: 'center',
  },
  scroll: {
    ...scrollStyles(5),
  },
}));

const Data = ({
  data, off, includeProperties, redFields, speedUnit, openChoice, rootClass, size,
}) => {
  const classes = useStyles();
  const t = useTranslation();
  const selectedPositionData = useSelector((state) => state.positions.selectedPositionData);

  const getTextClass = () => {
    switch (size) {
      case 'small':
        return classes.smallText;
      default:
        return classes.middleText;
    }
  };

  const checkProperty = (property) => {
    if (!off.includes(`${property}`)
      && property !== 'extraAttributes'
      && property !== 'categoryParams'
      && (![undefined, false].includes(selectedPositionData[property]) || data[property].value)) {
      return true;
    }
    return false;
  };

  return (
    <Paper className={rootClass} elevation={0} square>
      <AutoSizer style={{ maxHeight: '100%', maxWidth: '100%' }}>
        {({ height, width }) => (
          <Grid
            style={{
              height,
              width,
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
            className={classes.scroll}
            container
            direction="column"
          >
            {openChoice ? (
              <Grid container item>
                <Grid container item direction="column">
                  {Object.keys(data).map((property) => (
                    <Grid container item key={property} className={classes.listItemLong} alignItems="center">
                      <Grid item xs="auto">
                        <Typography className={getTextClass()}>{data[property].title}</Typography>
                      </Grid>
                      <Grid item xs style={{ textAlign: 'right' }}>
                        <Switch
                          checked={!off.includes(property)}
                          onClick={() => includeProperties(property)}
                          size={size || 'medium'}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) : (
              <Grid container item>
                <Grid container item direction="column" spacing={size === 'small' ? 0 : 1}>
                  {Object.keys(data).filter((property) => checkProperty(property)).map((property) => (
                    <Grid container item key={property} spacing={2} className={classes.listItemLong}>
                      <Grid item xs>
                        <Typography className={`${getTextClass()} ${redFields.includes(property) && classes.textRed}`}>
                          {data[property].title}
                        </Typography>
                      </Grid>
                      <Grid item xs className={`${redFields.includes(property) && classes.textRed}`} style={{ textAlign: 'right' }}>
                        {data[property].value || formatPosition(selectedPositionData[property], `${property}`, t, speedUnit)}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                {!!(!off.includes('categoryParams') && selectedPositionData.categoryParams) && (
                  <Grid container item direction="column">
                    <Grid item>
                      <Divider />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle2" color="inherit" className={classes.subtitle}>
                        {t('sharedCategoryParams')}
                      </Typography>
                    </Grid>
                    <Grid container item direction="column" spacing={size === 'small' ? 0 : 1}>
                      {Object.keys(selectedPositionData.categoryParams).filter((attribute) => attribute !== 'type').map((attribute) => (
                        <Grid container item key={attribute} className={classes.listItemLong} spacing={2}>
                          <Grid item xs>
                            <Typography className={getTextClass()}>{t(`position${toTitleCase(attribute)}`)}</Typography>
                          </Grid>
                          <Grid item xs style={{ textAlign: 'right' }}>
                            {formatPosition(selectedPositionData.categoryParams[attribute], `${attribute}`, t, speedUnit)}
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
                {!!(!off.includes('extraAttributes') && selectedPositionData.extraAttributes) && (
                  <Grid container item direction="column">
                    <Grid item>
                      <Divider />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle2" color="inherit" className={classes.subtitle}>
                        {t('sharedExtraAttributes')}
                      </Typography>
                    </Grid>
                    <Grid container item direction="column" spacing={size === 'small' ? 0 : 1}>
                      {Object.keys(selectedPositionData.extraAttributes).map((attribute) => (
                        <Grid container item key={attribute} className={classes.listItemLong} spacing={2}>
                          <Grid item xs>
                            <Typography className={getTextClass()}>{attribute}</Typography>
                          </Grid>
                          <Grid item xs style={{ textAlign: 'right' }}>
                            {selectedPositionData.extraAttributes[attribute]}
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        )}
      </AutoSizer>
    </Paper>
  );
};

export default Data;
