import React, { useEffect, useState } from 'react';
import {
  Paper, Button, ButtonGroup, Switch, Tooltip,
} from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useDispatch, useSelector } from 'react-redux';
import LibraryAddCheckOutlinedIcon from '@material-ui/icons/LibraryAddCheckOutlined';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import { ReorderOutlined } from '@material-ui/icons';
import { devicesActions, devicesPanelActions, mapActions } from '../../../store';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { useStylesSortingMenu } from '../MainPage/MainPage.styles';
import { filterBySearchName, filterByCategories } from '../filterDevices';
import lifetimes from '../../../common/static/lifetimes';
import { getCategory } from '../../../common/utils/formatter';

const columns = [
  'name',
  'group',
  'icon',
  'status',
  'lastUpdate',
  'batteryPercent',
  'batteryPic',
  'switch',
  'editButton',
];

const SortingMenu = ({
  sortValue,
  setSortValue,
  sortReverseValue,
  setSortReverseValue,
  progress,
  searchName,
}) => {
  const classes = useStylesSortingMenu();
  const t = useTranslation();
  const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
  const [selectedAnchorElTool, setSelectedAnchorElTool] = useState(null);
  const devices = useSelector((state) => state.devices.items);
  const deviceToolOff = useSelector((state) => state.map.deviceToolOff);
  const closedDevices = useSelector((state) => state.devices.closedDevices);
  const stateSyncClosedDevices = useSelector((state) => state.devices.stateSyncClosedDevices);
  const dispatch = useDispatch();
  const groups = useSelector((state) => state.groups.items);
  const categories = useSelector((state) => state.devices.categories);
  const categoryLifetimes = useSelector((state) => state.session.server.attributes.categoryLifetime ?? lifetimes.categories);
  const temporaryLifetime = useSelector((state) => state.session.server.attributes.temporaryLifetime ?? lifetimes.temporary);
  const [allOff, setAllOff] = useState(!!Object.keys(closedDevices).filter((deviceId) => closedDevices[deviceId]).length);

  const includeProperties = (property) => {
    let updatedOff;
    if (deviceToolOff.includes(property)) {
      updatedOff = deviceToolOff.filter((item) => item !== property);
    } else {
      updatedOff = [...deviceToolOff, property];
    }
    dispatch(mapActions.updateDeviceToolOff(updatedOff));
  };

  const menuHide = () => {
    setSelectedAnchorEl(null);
  };

  const menuShow = (anchorId) => {
    setSelectedAnchorEl(anchorId);
  };

  const handleSelection = (value) => {
    setSortValue(sortReverseValue !== value ? value : `${value}Reverse`);
    setSortReverseValue(sortReverseValue !== value && value);
  };

  const turnOnDevices = (on) => {
    const result = { ...closedDevices };
    if (on) {
      Object.keys(devices).forEach((deviceId) => result[deviceId] = true);
      setAllOff(true);
    } else {
      Object.values(devices)
        .filter((device) => (filterBySearchName(device, groups, searchName)
          && filterByCategories(device, categories[getCategory(device)], categoryLifetimes, temporaryLifetime)))
        .forEach((device) => {
          result[device.id] = false;
        });
      setAllOff(false);
    }
    dispatch(devicesActions.turnAll(result));
  };

  const handleClickChangeModeTitle = (event) => {
    setSelectedAnchorElTool(event.currentTarget);
  };

  const handleClickOpenReorderingMode = () => {
    dispatch(devicesPanelActions.change(true));
  };

  const handleClickOpenSortTitle = (event) => {
    menuShow(event.currentTarget);
  };

  const handleClickTurnOnDevices = () => {
    turnOnDevices(!allOff);
  };

  useEffect(() => {
    const deviceClosedCount = Object.keys(closedDevices).filter((deviceId) => closedDevices[deviceId]).length;
    if (deviceClosedCount === Object.keys(devices).length) {
      setAllOff(true);
    } else if (deviceClosedCount === 0) {
      setAllOff(false);
    }
  }, [stateSyncClosedDevices, Object.keys(devices).length]);

  return (
    <Paper square elevation={3} className={classes.container}>
      <ButtonGroup size="small" variant="text" className={classes.buttonGroup} fullWidth>
        <Tooltip title={t('changeModeTitle')}>
          <Button className={classes.button} onClick={handleClickChangeModeTitle}>
            <SettingsIcon />
          </Button>
        </Tooltip>
        <Tooltip title={t('lazyDevicesListDeviceReorderingMode')}>
          <Button className={classes.button} onClick={handleClickOpenReorderingMode}>
            <ReorderOutlined />
          </Button>
        </Tooltip>
        <Tooltip title={t('sortTitle')}>
          <Button className={classes.button} onClick={handleClickOpenSortTitle}>
            <SortIcon />
          </Button>
        </Tooltip>
        <Tooltip title={t('allOnTitle')}>
          <Button className={classes.button} disabled={!!progress} onClick={handleClickTurnOnDevices}>
            {allOff ? <LibraryAddCheckOutlinedIcon /> : <LibraryAddCheckIcon />}
          </Button>
        </Tooltip>
      </ButtonGroup>
      <Menu
        open={!!selectedAnchorElTool}
        anchorEl={selectedAnchorElTool}
        onClose={() => setSelectedAnchorElTool(null)}
        className={classes.menuEditRemove}
      >
        {columns && columns.map((column) => (
          <MenuItem key={column} onClick={() => includeProperties(column)}>
            <Switch checked={!deviceToolOff.includes(column)} />
            {t(`${column}ToolName`)}
          </MenuItem>
        ))}
      </Menu>
      <Menu open={!!selectedAnchorEl} anchorEl={selectedAnchorEl} onClose={menuHide} className={classes.menuEditRemove}>
        <MenuItem
          onClick={() => handleSelection('sortByName')}
          className={`${sortValue.replace('Reverse', '') === 'sortByName' && classes.activeItem}`}
        >
          {t('sortByName')}
        </MenuItem>
        <MenuItem
          onClick={() => handleSelection('sortByGroup')}
          className={`${sortValue.replace('Reverse', '') === 'sortByGroup' && classes.activeItem}`}
        >
          {t('sortByGroup')}
        </MenuItem>
        <MenuItem
          onClick={() => handleSelection('sortByStatus')}
          className={`${sortValue.replace('Reverse', '') === 'sortByStatus' && classes.activeItem}`}
        >
          {t('sortByStatus')}
        </MenuItem>
        <MenuItem
          onClick={() => handleSelection('sortByLastUpdate')}
          className={`${sortValue.replace('Reverse', '') === 'sortByLastUpdate' && classes.activeItem}`}
        >
          {t('sortByLastUpdate')}
        </MenuItem>
      </Menu>
    </Paper>
  );
};

export default SortingMenu;
