import {
  makeStyles,
} from '@material-ui/core';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WebAssetRounded } from '@material-ui/icons';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import CustomizableBadge from '../../../common/components/CustomizableBadge';
import { universalPanelActions } from '../../../store/universalPanel';
import { availableComponents } from '../../../common/hooks/useComponentsController';
import useStylesForUniversalPanelOfPerfectTable from '../../Settings/common/useStylesForUniversalPanelOfPerfectTable';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 58,
    right: theme.spacing(10),
  },
}));

const UniversalPanelButton = () => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();

  const panelState = useSelector((state) => state.universalPanel);

  const universalPanelSettings = {
    header: availableComponents.SelectCategory,
    body: availableComponents.ObjectsDetectedTable,
    settings: {
      disableStickyHeader: true,
      classes: useStylesForUniversalPanelOfPerfectTable(),
      label: t('deviceCategoriesDetected_object'),
      linkText: 'settings/detected',
      disableFilters: true,
    },
  };

  const handleClick = () => {
    if (panelState.isShow || panelState.header) {
      dispatch(universalPanelActions.changeVisibility());
    } else {
      dispatch(universalPanelActions.createAndShow(universalPanelSettings));
    }
  };

  return (
    <div className={classes.root}>
      <CustomizableBadge
        isActive={panelState.isShow ?? false}
        title={t('GlobalObjectDetectedPanel')}
        icon={<WebAssetRounded />}
        onClick={handleClick}
      />
    </div>
  );
};

export default memo(() => <UniversalPanelButton />);
