import React, { useState } from 'react';
import {
  CircularProgress,
  Drawer, Grid, Paper, makeStyles,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import ComponentIcon from './icons/CommonIcon';
import getExtraLayers from '../../../map/funcs/getExtraLayers';
import extraLayersItems from './extraLayersItems';
import { useCatch } from '../../utils/reactHelper';

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: 300,
    borderRadius: '7px 7px 0px 0px',
  },
  checkboxToggle: {
    borderRadius: '10px',
    backgroundColor: 'rgba(255,255,255,0.8)',
    width: 40,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(220,220,220,0.8)',
    },
  },
  avatar: {
    backgroundColor: 'rgba(255,255,255,0)',
  },
}));

const ExtraLayersDrawer = ({
  buttonsDrawerOpen, setButtonsDrawerOpen,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const extraLayersPanel = useSelector((state) => state.extraLayers.panel);
  const [progress, setProgress] = useState(false);

  const handleButton = useCatch(async (button) => {
    setProgress(true);
    await getExtraLayers(button, dispatch, extraLayersPanel);
    setProgress(false);
  });

  return (
    <Drawer
      anchor="bottom"
      classes={{ paper: classes.root }}
      open={buttonsDrawerOpen}
      onClose={() => setButtonsDrawerOpen(false)}
    >
      <Grid container spacing={2} style={{ margin: 2, maxWidth: '100%' }}>
        {extraLayersItems.map((button) => (
          <Grid item key={button}>
            <Paper
              className={classes.checkboxToggle}
              elevation={3}
              onClick={() => handleButton(button, dispatch, extraLayersPanel)}
            >
              <Avatar className={classes.avatar}>
                {progress ? (
                  <CircularProgress />
                ) : (
                  <ComponentIcon
                    panel={extraLayersPanel}
                    value={button}
                  />
                )}
              </Avatar>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Drawer>
  );
};

export default ExtraLayersDrawer;
