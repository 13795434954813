import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'extraLayers',
  initialState: {
    panel: JSON.parse(window.localStorage.getItem('extraLayersPanel')) ?? {
      onFlightRadarFlights: 0,
      onFlightRadarAirports: 0,
    },
    flightRadarFlights: [],
    flightRadarAirports: [],
  },
  reducers: {
    switch(state, action) {
      state.panel[action.payload.name] = action.payload.status;
      window.localStorage.setItem('extraLayersPanel', JSON.stringify(state.panel));
    },
    refresh(state, action) {
      state[action.payload.name] = action.payload.points;
    },
  },
});

export { actions as extraLayersActions };
export { reducer as extraLayersReducer };
