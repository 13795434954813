import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'events',
  initialState: {
    items: [],
    sound: JSON.parse(window.localStorage.getItem('eventsSoundOn')),
  },
  reducers: {
    add(state, action) {
      state.items.unshift(...action.payload);
    },
    delete(state, action) {
      state.items = state.items.filter((item) => item.id !== action.payload.id);
    },
    deleteAll(state) {
      state.items = [];
    },
    turnSound(state, action) {
      state.sound = action.payload;
      window.localStorage.setItem('eventsSoundOn', action.payload);
    },
  },
});

export { actions as eventsActions };
export { reducer as eventsReducer };
