import {
  Checkbox,
  FormControl, InputLabel, ListItemText, MenuItem, Select,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { useEffectAsync } from '../utils/reactHelper';
import SuperMenuItem from '../components/SuperMenuItem';
import logout from '../utils/logout';
import { useTranslation } from '../components/LocalizationProvider';

const ELEMENT_HEIGHT = 48;
const MIN_CONTAINER_HEIGHT = 360;

const LinkField = ({
  margin,
  variant,
  label,
  endpointAll,
  endpointLinked,
  keyGetter = (item) => item.id,
  titleGetter = (item) => item.name,
  subtitleGetter = () => null,
  permissions,
  setPermissions,
  type,
  fullWidth,
  newItem,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useTranslation();
  const adminEnabled = useSelector((state) => state.session.user?.administrator);

  const [items, setItems] = useState();

  useEffectAsync(async () => {
    const response = await fetch(endpointAll);
    if (response.ok) {
      setItems(await response.json());
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
  }, []);

  useEffectAsync(async () => {
    if (!newItem) {
      const response = await fetch(endpointLinked);
      if (response.ok) {
        const data = await response.json();
        const per = permissions;
        per[type].linked = data.map((it) => it.id);
        per[type].old = data.map((it) => it.id);
        setPermissions({ ...per });
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
    }
  }, []);

  useEffect(() => {
    if (items && permissions && setPermissions && type === 'devices' && permissions?.groups?.linked && adminEnabled) {
      const per = permissions;
      items.forEach((device) => {
        if (per.groups.linked.includes(device.groupId)) {
          per.devices.groupReserve.push(device.id);
        } else {
          const index = per.devices.groupReserve.indexOf(device.id);
          if (index > -1) {
            per.devices.groupReserve.splice(index, 1);
          }
        }
        if (per.groups.old.includes(device.groupId)) {
          const index = per.devices.linked.indexOf(device.id);
          if (index > -1) {
            per.devices.linked.splice(index, 1);
          }
        }
      });
      setPermissions({ ...per });
    }
  }, [items, permissions?.groups?.linked.length]);

  const clearOrFillPermissions = () => {
    if (permissions[type].linked.length) {
      permissions[type].linked = [];
    } else {
      permissions[type].linked = items.map((item) => keyGetter(item));
    }
  };

  const deleteOrAddItemToPermissions = (item) => {
    const id = keyGetter(item);
    const isHaveIdInLinked = permissions[type].linked.find((cur) => cur === id);

    if (isHaveIdInLinked) {
      permissions[type].linked = permissions[type].linked.filter((cur) => cur !== id);
    } else {
      permissions[type].linked.push(id);
    }
  };

  const handleElementClick = (item) => {
    if (item === 'all') {
      clearOrFillPermissions();
    } else {
      deleteOrAddItemToPermissions(item);
    }

    setPermissions({ ...permissions });
  };

  const getValue = () => {
    if (type === 'devices') {
      return Array.from(new Set([...permissions[type].linked, ...permissions[type].groupReserve]));
    }
    return permissions[type].linked;
  };

  const isChecked = (item) => {
    if (permissions[type].linked.includes(keyGetter(item))) {
      return true;
    }
    if (type === 'devices' && permissions[type].groupReserve.includes(keyGetter(item))) {
      return true;
    }
    return false;
  };

  const isDisabled = (item) => {
    if (type === 'devices' && permissions[type].groupReserve.includes(keyGetter(item))) {
      return true;
    }
    return false;
  };

  const menuItemLazyLoad = ({ index, style }) => (
    <MenuItem
      style={style}
      value={keyGetter(items[index])}
      key={keyGetter(items[index])}
      disabled={isDisabled(items[index])}
      onClick={() => handleElementClick(items[index])}
    >
      <Checkbox
        checked={isChecked(items[index])}
      />
      <ListItemText primary={titleGetter(items[index])} secondary={subtitleGetter(items[index])} />
    </MenuItem>
  );

  const calcHeight = () => (items.length >= 7 ? MIN_CONTAINER_HEIGHT : items.length * ELEMENT_HEIGHT);

  if (permissions[type].linked) {
    return (
      <FormControl margin={margin} variant={variant} fullWidth={fullWidth}>
        <InputLabel>{label}</InputLabel>
        <Select
          multiple
          value={getValue()}
          renderValue={(selected) => (items ? `${selected.length} ${t('sharedNumbers')}` : null)}
          MenuProps={theme.overrides.MenuProps}
        >
          <div>
            {items?.length && (
              <MenuItem
                style={{
                  marginTop: '-10px',
                  backgroundColor: theme.palette.grey[300],
                }}
                value="all"
                onClick={() => handleElementClick('all')}
              >
                <SuperMenuItem all={permissions[type].linked.length} />
              </MenuItem>
            )}
            {items?.length && (
              <AutoSizer disableHeight>
                {({ width }) => (
                  <FixedSizeList
                    height={calcHeight()}
                    width={width}
                    itemCount={items.length}
                    itemSize={ELEMENT_HEIGHT}
                  >
                    {menuItemLazyLoad}
                  </FixedSizeList>
                )}
              </AutoSizer>
            )}
          </div>
        </Select>
      </FormControl>
    );
  }
  return null;
};

export default LinkField;
