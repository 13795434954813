import React, { useState } from 'react';
import {
  useTheme, LinearProgress,
} from '@material-ui/core';
import { Column } from 'devextreme-react/data-grid';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffectAsync } from '../../common/utils/reactHelper';
import EditCollectionView from '../../EditCollectionView';
import OptionsLayout from './OptionsLayout';
import { useTranslation } from '../../common/components/LocalizationProvider';
import logout from '../../common/utils/logout';
import SmartTable from '../../common/components/SmartTable';
import { allowedPageSizes, currentPageSize } from '../../common/settings/settingsPage';
import { ColorsCellRender, TextIconCellRender } from '../../common/components/SmartTable/columns/cells';
import { ColorsGroupCellRender, TextIconGroupCellRender } from '../../common/components/SmartTable/columns/groups';
import { getTextFromIconWithSeparator } from '../../common/components/SmartTable/funcs/calculateCellValue';

const MarkercatsView = ({ updateTimestamp, onMenuClick, onAddNewRow }) => {
  const t = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [items, setItems] = useState([]);
  const [requestProgress, setRequestProgress] = useState(true);

  useEffectAsync(async () => {
    const response = await fetch('/api/icons?all=true');
    if (response.ok) {
      setItems(await response.json());
    } else if (response.status === 401) {
      if (response.status === 401) {
        logout(history, dispatch);
      }
    }
    setRequestProgress(false);
  }, [updateTimestamp]);

  if (requestProgress) {
    return (
      <LinearProgress style={{ top: '100px' }} />
    );
  }

  return (
    <SmartTable
      tableName="MarkercatsPage"
      fileExportingName="settingsMarkercats"
      allowedPageSizes={allowedPageSizes}
      currentPageSize={currentPageSize}
      arrayDependencies={[items]}
      dataSource={items}
      onAddNewRow={onAddNewRow}
      onMenuClick={onMenuClick}
    >
      <Column
        dataField="id"
        caption={t('deviceIdentifier')}
        width={100}
        visible={false}
      />
      <Column
        dataField="name"
        caption={t('userName')}
      />
      <Column
        dataField="icon"
        caption={t('deviceIcon')}
        width={250}
        cellComponent={({ data }) => <TextIconCellRender dataset={data} t={t} />}
        groupCellComponent={({ data }) => <TextIconGroupCellRender dataset={data} t={t} />}
        calculateCellValue={(item) => getTextFromIconWithSeparator(item, t)}
      />
      <Column
        key="color"
        name="color"
        dataField="color"
        dataType="string"
        caption={t('sharedColor')}
        width={100}
        cellComponent={({ data }) => <ColorsCellRender dataset={data} theme={theme} />}
        groupCellComponent={({ data }) => <ColorsGroupCellRender dataset={data} theme={theme} />}
      />
    </SmartTable>
  );
};

const MarkercatsPage = () => {
  const t = useTranslation();

  return (
    <OptionsLayout>
      <EditCollectionView
        disableAdd
        stickyTop
        textOfAddButton={t('markerAddIcon')}
        content={MarkercatsView}
        editPath="/settings/icon"
        endpoint="icons"
      />
    </OptionsLayout>
  );
};

export default MarkercatsPage;
