import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  formatDate, formatMsToTime, formatPosition, getSortedArray,
} from '../../common/utils/formatter';
import { useTranslation } from '../../common/components/LocalizationProvider';
import usePersistedState from '../../common/utils/usePersistedState';
import useReportStyles from './hooks/useReportStyles';
import Map from '../../map/Map';
import PositionsMap from '../../map/PositionsMap';
import positionsTypes from '../../common/static/positionsTypes';
import MapCamera from '../../map/MapCamera';
import logout from '../../common/utils/logout';
import ReportTemplate from './components/ReportTemplate';
import { useEffectAsync } from '../../common/utils/reactHelper';

const typeReport = 'stopReport';

const columnsArray = [
  ['deviceId', 'reportDevice', 1],
  ['group', 'reportGroup', 1],
  ['startTime', 'reportStartTime', 1],
  ['endTime', 'reportEndTime', 1],
  ['address', 'positionAddress', 0],
  ['duration', 'reportDuration', 0],
  ['latitude', 'reportLatitude', 0],
  ['longitude', 'reportLongitude', 0],
];

const typesSorting = {
  deviceId: (a, b) => getSortedArray(a.deviceId, b.deviceId),
  deviceIdReverse: (a, b) => getSortedArray(a.deviceId, b.deviceId, true),
  group: (a, b) => getSortedArray(a.groupId, b.groupId),
  groupReverse: (a, b) => getSortedArray(a.groupId, b.groupId, true),
  startTime: (a, b) => getSortedArray(a.startTime, b.startTime),
  startTimeReverse: (a, b) => getSortedArray(a.startTime, b.startTime, true),
  endTime: (a, b) => getSortedArray(a.endTime, b.endTime),
  endTimeReverse: (a, b) => getSortedArray(a.endTime, b.endTime, true),
};

const StopReportPage = () => {
  const t = useTranslation();

  const [items, setItems] = useState([]);
  const [offColumns, setOffColumns] = usePersistedState(typeReport, []);
  const groups = useSelector((state) => state.groups.items);
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useReportStyles();
  const [selectedItem, setSelectedItem] = useState(null);
  const [devicesList, setDevicesList] = useState([]);
  const [devicesObject, setDevicesObject] = useState({});

  const [progress, setProgress] = useState(0);
  const [progressDevices, setProgressDevices] = useState(true);
  const [page, setPage] = useState(0);

  const columnsArrayFiltered = columnsArray.filter((column) => !offColumns.includes(column[0]));

  const handleSubmit = async (deviceId, from, to, headers) => {
    setProgress(1);
    const query = new URLSearchParams({
      from, to,
    });
    deviceId.forEach((it) => query.append('deviceId', it));
    columnsArrayFiltered.forEach((it) => query.append('column', it[0]));
    const response = await fetch(`/api/reports/stops?${query.toString()}`, { headers });
    if (response.ok) {
      const contentType = response.headers.get('content-type');
      if (contentType) {
        if (contentType === 'application/json') {
          setPage(0);
          setItems(await response.json());
        } else {
          window.location.assign(window.URL.createObjectURL(await response.blob()));
        }
      }
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
    setProgress(100);
    setProgress(0);
  };

  const formatValue = (item, key, devices) => {
    switch (key) {
      case 'startTime':
      case 'endTime':
        return formatDate(item[key]);
      case 'deviceId':
        return devices[item[key]]?.name;
      case 'group':
        return groups[devices[item.deviceId].groupId]?.name || '';
      case 'longitude':
      case 'latitude':
        return formatPosition(item[key], 'latitude', t);
      case 'duration':
        return formatMsToTime(item[key], t);
      case 'address':
        return formatPosition(item[key], 'address', t);
      default:
        return item[key];
    }
  };

  useEffectAsync(async () => {
    const response = await fetch('/api/devices?object=true');
    if (response.ok) {
      const result = await response.json();
      setDevicesList(Object.values(result));
      setDevicesObject(result);
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
    setProgressDevices(false);
  }, []);

  return (
    <ReportTemplate
      items={items}
      progress={progress}
      progressDevices={progressDevices}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      columnsArray={columnsArray}
      handleSubmit={handleSubmit}
      typeSortingDefault="startTime"
      formatValue={formatValue}
      page={page}
      setPage={setPage}
      typesSorting={typesSorting}
      typeReport={typeReport}
      offColumns={offColumns}
      setOffColumns={setOffColumns}
      columnsArrayFiltered={columnsArrayFiltered}
      breadcrumbs={['reportTitle', 'reportStops']}
      devicesList={devicesList}
      devicesObject={devicesObject}
      map={selectedItem && (
        <div className={classes.containerMap}>
          <Map noFixed>
            <PositionsMap
              positions={{ [selectedItem.deviceId]: [selectedItem] }}
              devices={devicesObject}
              data={positionsTypes.stop}
              noClick
            />
            <MapCamera latitude={selectedItem.latitude} longitude={selectedItem.longitude} />
          </Map>
        </div>
      )}
      mapOn
    />
  );
};

export default StopReportPage;
