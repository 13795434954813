import React, { useEffect, useState } from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary, Avatar, Container, IconButton, Paper, Slider,
  Tooltip, Typography, useTheme,
} from '@material-ui/core';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AdjustIcon from '@material-ui/icons/Adjust';
import ReportFilter from '../../Reports/components/ReportFilter';
import requestRoute from '../../../common/utils/requestRoute';
import CircularProgressWithLabel from '../../../common/components/CircularProgressWithLabel';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { formatPosition } from '../../../common/utils/formatter';
import { getIconSrc } from '../../../common/utils/selectors';
import { useMarkStyles, useStylesControlPanel, useTooltipStyles } from '../ReplayPage/ReplayPage.styles';

const TimeLabel = ({ children, open, value }) => (
  <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
    {children}
  </Tooltip>
);

const TimeLabelNew = ({
  children, open, value, index,
}) => {
  const classes = useTooltipStyles();
  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement={index === 0 ? 'top-end' : 'top-start'}
      title={value}
      classes={classes}
    >
      {children}
    </Tooltip>
  );
};

const MarkLabel = ({ device, position }) => {
  const classes = useMarkStyles();
  const theme = useTheme();

  return (
    <Tooltip title={`${device.name} - ${new Date(position.fixTime).toLocaleString()}`}>
      <Avatar
        className={classes.mark}
        style={{ backgroundColor: device.attributes.color || theme.palette.tracks.replay0 }}
      >
        <img src={getIconSrc(device)} alt="" className={classes.icon} />
      </Avatar>
    </Tooltip>
  );
};

const ControlPanel = ({
  to, setTo, from, setFrom, progress, setProgress, compactMode, setCompactMode, indexes, setIndexes,
  positions, setPositions, period, setPeriod, panel, setNothing, needMoveMap, setPositionsPath, setPositionsStop,
  setPositionsSimilar, currentPositions, devicesObject, devicesList, progressDevices,
}) => {
  const t = useTranslation();
  const classes = useStylesControlPanel();
  const [expanded, setExpanded] = useState(true);
  const [minDistance, setMinDistance] = useState(1000);
  const [expandMode, setExpandMode] = useState(true);
  const theme = useTheme();
  const [marks, setMarks] = useState([]);

  const handleChangeTwo = (event, newValue) => {
    /**
     * Изменяет ширину временной шкалы видимости устройств
     */
    if (!Array.isArray(newValue)) {
      return;
    }
    if (expandMode) {
      setPeriod(newValue);
      setMinDistance(newValue[1] - newValue[0]);
      return;
    }
    if (period[0] !== newValue[0]) {
      if (newValue[0] + minDistance <= to.valueOf()) {
        newValue[1] = newValue[0] + minDistance;
        setPeriod(newValue);
      }
    } else if (newValue[1] - minDistance >= from.valueOf()) {
      newValue[0] = newValue[1] - minDistance;
      setPeriod(newValue);
    }
  };

  const reducePositions = (positions) => {
    /**
     * Уменьшает количество позиций в элементе Слайдера во избежании перегрузки приложения.
     */
    const shortPositions = [];
    const index = positions.length > 399 ? Math.floor(positions.length / 200) : 0;
    if (index !== 0) {
      for (let i = 0; i < positions.length; i += index) {
        shortPositions.push(positions[i]);
      }
    } else {
      return positions;
    }
    return shortPositions;
  };

  const handleSubmit = async (deviceIds, from, to, headers) => {
    /**
     * Обнуляет все данные, запрашивает и устанавливает новые.
     */
    setProgress(1);
    setPositions({});
    setPositionsPath({});
    setPositionsStop({});
    setPositionsSimilar({});
    setIndexes(null);
    const states = {
      setPositions,
      setPositionsPath,
      setPositionsStop,
      setPositionsSimilar,
      indexes,
      setIndexes,
    };
    const res = await requestRoute(deviceIds.map((id) => devicesObject[id]), from, to, headers, states, panel.onBs, false,
      setProgress);
    setProgress(100);
    setProgress(0);
    if (res) {
      setExpanded(false);
    } else {
      setNothing(true);
    }
  };

  useEffect(() => {
    /**
     * Изменение дефолтных состояний точек на временной школе при изменении перода from - to
     */
    setMinDistance(to.valueOf() - from.valueOf());
    setPeriod([from.valueOf(), to.valueOf()]);
  }, [from, to]);

  useEffect(() => {
    /**
     * Обновляет значения маркеров позиций устройств на временной шкале при изменении текущих позиций currentPositions
     */
    if (currentPositions) {
      setMarks(Object.values(currentPositions)
        .map((p) => ({
          value: new Date(p[0]?.fixTime).valueOf(),
          label: p[0] && <MarkLabel device={devicesObject[p[0].deviceId]} position={p[0]} />,
        })));
    }
  }, [currentPositions]);

  return (
    <Container
      maxWidth={!expanded ? false : 'sm'}
      className={`${classes.controlPanel} ${needMoveMap ? classes.controlPanelShort : classes.controlPanelWide}`}
    >
      {!!(!expanded && Object.keys(positions).length) && (
        compactMode ? (
          <div className={classes.sliderOneItem}>
            <Slider
              getAriaLabel={() => 'Custom marks'}
              value={period}
              step={20000}
              valueLabelFormat={(v) => new Date(v).toLocaleString()}
              onChange={handleChangeTwo}
              valueLabelDisplay="auto"
              min={from.valueOf()}
              max={to.valueOf()}
              marks={marks}
              ValueLabelComponent={TimeLabelNew}
            />
          </div>
        ) : (
          <div className={classes.sliderBlock}>
            {indexes && Object.keys(positions).map((id, index) => (
              !!positions[id].length
              && (
                <div key={id} className={classes.sliderOneItem}>
                  <span style={{ color: devicesObject[id].attributes.color || theme.palette.tracks.replay0, padding: 0 }}>
                    {devicesObject[id].name}
                  </span>
                  <Slider
                    max={positions[id].length - 1}
                    step={null}
                    marks={reducePositions(positions[id].map((_, index) => ({ value: index })))}
                    value={indexes[id][index]}
                    onChange={(_, i) => setIndexes({ ...indexes, [id]: i })}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(i) => (
                      i < positions[id].length ? formatPosition(positions[id][i], 'fixTime', t) : '')}
                    ValueLabelComponent={TimeLabel}
                    className={classes.slider}
                    style={{ color: devicesObject[id].attributes.color || theme.palette.tracks.replay0 }}
                  />
                </div>
              )))}
          </div>
        )
      )}
      <div className={classes.sliderButtons}>
        {!!(!expanded && Object.keys(positions).length) && (
          <div className={classes.buttonRow}>
            <Paper>
              <Tooltip title={t('changeTypeTimelineTitle')}>
                <IconButton onClick={() => setCompactMode(!compactMode)} style={{ transform: 'rotate(90deg)' }}>
                  <AssessmentIcon />
                </IconButton>
              </Tooltip>
            </Paper>
            {compactMode && (
              <Paper>
                <Tooltip title={t('dynamicThumbTimelineTitle')}>
                  <IconButton onClick={() => setExpandMode(!expandMode)}>
                    <AdjustIcon color={expandMode ? 'error' : 'inherit'} />
                  </IconButton>
                </Tooltip>
              </Paper>
            )}
          </div>
        )}
        <Accordion
          expanded={expanded}
          onChange={() => !progress && setExpanded(!expanded)}
          className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={progress ? <CircularProgressWithLabel value={progress} rotate /> : <ExpandLessIcon />}
            className={classes.accordionBar}
          >
            <Typography align="left" noWrap>
              {t('reportReplay')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.configForm}>
            <ReportFilter
              handleSubmit={handleSubmit}
              showOnly
              progress={progress}
              onBs={panel.onBs}
              setF={setFrom}
              setT={setTo}
              devicesList={devicesList}
              progressDevices={progressDevices}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    </Container>
  );
};

export default ControlPanel;
