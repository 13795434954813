import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import StopIcon from '@material-ui/icons/Stop';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { formatMsToTime, getStatusColor } from '../../../utils/formatter';
import {
  getButtonControlModeColor, getColorConnectionStatus, getTextFromIcon, getWorkModeButtonColor,
} from '../funcs/calculateCellValue';
import { prefixString } from '../../../utils/stringUtils';

const CustomizeCourseRender = ({ dataset }) => (
  <>
    {dataset.data?.course}
    &#176;
  </>
);

const ColorsCellRender = ({
  dataset, theme,
}) => (
  <FiberManualRecordIcon style={{ color: (dataset.data.color || dataset.data?.attributes?.color) ?? theme.palette.tracks.replay0 }} />
);

const ActionsMenuRender = ({
  dataset, innerClasses, onMenuClick,
}) => (
  <MoreVertIcon className={innerClasses.pointerCursor} color="action" onClick={(e) => onMenuClick(e.currentTarget, dataset.data.id)} />
);

const LastUpdateCellRender = ({
  dataset, now, t,
}) => (
  <>
    {formatMsToTime(now.valueOf() - new Date(dataset.data?.lastUpdate).valueOf(), t)}
  </>
);

const VisibleCellRender = ({
  dataset, closedDevices,
}) => (
  <>
    {closedDevices[dataset.data.id] ? (
      <VisibilityOffOutlined />
    ) : (
      <VisibilityOutlined />
    )}
  </>
);

const StatusCellRender = ({
  dataset, deviceListClasses,
}) => (
  <Brightness1Icon className={`${deviceListClasses[getStatusColor(dataset.data.status)]} ${deviceListClasses.brightness}`} />
);

const IconCellRender = ({
  dataset,
}) => (
  <Grid container alignItems="center" spacing={2}>
    <Grid item>
      <img src={`/images/icon/device/${dataset.data?.icon || 'default'}.svg`} alt="" />
    </Grid>
  </Grid>
);

const TextIconCellRender = ({
  dataset, t,
}) => (
  <Grid container alignItems="center" spacing={2}>
    <Grid item>
      <img src={`/images/icon/device/${dataset.data.icon || 'default'}.svg`} alt="" />
    </Grid>
    <Grid item>
      {getTextFromIcon(dataset.data, t)}
    </Grid>
  </Grid>
);

const NameCellRender = ({
  dataset, handleClickByObject, innerClasses, chosenDevice,
}) => (
  <Typography
    className={`${innerClasses.pointerCursor} ${chosenDevice === dataset.data.id && innerClasses.highlight}`}
    variant="body2"
    onClick={() => handleClickByObject(dataset.data)}
  >
    {dataset.data.name}
  </Typography>
);

const TemporaryCellRender = ({
  dataset, handleChangeTemporaryStatus, innerClasses, t,
}) => (
  <FormControlLabel
    className={innerClasses.formControlLabel}
    control={(
      <Checkbox
        checked={dataset.data.temporary ?? false}
        color="secondary"
        onChange={(event, value) => handleChangeTemporaryStatus([dataset.data], value)}
      />
    )}
    label={(dataset.data.temporary ?? false) ? t('sharedYes') : t('sharedNo')}
  />
);

const ColorConnectionCellRender = ({
  dataset, positions, theme,
}) => (
  <StopIcon style={{ color: getColorConnectionStatus(positions[dataset.data.id]?.attributes.categoryParams?.connectionStatus, theme) }} />
);

const ControlModeCellRender = ({
  dataset, handleChangeCategoryParamStatus, controlModesStrizh3, theme, t,
}) => (
  <>
    {dataset.data.model === 'strizh3' && (
      <ToggleButtonGroup
        value={dataset.data.attributes.categoryParams.controlMode}
        exclusive
        onChange={(_, value) => {
          if (value) {
            handleChangeCategoryParamStatus(value, [dataset.data], 'controlMode');
          }
        }}
        size="small"
      >
        {controlModesStrizh3.map((mode) => (
          <ToggleButton
            key={mode}
            style={{
              background: dataset.data.attributes.categoryParams.controlMode === mode ? theme.palette.common.blue : '#ededed',
            }}
            value={mode}
            size="small"
          >
            <Typography variant="caption" noWrap>
              {t(mode)}
            </Typography>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    )}
  </>
);

const WorkModeCellRender = ({
  dataset, handleChangeCategoryParamStatus, t, workModes,
}) => {
  if (dataset.data.attributes.categoryParams.controlMode !== 'manual') {
    return (
      <ToggleButton
        size="small"
        value="0"
        style={{
          background: getWorkModeButtonColor(dataset.data, ''),
          width: '100%',
          cursor: 'default',
        }}
      >
        <Typography variant="caption" noWrap>{t(dataset.data.attributes.categoryParams.controlMode)}</Typography>
      </ToggleButton>
    );
  }

  return (
    <ToggleButtonGroup
      value={dataset.data.attributes.categoryParams.workMode}
      exclusive
      onChange={(_, value) => {
        if (value) {
          handleChangeCategoryParamStatus(value, [dataset.data], 'workMode');
        }
      }}
      size="small"
    >
      {workModes.map((mode) => (
        <ToggleButton
          key={mode}
          style={{ background: getWorkModeButtonColor(dataset.data, mode) }}
          value={mode}
          disabled={dataset.data.attributes.categoryParams.controlMode !== 'manual'}
        >
          <Typography variant="caption" noWrap>
            {t(mode)}
          </Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

const StateCellRender = ({
  dataset, positions, t,
}) => {
  const positionData = positions[dataset.data.id];
  const statusText = t(positionData?.attributes.categoryParams?.workModeDescription) || t(positionData?.attributes.categoryParams?.workMode);

  if (!statusText || dataset.data.model !== 'strizh3') {
    return (<></>);
  }

  return (
    <ToggleButton
      size="small"
      value="0"
      style={{
        background: getButtonControlModeColor(positions[dataset.data.id]?.attributes.categoryParams?.workMode),
        width: '100%',
        cursor: 'default',
      }}
    >
      <Typography variant="caption" noWrap>{statusText}</Typography>
    </ToggleButton>
  );
};

const ShowRadarAreaCellRender = ({
  dataset, innerClasses, handleChangeShowRadarAreaStatus, t,
}) => (
  <FormControlLabel
    className={innerClasses.formControlLabel}
    control={(
      <Checkbox
        checked={dataset.data.attributes.categoryParams.showRadarArea ?? false}
        color="secondary"
        onChange={(event, value) => handleChangeShowRadarAreaStatus([dataset.data], value)}
      />
    )}
    label={(dataset.data.attributes.categoryParams.showRadarArea ?? false) ? t('sharedYes') : t('sharedNo')}
  />
);

const NotificatorLinksRender = ({
  dataset, t,
}) => {
  if (dataset.data.notificators) {
    return dataset.data.notificators
      .split(/[, ]+/)
      .filter(Boolean)
      .map((it) => (
        it === 'telegram'
          ? (
            <Tooltip title={t('telegramBotLinkTitle')} key={it}>
              <a href="https://t.me/SmartTrackBot" target="_blank" rel="noopener noreferrer">{t(prefixString('notificator', it))}</a>
            </Tooltip>
          )
          : t(prefixString('notificator', it))
      )).reduce((prev, curr) => [prev, ', ', curr]);
  }
  return '';
};

export {
  CustomizeCourseRender,
  ColorsCellRender,
  ActionsMenuRender,
  LastUpdateCellRender,
  VisibleCellRender,
  StatusCellRender,
  IconCellRender,
  TextIconCellRender,
  NameCellRender,
  TemporaryCellRender,
  ColorConnectionCellRender,
  ControlModeCellRender,
  WorkModeCellRender,
  StateCellRender,
  ShowRadarAreaCellRender,
  NotificatorLinksRender,
};
