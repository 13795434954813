import React from 'react';
import {
  Typography
  ,
} from '@material-ui/core';
import { useTranslation } from '../../../../common/components/LocalizationProvider';

const VirtualEmptyRow = ({ style, classes }) => {
  const t = useTranslation();

  return (
    <div style={style} className={classes.emptyItem}>
      <Typography variant="body2" color="textSecondary">{t('GlobalListDevicesIsEmpty')}</Typography>
    </div>
  );
};

export default VirtualEmptyRow;
