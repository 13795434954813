import React, { useState } from 'react';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, FormControlLabel, Checkbox,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from '../../common/components/LocalizationProvider';
import EditItemView from './components/EditItemView';
import { prefixString } from '../../common/utils/stringUtils';
import SelectField from '../../common/form/SelectField';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
  checkbox: {
    marginTop: 12,
  },
}));

const NotificationPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [item, setItem] = useState();

  return (
    <EditItemView
      endpoint="notifications"
      item={item}
      setItem={setItem}
      disabledSave={!(item && item.type && item.notificators?.length)}
    >
      {item
        && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedNotification')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <SelectField
                margin="normal"
                value={item.type || ''}
                emptyValue={null}
                onChange={(e) => setItem({ ...item, type: e.target.value })}
                endpoint="/api/notifications/types"
                keyGetter={(it) => it.type}
                titleGetter={(it) => t(prefixString('event', it?.type || it))}
                label={t('sharedType')}
                variant="filled"
              />
              <SelectField
                multiple
                margin="normal"
                value={item.notificators ? item.notificators.split(/[, ]+/) : []}
                onChange={(e) => setItem({ ...item, notificators: e.target.value.join() })}
                endpoint="/api/notifications/notificators"
                keyGetter={(it) => it.type}
                titleGetter={(it) => t(prefixString('notificator', it?.type || it))}
                label={t('notificationNotificators')}
                variant="filled"
              />
              <FormControlLabel
                className={classes.checkbox}
                control={(
                  <Checkbox
                    checked={item.always}
                    onChange={(event) => setItem({ ...item, always: event.target.checked })}
                  />
                )}
                label={t('notificationAlways')}
              />
            </AccordionDetails>
          </Accordion>
        </>
        )}
    </EditItemView>
  );
};

export default NotificationPage;
